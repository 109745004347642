<template>
  <form
    class="form"
    :class="platform"
    data-t="emal-step"
    @submit.prevent="handleSubmit"
  >
    <h2 class="title">{{ t('profile.setEmail') }}</h2>
    <StDrawerClose v-if="isMobile" class="close" />
    <StInput
      v-bind="email.componentBindings"
      :label="t('registration.emailLabel')"
      :placeholder="t('registration.emailPlaceholder')"
      inputmode="email"
      :size="isMobile ? 'm' : 'l'"
      type="email"
      data-t="email"
      class="email-input"
    />
    <StInputPassword
      v-bind="password.componentBindings"
      :label="t('registration.passwordLabel')"
      :placeholder="t('registration.passwordPlaceholder')"
      :rules="passwordValidators"
      type="password"
      :error="!!password.error"
      :error-message="password.error"
      :size="isMobile ? 'm' : 'l'"
      :rules-position="isMobile ? 'top' : 'bottom'"
      data-t="password"
      class="password-input"
    />
    <StButton
      submit
      class="submit-button"
      :label="t('profile.setEmail')"
      :size="isMobile ? 'l' : 'xl'"
      data-t="submit-button"
      :loading="status === 'pending'"
      :disabled="isDisabledSubmit"
    />
  </form>
</template>

<script setup lang="ts">
import { required, useForm, validEmail } from '@st/validate'
import { usePasswordValidator } from '@st/user/composables/usePasswordValidator'
import StDrawerClose from '@st/ui/components/StDrawer/StDrawerClose.vue'
import { useUserAuthEmailCodeSent } from '../useUserAuthEmailCodeSent'
import type { EmailStepData } from '../types'

const emit = defineEmits<{
  success: [EmailStepData]
}>()

const { t, locale } = useI18n()
const { isMobile, platform } = usePlatform()
const { passwordValidators } = usePasswordValidator()

const {
  fields: { email, password },
  values,
  isValid,
} = useForm({
  fieldsSchema: {
    email: {
      initialValue: '',
      validators: [
        {
          rule: required,
          triggerErrorDisplay: 'never',
        },
        {
          rule: validEmail,
          errorMessage: t('registration.errorMessages.emailShouldBeValid'),
        },
      ],
    },
    password: {
      initialValue: '',
      validators: [
        {
          rule: required,
          triggerErrorDisplay: 'never',
        },
        ...passwordValidators.value,
      ],
    },
  },
})
const isDisabledSubmit = computed(() => !isValid.value)

const requestCodeSendBody = computed(() => ({
  ...values.value,
  language: locale.value as any,
}))

const { sendCode, error, status } =
  useUserAuthEmailCodeSent(requestCodeSendBody)

const toast = useToast()
async function handleSubmit() {
  await sendCode()
  if (!error.value) {
    emit('success', requestCodeSendBody.value)
  } else {
    toast.open({
      type: 'negative',
      label: t('profile.setEmailError'),
    })
  }
}
</script>

<style scoped>
.title {
  margin: 0;
  font: var(--desktop-text-xl-semibold);
}

.submit-button {
  margin-top: auto;
}

.form {
  position: relative;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--spacing-200);

  width: 100%;
  /* stylelint-disable-next-line */
  padding: 40px var(--spacing-600);

  &.mobile {
    padding: var(--spacing-150) var(--spacing-200) var(--spacing-200);

    .title {
      margin-bottom: var(--spacing-050);
      font: var(--mobile-title-2-semibold);
    }

    .submit-button {
      margin-top: var(--spacing-100);
    }
  }
}

.close {
  position: absolute;
  top: var(--spacing-050);
  right: var(--spacing-050);
}
</style>
