import {
  TonConnectUI,
  type ConnectedWallet,
  type SendTransactionRequest,
  type ActionConfiguration,
  toUserFriendlyAddress,
  TonConnect,
} from '@tonconnect/ui'

export function useTonConnect() {
  const url = useRequestURL()
  const app = useNuxtApp().vueApp
  let tonConnect: TonConnectUI = inject('tonConnect') as TonConnectUI

  if (!tonConnect) {
    tonConnect = new TonConnectUI({
      connector: new TonConnect({
        manifestUrl: `${url.origin}/tonconnect-manifest.json`,
        walletsListSource: `${url.origin}/tonconnect-wallets.json`,
      }),
    })
    app.provide('tonConnect', tonConnect)
  }

  function openModal() {
    tonConnect.openModal()
  }

  function sendTransaction(
    transaction: SendTransactionRequest,
    options?: ActionConfiguration,
  ) {
    return tonConnect.sendTransaction(transaction, options)
  }

  const isConnected = ref(tonConnect.connected)
  const wallet = ref<ConnectedWallet | null>()
  const initedAddress = computed(() =>
    tonConnect?.account?.address
      ? toUserFriendlyAddress(tonConnect?.account?.address)
      : '',
  )
  const address = ref(initedAddress.value)

  const unsubscribe = tonConnect.onStatusChange(async (walletInfo) => {
    address.value = walletInfo?.account.address
      ? toUserFriendlyAddress(walletInfo?.account.address)
      : ''

    isConnected.value = tonConnect.connected
    wallet.value = walletInfo
  })

  onUnmounted(() => {
    unsubscribe()
  })

  return {
    tonConnect,
    openModal,
    sendTransaction,
    isConnected,
    walletAddress: computed(() => address.value),
    disconnect: () => tonConnect.disconnect(),
  }
}
