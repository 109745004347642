export const currencyCodeByCountryCode: Record<string, string> = {
  AND: 'EUR',
  ARE: 'AED',
  AFG: 'AFN',
  ATG: 'XCD',
  AIA: 'XCD',
  ALB: 'ALL',
  ARM: 'AMD',
  AGO: 'AOA',
  ATA: 'AQD',
  ARG: 'ARS',
  ASM: 'EUR',
  AUT: 'EUR',
  AUS: 'AUD',
  ABW: 'ANG',
  ALA: 'EUR',
  AZE: 'AZN',
  BIH: 'BAM',
  BRB: 'BBD',
  BGD: 'BDT',
  BEL: 'EUR',
  BFA: 'XOF',
  BGR: 'BGN',
  BHR: 'BHD',
  BDI: 'BIF',
  BEN: 'XOF',
  BLM: 'EUR',
  BMU: 'BMD',
  BRN: 'BND',
  BOL: 'BOB',
  BRA: 'BRL',
  BHS: 'BSD',
  BTN: 'INR',
  BVT: 'NOK',
  BWA: 'BWP',
  BLR: 'BYR',
  BLZ: 'BZD',
  CAN: 'CAD',
  CCK: 'AUD',
  COD: 'CDF',
  CAF: 'XAF',
  COG: 'XAF',
  CHE: 'CHF',
  COK: 'NZD',
  CHL: 'CLP',
  CMR: 'XAF',
  CHN: 'CNY',
  COL: 'COP',
  CRI: 'CRC',
  CUB: 'CUP',
  CPV: 'CVE',
  CXR: 'AUD',
  CYP: 'CYP',
  CZE: 'CZK',
  DEU: 'EUR',
  DJI: 'DJF',
  DNK: 'DKK',
  DMA: 'XCD',
  DOM: 'DOP',
  DZA: 'DZD',
  ECU: 'ECS',
  EST: 'EEK',
  EGY: 'EGP',
  ESH: 'MAD',
  ERI: 'ETB',
  ESP: 'EUR',
  ETH: 'ETB',
  FIN: 'EUR',
  FJI: 'FJD',
  FLK: 'FKP',
  FSM: 'USD',
  FRO: 'DKK',
  FRA: 'EUR',
  GAB: 'XAF',
  GBR: 'GBP',
  GRD: 'XCD',
  GEO: 'GEL',
  GUF: 'EUR',
  GGY: 'GGP',
  GHA: 'GHS',
  GIB: 'GIP',
  GRL: 'DKK',
  GMB: 'GMD',
  GIN: 'GNF',
  GLP: 'EUR',
  GNQ: 'XAF',
  GRC: 'EUR',
  SGS: 'GBP',
  GTM: 'GTQ',
  GUM: 'USD',
  GNB: 'XOF',
  GUY: 'GYD',
  HKG: 'HKD',
  HMD: 'AUD',
  HND: 'HNL',
  HRV: 'HRK',
  HTI: 'HTG',
  HUN: 'HUF',
  IDN: 'IDR',
  IRL: 'EUR',
  ISR: 'ILS',
  IMN: 'GBP',
  IND: 'INR',
  IOT: 'USD',
  IRQ: 'IQD',
  IRN: 'IRR',
  ISL: 'ISK',
  ITA: 'EUR',
  JEY: 'GBP',
  JAM: 'JMD',
  JOR: 'JOD',
  JPN: 'JPY',
  KEN: 'KES',
  KGZ: 'KGS',
  KHM: 'KHR',
  KIR: 'AUD',
  COM: 'KMF',
  KNA: 'XCD',
  PRK: 'KPW',
  KOR: 'KRW',
  KWT: 'KWD',
  CYM: 'KYD',
  KAZ: 'KZT',
  LAO: 'LAK',
  LBN: 'LBP',
  LCA: 'XCD',
  LIE: 'CHF',
  LKA: 'LKR',
  LBR: 'LRD',
  LSO: 'LSL',
  LTU: 'LTL',
  LUX: 'EUR',
  LVA: 'LVL',
  LBY: 'LYD',
  MAR: 'MAD',
  MCO: 'EUR',
  MDA: 'MDL',
  MNE: 'EUR',
  MAF: 'ANG',
  MDG: 'MGA',
  MHL: 'USD',
  MKD: 'MKD',
  MLI: 'XOF',
  MMR: 'MMK',
  MNG: 'MNT',
  MAC: 'MOP',
  MNP: 'USD',
  MTQ: 'EUR',
  MRT: 'MRO',
  MSR: 'XCD',
  MLT: 'MTL',
  MUS: 'MUR',
  MDV: 'MVR',
  MWI: 'MWK',
  MEX: 'MXN',
  MYS: 'MYR',
  MOZ: 'MZN',
  NAM: 'NAD',
  NCL: 'XPF',
  NER: 'XOF',
  NFK: 'AUD',
  NGA: 'NGN',
  NIC: 'NIO',
  NLD: 'EUR',
  NOR: 'NOK',
  NPL: 'NPR',
  NRU: 'AUD',
  NIU: 'NZD',
  NZL: 'NZD',
  OMN: 'OMR',
  PAN: 'PAB',
  PER: 'PEN',
  PYF: 'XPF',
  PNG: 'PGK',
  PHL: 'PHP',
  PAK: 'PKR',
  POL: 'PLN',
  SPM: 'EUR',
  PCN: 'NZD',
  PRI: 'USD',
  PSE: 'JOD',
  PRT: 'EUR',
  PLW: 'USD',
  PRY: 'PYG',
  QAT: 'QAR',
  REU: 'EUR',
  ROU: 'RON',
  SRB: 'RSD',
  RUS: 'RUB',
  RWA: 'RWF',
  SAU: 'SAR',
  SLB: 'SBD',
  SYC: 'SCR',
  SDN: 'SDG',
  SWE: 'SEK',
  SGP: 'SGD',
  SHN: 'GBP',
  SVN: 'EUR',
  SJM: 'NOK',
  SVK: 'SKK',
  SLE: 'SLL',
  SMR: 'EUR',
  SEN: 'XOF',
  SOM: 'SOS',
  SUR: 'SRD',
  STP: 'STD',
  SLV: 'SVC',
  SYR: 'SYP',
  SWZ: 'SZL',
  TCA: 'USD',
  TCD: 'XAF',
  ATF: 'EUR',
  TGO: 'XOF',
  THA: 'THB',
  TJK: 'TJS',
  TKL: 'NZD',
  TLS: 'TMT',
  TKM: 'TND',
  TUN: 'TOP',
  TON: 'IDR',
  TUR: 'TRY',
  TTO: 'TTD',
  TUV: 'AUD',
  TWN: 'TWD',
  TZA: 'TZS',
  UKR: 'UAH',
  UGA: 'UGX',
  UMI: 'USD',
  USA: 'USD',
  URY: 'UYU',
  UZB: 'UZS',
  VAT: 'EUR',
  VCT: 'XCD',
  VEN: 'VEF',
  VGB: 'USD',
  VIR: 'USD',
  VNM: 'VND',
  VUT: 'VUV',
  WLF: 'XPF',
  WSM: 'EUR',
  YEM: 'YER',
  MYT: 'EUR',
  ZAF: 'ZAR',
  ZMB: 'ZMK',
  ZWE: 'ZWD',
}
