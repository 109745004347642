<template>
  <section class="rolling-modal" :class="{ mobile: isMobile }">
    <div class="rolling-modal-wrapper">
      <button v-if="isMobile" class="close-button" @click="close">
        <StIcon name="cross-large" :size="20" />
      </button>
      <header class="header">
        <small class="header-small">{{
          t('freespinRolling.congratulations')
        }}</small>
        <h2 class="title">{{ t('freespinRolling.title') }}</h2>
      </header>
      <div class="reward">
        <img
          :width="isMobile ? 20 : 28"
          src="./parts/leftSpikelet.svg"
          alt=""
          srcset=""
        />
        <div class="reward-amount-wrapper">
          <strong class="reward-amount">{{ convertedReward }}</strong>
        </div>

        <img
          :width="isMobile ? 20 : 28"
          src="./parts/rightSpikelet.svg"
          alt=""
          srcset=""
        />
      </div>
      <div class="rules">
        <p class="rules-title">{{ t('freespinRolling.rulesTitleNew') }}</p>
        <ul class="rules-list">
          <li class="rules-item">
            <span class="rules-counter">{{ ruleIndexes.firstRule }}</span>
            <span>{{ t('freespinRolling.firstRule') }}</span>
          </li>
          <li v-if="showSecondRule" class="rules-item">
            <span class="rules-counter">{{ ruleIndexes.secondRule }}</span>
            <i18n-t
              keypath="freespinRolling.secondRule"
              tag="span"
              class="text"
            >
              <template #count>
                {{
                  formatMultiplier(
                    currentFreespinRolling?.rollingMultiplier ?? 0,
                  )
                }}
              </template>
              <template #amount>
                <b>{{
                  formatFiat(currentFreespinRolling?.rollingFullAmount ?? 0)
                }}</b>
              </template>
            </i18n-t>
          </li>
          <li class="rules-item">
            <span class="rules-counter">{{ ruleIndexes.thirdRule }}</span>
            <i18n-t keypath="freespinRolling.thirdRule" tag="span" class="text">
              <template #amount>
                <b>
                  {{
                    formatCrypto(
                      currentFreespinRolling?.freespinCurrentRewardAmount ?? 0,
                    )
                  }}
                </b>
              </template>
            </i18n-t>
          </li>
        </ul>
      </div>
      <StButton
        class="rolling-button"
        :size="isMobile ? 'l' : 'xl'"
        replace
        :to="{ query: { modal: 'payments', tab: 'deposit' } }"
      >
        {{ t('freespinRolling.deposit') }}
      </StButton>

      <div class="timer">
        <BonusTimer
          v-if="currentFreespinRolling"
          :label="t('freespinRolling.period')"
          :expires-at="currentFreespinRolling.expiredAt"
          is-shown-days-label
          data-t="bonus-timer"
        />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useCurrenciesStore } from '@st/payments/stores/useCurrenciesStore'
import BonusTimer from '../FreeBonusCard/parts/BonusTimer.vue'
import { useFreespinsStore } from '../../stores/useFreespinsStore'

defineProps<{
  isMobile?: boolean
}>()

const { t } = useI18n()

const { currentFreespinRolling } = storeToRefs(useFreespinsStore())
const freespinRewardCurrencyId = computed(
  () => currentFreespinRolling.value?.rewardCurrencyId ?? 0,
)

const { getCurrencyById } = useCurrenciesStore()
const freespinCurrencyId = computed(
  () =>
    getCurrencyById(currentFreespinRolling.value?.freespinCurrencyId ?? 0)
      ?.code ?? 'usd',
)

const { format: formatMultiplier } = useNumberFormatter()
const { format: formatFiat } = useCurrencyFormatter({
  currency: freespinCurrencyId.value,
})
const { format: formatCrypto } = useCryptoFormatter()

const currentRewardAmount = computed(
  () => currentFreespinRolling.value?.freespinCurrentRewardAmount || '0',
)

const convertedReward = computed(() =>
  formatFiat(
    currentRewardAmount.value,
    getCurrencyById(freespinRewardCurrencyId.value)?.code,
  ),
)
const router = useRouter()
function close() {
  router.replace({ query: {} })
}

const showSecondRule = computed(
  () => +(currentFreespinRolling.value?.rollingMultiplier ?? 0) > 0,
)

const ruleIndexes = computed(() => ({
  firstRule: 1,
  secondRule: showSecondRule.value ? 2 : null,
  thirdRule: showSecondRule.value ? 3 : 2,
}))
</script>

<style scoped>
.rolling-modal-wrapper {
  position: relative;

  display: flex;
  flex-direction: column;

  width: 100%;
  padding: var(--spacing-300);
  padding-top: 172px; /* stylelint-disable-line */

  background-image: url('./parts/bg.png');
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
  border-radius: var(--border-radius-200);
}

.header {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-025);
  align-items: center;
}

.header-small {
  font: var(--desktop-text-md-semibold);
  color: var(--text-tertiary);
}

.title {
  max-width: 360px;
  margin: 0;

  font: var(--desktop-text-xl-semibold);
  color: var(--text-primary);
  text-align: center;
}

.reward {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;
  justify-content: center;

  margin-top: var(--spacing-300);
}

.reward-amount {
  font: var(--desktop-text-2xl-semibold);
  color: var(--text-primary);
}

.reward-amount-wrapper {
  display: flex;
  gap: var(--spacing-125);
  align-items: center;
}

.rules {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);

  margin-top: 30px; /* stylelint-disable-line */
  padding: var(--spacing-200) var(--spacing-250);

  backdrop-filter: blur(12px);
  border: var(--border-width-medium) solid var(--border-primary);
  border-radius: var(--border-radius-150);
}

.rules-title {
  margin: 0;
  font: var(--desktop-text-md-semibold);
  color: var(--text-primary);
}

.rules-list {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);

  margin: 0;
  padding: 0;

  list-style: none;
}

.rules-item {
  display: flex;
  gap: var(--spacing-150);
  align-items: center;

  font: var(--desktop-text-sm-medium);
  color: var(--text-secondary);

  b {
    color: var(--text-primary);
  }
}

.rules-counter {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  font: var(--desktop-text-xs-semibold);
  color: var(--text-primary);

  background: var(--background-secondary);
  border-radius: var(--border-radius-150);
}

.about-link {
  margin-top: 20px; /* stylelint-disable-line */

  font: var(--desktop-text-md-semibold);
  color: var(--text-tertiary);
  text-align: center;
  text-decoration: none;
}

.rolling-button {
  margin: var(--spacing-250) 0;
}

.timer {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-025);
}

.timer-title {
  font: var(--desktop-text-xs-medium);
  color: var(--text-tertiary);
  text-align: center;
}

.rolling-modal {
  display: flex;
  flex-direction: column;

  width: 478px;

  background: radial-gradient(
      52.81% 43.84% at 12.58% 0%,
      rgb(255 91 39 / 60%) 4.24%,
      rgb(243 45 69 / 0%) 100%
    ),
    radial-gradient(
      106.54% 82% at 87.42% 0%,
      rgb(255 48 70 / 60%) 4.24%,
      rgb(38 38 49 / 0%) 50.61%
    ),
    var(--background-base, #111118);
  border-radius: var(--border-radius-200);
  box-shadow: 0 0 80px -8px #1d1d2a;

  &.mobile {
    overflow: hidden;
    width: 100%;
    min-height: 574px;

    .rolling-modal-wrapper {
      overflow-y: auto;
      flex-grow: 1;

      height: auto;
      padding: var(--spacing-200);
      padding-top: 160px; /* stylelint-disable-line */

      background-image: url('./parts/bg-m.png');
      background-position: center 20px;
      background-size: 360px auto;
    }

    .header-small {
      font: var(--mobile-caption-1-medium);
    }

    .title {
      max-width: 235px;
      font: var(--mobile-headline-semibold);
    }

    .reward {
      margin-top: var(--spacing-150);
    }

    .reward-amount {
      font: var(--mobile-title-1-semibold);
    }

    .rules {
      gap: var(--spacing-200);
      margin-top: var(--spacing-250);
      padding: var(--spacing-150) var(--spacing-200);
    }

    .rules-title {
      font: var(--mobile-text-semibold);
    }

    .rules-item {
      font: var(--mobile-text-regular);
    }

    .about-link {
      margin-top: var(--spacing-200);
    }

    .rolling-button {
      margin: var(--spacing-200) 0;
    }
  }
}

.close-button {
  all: unset;

  position: absolute;
  top: 4px;
  right: 4px;

  padding: var(--spacing-125);

  line-height: 0;
}
</style>
