<template>
  <div class="two-factor" :class="platform" data-t="st-2fa">
    <StCodeInput
      v-model="code"
      :success="props.isCodeValid"
      :error="!!errorMessage"
      :error-message="errorMessage"
      :length="CODE_LENGTH"
      :size="codeInputSize"
      data-t="code-input"
      autofocus
      @code-entered="emit('inputFilled', code)"
    />
    <StButton
      v-if="rawTimer <= 0"
      :type="props.buttonType"
      :disabled="!!rawCountDown"
      block
      @click="resendCode"
    >
      <I18nT v-if="!!rawCountDown" keypath="authorization.resendCode">
        <template #value>
          <StBadge
            size="s"
            :is-left-icon="false"
            :label="countdown"
            data-t="countdown"
          />
        </template>
      </I18nT>
      <span v-else> {{ t('authorization.sendCodeAgain') }}</span>
    </StButton>
    <div v-if="isNoEmailAccessVisible" class="no-email-access">
      <StButton
        :type="props.noAccessButtonType"
        size="s"
        :label="t('authorization.restoreEmalAccess')"
        :to="supportEmailLink"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ButtonType } from '@st/ui/components/StButton/types'
import { useSettingsStore } from '../../stores/useSettingsStore'
import type { Error2FA, Error2FACodes } from './interfaces'

type ErrorCodeMap = Record<Error2FACodes, string> | undefined

const CODE_LENGTH = 6

const { t } = useI18n()
const { startTimer, timer, rawTimer } = useTimeoutTimer()
const {
  startTimer: startCountDown,
  timer: countdown,
  rawTimer: rawCountDown,
} = useTimeoutTimer()

const { supportEmailLink } = useSupportUrl()

const props = withDefaults(
  defineProps<{
    isCodeValid: boolean
    isResendCodeVisible?: boolean
    error?: unknown
    isWide?: boolean
    platform?: 'desktop' | 'mobile'
    isNoEmailAccessVisible?: boolean
    resendTimeoutError?: boolean
    buttonType?: ButtonType
    noAccessButtonType?: ButtonType
  }>(),
  {
    isResendCodeVisible: true,
    isWide: false,
    platform: 'desktop',
    isNoEmailAccessVisible: true,
    buttonType: 'gray',
    noAccessButtonType: 'text-secondary',
  },
)

const { settings } = storeToRefs(useSettingsStore())
const confirmationTimeoutInSec = computed(
  () => settings.value?.confirmationTimeoutInSec ?? 180,
)

const code = ref('')

const codeInputSize = computed(() => {
  if (props.platform === 'mobile') {
    return 's'
  }

  if (props.platform === 'desktop' && props.isWide) {
    return 'l'
  }

  return 'm'
})

const emit = defineEmits<{
  (e: 'resendCodeClicked'): void
  (e: 'inputFilled', value: string): void
}>()

function refreshCountdown() {
  startCountDown(confirmationTimeoutInSec.value)
}

function resendCode() {
  refreshCountdown()
  emit('resendCodeClicked')
}

onMounted(() => {
  startCountDown(confirmationTimeoutInSec.value)
})

watch(
  () => props.error,
  () => {
    if (!props.error) return

    const errorBody = (props.error as Error2FA).data
    if (!errorBody) return

    const { error: errorCode, data } = errorBody

    if (errorCode === 'CONFIRMATION_CODE_MAX_ATTEMPTS_EXCEEDED') {
      const time = data.ttl || 0
      const timeout = Math.floor(time / 1000)
      if (!timeout) return

      startTimer(timeout)
    }
  },
)

const errorCodeMap: ComputedRef<ErrorCodeMap> = computed(() => {
  if (!props.error) return undefined

  const errorBody = (props.error as Error2FA).data
  if (!errorBody) return undefined

  const { data } = errorBody

  return {
    CONFIRMATION_CODE_NOT_VALID: t(
      'authorization.errorMessages.confirmationCodeisNotValid',
      {
        value: data?.attemptsLeft || 0,
      },
    ),
    CONFIRMATION_CODE_MAX_ATTEMPTS_EXCEEDED: t(
      'authorization.errorMessages.confirmationCodeMaxAttemptsError',
      {
        value: timer.value,
      },
    ),
  }
})

const errorMessage = computed(() => {
  if (!props.error) return undefined

  const errorBody = (props.error as Error2FA).data
  if (!errorBody) return undefined

  const { error: errorCode } = errorBody
  if (!errorCodeMap.value) return undefined

  if (
    rawTimer.value === 0 &&
    errorCode === 'CONFIRMATION_CODE_MAX_ATTEMPTS_EXCEEDED'
  ) {
    return undefined
  }

  return (
    errorCodeMap.value[errorCode as Error2FACodes] ||
    t('authorization.errorMessages.somethingWentWrong')
  )
})

defineExpose({
  restartTimer: () => refreshCountdown(),
})
</script>

<style scoped>
.resend-code {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--spacing-250);
}

.no-email-access {
  display: flex;
  align-items: center;
  justify-content: center;
}

.two-factor {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);

  &.mobile {
    gap: var(--spacing-150);

    .resend-code {
      margin-top: var(--spacing-300);
    }

    .no-email-access {
      margin-top: var(--spacing-050);
    }
  }
}
</style>
