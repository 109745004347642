import { useUserStore } from '@st/user/stores/useUserStore'
import { useFreebetsStore } from '../stores/useFreebetsStore'
import { useFreespinsStore } from '../stores/useFreespinsStore'
import { useCashBonusesStore } from '../stores/useCashBonusesStore'

/**
 * хук, отслеживающий статусы обкатки у бонусов юзера
 * при изменении текущего обкатываемого бонуса
 * ( перезапрашивает актуальные данные )
 */
export function useBonusesRollingSocket() {
  const { isAuthenticated } = storeToRefs(useUserStore())
  const io = useSocket()
  const { fetchFreebets } = useFreebetsStore()
  const { fetchFreespins } = useFreespinsStore()
  const { fetchCashBonuses } = useCashBonusesStore()

  function refetchBonuses() {
    if (!isAuthenticated.value) return
    fetchFreebets()
    fetchFreespins()
    fetchCashBonuses()
  }

  onMounted(() => {
    io.on('rollingStatus', refetchBonuses)
  })

  onBeforeUnmount(() => {
    io.off('rollingStatus', refetchBonuses)
  })
}
