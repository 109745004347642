<template>
  <section class="confirm-email" :class="platform" data-t="confirm-step">
    <div v-if="isMobile" class="mobile-header">
      <StDrawerHeader
        :title="t('profile.confirmEmail')"
        with-back-button
        @back="emit('back')"
      />
      <p class="subtitle">
        <I18nT keypath="profile.enterCodeEmail">
          <template #email>
            <b class="email">
              {{ emailStepData.email }}
            </b>
          </template>
        </I18nT>
      </p>
    </div>
    <header v-else class="confirm-header">
      <h2 class="title">{{ t('profile.confirmEmail') }}</h2>
      <p class="subtitle">
        <I18nT keypath="profile.enterCodeEmail">
          <template #email>
            <b class="email">
              {{ emailStepData.email }}
            </b>
          </template>
        </I18nT>
      </p>
    </header>
    <div class="wrapper">
      <St2FA
        :is-code-valid="isCodeValid"
        :error="error"
        :platform="platform"
        :is-no-email-access-visible="false"
        @input-filled="handleInputFilled"
        @resend-code-clicked="sendCode"
      />

      <StButton
        v-if="!isMobile"
        class="go-back-button"
        :label="t('authorization.secondStepGoBack')"
        size="l"
        type="text-only"
        icon="arrow-left"
        is-left-icon
        data-t="go-back-button"
        @click="emit('back')"
      />
    </div>
  </section>
</template>

<script setup lang="ts">
import St2FA from '@st/core/components/St2Fa/St2Fa.vue'
import { delay } from '@st/utils'
import StDrawerHeader from '@st/ui/components/StDrawer/StDrawerHeader.vue'
import { useUserAuthEmailCodeSent } from '../useUserAuthEmailCodeSent'
import type { EmailStepData } from '../types'

const { t } = useI18n()
const { isMobile, platform } = usePlatform()

const { emailStepData } = defineProps<{
  emailStepData: EmailStepData
}>()
const emit = defineEmits<{
  back: []
  success: []
}>()

const isCodeValid = ref(false)
const code = ref('')
const requestBody = computed(() => ({
  ...emailStepData,
  confirmationCode: code.value,
}))

const {
  execute,
  error,
  data: userData,
} = useStFetch('/user/auth/provider/email/create', {
  method: 'post',
  body: requestBody,
  immediate: false,
  watch: false,
})

async function sendRequest() {
  await execute()
  if (userData.value) {
    isCodeValid.value = true
    await delay(1500)
    emit('success')
  }
}

function handleInputFilled(value: string) {
  code.value = value

  sendRequest()
}

const { sendCode } = useUserAuthEmailCodeSent(computed(() => emailStepData))
</script>

<style scoped>
.confirm-header {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);
}

.title {
  margin: 0;
  font: var(--desktop-text-xl-semibold);
}

.subtitle {
  margin: 0;
  font: var(--desktop-text-sm-medium);
  color: var(--text-tertiary);
}

.go-back-button {
  margin-top: auto;
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--spacing-200);
}

.confirm-email {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--spacing-200);

  width: 100%;
  padding: var(--spacing-500) var(--spacing-600) var(--spacing-300);

  &.mobile {
    gap: var(--spacing-100);
    padding: 0;

    .wrapper {
      padding: 0 var(--spacing-200) var(--spacing-150);
    }

    .title {
      margin-bottom: var(--spacing-050);
      font: var(--mobile-title-2-semibold);
    }

    .subtitle {
      padding: 0 var(--spacing-200);
      font: var(--mobile-text-content-regular);
    }
  }
}

.email {
  color: var(--text-primary);
}
</style>
