import { filterObjectByFilledValues } from '@st/utils'
import { useAuthTelegramScript } from './useAuthTelegramScript'

// eslint-disable-next-line
type TelegramAuthParams = {
  language: any // 'en' | 'ru' | 'es' | 'pt'
  referralCode?: string
  promocodeName?: string
}

type AuthEndpoint = '/auth/telegram' | '/user/auth/telegram'

export function useTelegramAuthWidget() {
  const config = useRuntimeConfig()
  const botId = config.public.telegramAuthBotId as string
  const stFetch = useRawStFetch()
  const { onLoaded, onError } = useAuthTelegramScript()

  async function handleTelegramAuth(
    params: TelegramAuthParams,
    endpoint: AuthEndpoint = '/auth/telegram',
  ) {
    const filteredParams = filterObjectByFilledValues(params)

    return new Promise((resolve, reject) => {
      if (!botId) {
        console.error('NUXT_PUBLIC_TELEGRAM_AUTH_BOT_ID is missing')
        reject()
        return
      }

      onLoaded(({ auth }) => {
        /*
        вместо встраивания инлайн скрипта с iframe tg кнопкой
        вызывается auth метод из ранее загруженного скрипта
        принимающий botId и язык
        в колбэке возвращаются данные
        */
        const { language } = filteredParams
        auth(
          {
            bot_id: botId,
            request_access: 'write',
            lang: language ?? 'en',
          },
          async (tgData: any) => {
            if (!tgData) {
              console.error('Telegram authorization failed')
              reject()
              return
            }

            await stFetch(endpoint, {
              method: 'post',
              body: {
                ...filteredParams,
                tgData,
              },
            })

            resolve(tgData)
          },
        )
      })

      onError((error) => {
        console.error('Telegram web widget script loading error:', error)
        reject()
      })
    })
  }

  return {
    handleTelegramAuth,
  }
}
