<template>
  <div class="scores" :class="scoresClass">
    <div v-for="(qualifier, idx) in qualifiers" :key="idx" class="scores-row">
      <div class="scores-group">
        <template v-if="!isDefault">
          <div
            v-for="(periodScore, pscIdx) in props.periodScores"
            :key="pscIdx"
            class="scores-item"
            :data-label="periodScore.dataLabel || periodScore.number"
            :data-t="`${qualifier}-scores-item`"
          >
            <span>
              {{ periodScore[`${qualifier}_score`] || 0 }}
            </span>
          </div>
        </template>
        <div
          :class="!isDefault && 'total'"
          class="scores-item"
          :data-t="`${qualifier}-total-score`"
        >
          {{
            props.stats[`${qualifier}_legscore`] ||
            props.stats[`${qualifier}_score`] ||
            0
          }}
        </div>
        <StIcon
          v-if="props.server && props.server === idx + 1"
          name="game-server"
          :size="14"
          class="scores-lead"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { TMatchStats, TQualifier } from 'markets-store'
import type { TExtendedPeriodScore } from '../../composables/usePeriodScores'

interface Props {
  periodScores?: TExtendedPeriodScore[]
  stats?: TMatchStats
  server?: number
  isDefault?: boolean
  isCompact?: boolean
  size?: 's' | 'm'
  onlyScores?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  periodScores: () => [],
  stats: () => ({}),
  server: undefined,
  isDefault: false,
  isCompact: false,
  size: 's',
  onlyScores: false,
})
const qualifiers: TQualifier[] = ['home', 'away']

const scoresClass = computed(() => [
  { mini: props.isCompact, 'only-scores': props.onlyScores },
  props.size,
])
</script>

<style scoped>
.scores-item {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 20px;
  height: 20px;
  padding: 0 var(--spacing-025, 2px);

  font: var(--desktop-text-sm-medium);
  color: var(--text-tertiary);

  &[data-label]::before {
    bottom: -44px;

    overflow: hidden;

    max-width: 20px;

    font: var(--desktop-text-sm-medium);
    color: var(--text-tertiary);
    text-overflow: ellipsis;
  }

  &.total {
    position: relative;
    order: 1;
    color: var(--text-primary);
  }
}

.scores {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  &.m {
    gap: var(--spacing-050);

    .scores-item[data-label]::before {
      bottom: -46px;
    }
  }

  &.mini {
    .scores-item {
      &.total {
        &::after {
          display: none;
        }
      }

      &:not(.total) {
        display: none;
      }
    }
  }

  &.only-scores {
    .scores-item::before {
      display: none;
    }
  }
}

.scores-row {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;

  &:not(:last-child) {
    .scores-item[data-label]::before {
      content: attr(data-label);
      position: absolute;
    }
  }
}

.scores-group {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-start;

  min-width: 0;
}

.scores-lead {
  order: 1;
  color: var(--text-link);
}
</style>
