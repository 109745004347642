import { useUserStore } from '@st/user/stores/useUserStore'

export function usePreferredCountryCode() {
  const userStore = useUserStore()
  const { userSettings } = storeToRefs(userStore)
  const { updateUserSettings } = userStore

  const preferredCountryCode = computed({
    get: () => userSettings.value?.preferredCountryCodeForCurrencyExchange,
    set: (value: string) => {
      updateUserSettings([
        {
          name: 'preferredCountryCodeForCurrencyExchange',
          value: value.toUpperCase(),
        },
      ])
    },
  })

  return {
    preferredCountryCode,
  }
}
