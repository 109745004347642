import { createEntityDeps } from '@st/use/composables'

const [useVerificationDeps, provideVerificationDeps] = createEntityDeps<{
  userKycLevel: Ref<number | null>
  isReadyUser: Ref<boolean>
  isAuthenticated: Ref<boolean>
  isReadyTags: Ref<boolean>
  hasSkipKycLevelCheckOnDepositTag: Ref<boolean>
}>()

export { useVerificationDeps, provideVerificationDeps }
