<template>
  <div class="no-methods" :class="platform" data-t="no-methods">
    <div class="empty-cards">
      <img src="../assets/empty-card.svg" />
    </div>
    <div class="info">
      <div class="title">{{ t('payments.deposit.noMethodsTitle') }}</div>
      <div class="subtitle">
        {{ t('payments.deposit.noMethodsSubtitle') }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { platform } = usePlatform()
const { t } = useI18n()
</script>

<style scoped>
.no-methods {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);
  padding: var(--spacing-200) 0;
}

.empty-cards {
  display: flex;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-content: center;
}

.info {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-025);
  text-align: center;
}

.title {
  font: var(--desktop-text-md-semibold);
}

.subtitle {
  font: var(--desktop-text-xs-medium);
  color: var(--text-tertiary);
}

.no-methods.mobile {
  .info {
    gap: var(--spacing-075);
  }

  .title {
    font: var(--mobile-text-semibold);
  }

  .subtitle {
    font: var(--mobile-text-content-regular);
  }
}
</style>
