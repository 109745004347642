<template>
  <i18n-t
    keypath="notifications.body.freespinsAccrual"
    tag="p"
    class="text"
    data-t="body-message"
    :plural="count"
  >
    <template #count>
      <b>{{ count }}</b>
    </template>
  </i18n-t>
</template>

<script setup lang="ts">
import type { FreespinProgramCreated } from '../../../../types'

const props = defineProps<{
  notification: FreespinProgramCreated
}>()

const count = computed(() => props.notification.data.freespinNumber)
</script>

<style scoped>
.text {
  margin: 0;

  b {
    color: var(--text-primary);
  }
}
</style>
