import type { paths } from '@st/api/paths'
import type { ResponseError } from '@st/openapi-tools'
import { useAccountsStore } from '@st/payments/stores/useAccountsStore'
import { useCurrenciesStore } from '@st/payments/stores/useCurrenciesStore'
import { useUserStore } from '@st/user/stores/useUserStore'
import { useAnalytics } from '@st/analytics/composables/useAnalytics'

export type DemoInitError = ResponseError<
  paths,
  '/casino/game/init/demo',
  'post'
>
export type RealInitError = ResponseError<paths, '/casino/game/init', 'post'>
export type GameInitError = DemoInitError | RealInitError

interface LauchGameOptions {
  gameId: number
  gameMode: 'real' | 'demo'
}

interface LaunchGameResult {
  result:
    | 'success'
    | 'initError'
    | 'authError'
    | 'geoError'
    | 'authErrorWithDemoAvailable'
    | 'geoErrorWithDemoAvaiable'
  error?: GameInitError
}

export const useGameInitStore = defineStore('gameInit', () => {
  const initError = ref<GameInitError | null>(null)
  const frameUrl = ref<string | null>(null)
  const sessionId = ref<string | null>(null)
  const gameMode = ref<'demo' | 'real' | null>(null)
  const { sendEvent } = useAnalytics()

  const stFetch = useRawStFetch()
  const {
    userSettings,
    isAuthenticated,
    isReady: isReadyAuth,
  } = storeToRefs(useUserStore())
  const { getCurrencyByCode } = useCurrenciesStore()
  const { activeAccount } = storeToRefs(useAccountsStore())
  const { isReady: isReadyGeo, isAvailable } = useGeoAvailability()

  const gameCurrencyId = computed(() => {
    const code = userSettings.value?.preferredCurrencyCodeForCasino
    return code ? getCurrencyByCode(code)?.id : undefined
  })

  function clear() {
    initError.value = null
    frameUrl.value = null
    sessionId.value = null
    gameMode.value = null
  }

  async function initRealGame(gameId: number) {
    await until(
      () => !!gameCurrencyId.value && !!activeAccount.value?.id,
    ).toBeTruthy()

    const { data, error } = await stFetch('/casino/game/init', {
      method: 'post',
      body: {
        gameId,
        gameCurrencyId: gameCurrencyId.value as number,
        accountId: activeAccount.value?.id,
      },
    })

    if (error) {
      initError.value = error
    } else {
      clear()
      sessionId.value = data.sessionId
      frameUrl.value = data.url
      gameMode.value = 'real'

      sendEvent('initGame', {
        sessionId: data.sessionId,
        gameId,
        gameMode: 'real',
      })
    }
  }

  async function initDemoGame(gameId: number) {
    const { data, error } = await stFetch('/casino/game/init/demo', {
      method: 'post',
      body: {
        gameId,
      },
    })

    if (error) {
      initError.value = error
    } else {
      clear()
      gameMode.value = 'demo'
      frameUrl.value = data.url

      sendEvent('initGame', {
        gameId,
        gameMode: 'demo',
      })
    }
  }

  async function checkIfGameHasDemo(gameId: number) {
    const {
      data: { data: games },
    } = await stFetch('/casino/game/find', {
      method: 'post',
      body: {
        pagination: {
          page: 0,
          perPage: 1,
          orderBy: [
            {
              fieldName: 'gameId',
              sortOrder: 'ASC',
            },
          ],
        },
        params: {
          gameId: [gameId],
        },
      },
    })
    return Boolean(games?.[0]?.hasDemoMode)
  }

  async function launchGame(
    options: LauchGameOptions,
  ): Promise<LaunchGameResult> {
    if (options.gameMode === 'demo') {
      await initDemoGame(options.gameId)
      if (initError.value) {
        return {
          result: 'initError',
          error: initError.value,
        }
      }
      return {
        result: 'success',
      }
    }

    await until(isReadyGeo).toBeTruthy()
    if (!isAvailable.value) {
      const hasDemo = await checkIfGameHasDemo(options.gameId)
      if (hasDemo) {
        return {
          result: 'geoErrorWithDemoAvaiable',
        }
      }

      return {
        result: 'geoError',
      }
    }

    await until(isReadyAuth).toBeTruthy()
    if (!isAuthenticated.value) {
      const hasDemo = await checkIfGameHasDemo(options.gameId)
      if (hasDemo) {
        return {
          result: 'authErrorWithDemoAvailable',
        }
      }

      return {
        result: 'authError',
      }
    }

    await initRealGame(options.gameId)
    if (initError.value) {
      return {
        result: 'initError',
        error: initError.value,
      }
    }
    return {
      result: 'success',
    }
  }

  return {
    launchGame,
    frameUrl,
    sessionId,
    gameMode,
    initError,
    checkIfGameHasDemo,
  }
})
