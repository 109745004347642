type RedirectError =
  | 'ALREADY_LOGGED_IN'
  | 'AUTH_FORBIDDEN'
  | 'BAD_REQUEST'
  | 'FORBIDDEN_BY_COUNTRY'
  | 'GOOGLE_OIDC_DISABLED'
  | 'GOOGLE_OIDC_ERROR'
  | 'GOOGLE_OIDC_REGISTRATION_DISABLED'
  | 'REGISTRATION_DISABLED'
  | 'SECURITY_TOKEN_NOT_MATCH'
  | 'UNAUTHORIZED'
  | 'USER_ALREADY_EXISTS'

type EndpointParam =
  | '/site/api/auth/google/redirect'
  | '/site/api/user/auth/google/redirect'

export function useGoogleRedirect() {
  const url = useRequestURL()
  const redirectErrorQuery = useRouteQuery<RedirectError | null>(
    'authError',
    null,
  )
  const { isMobile } = usePlatform()

  async function handleGoogleRedirect(
    params?: Record<string, string | undefined>,
    endpoint: EndpointParam = '/site/api/auth/google/redirect',
  ) {
    const urlParams = new URLSearchParams()

    if (params) {
      Object.entries(params).forEach(([key, value]) => {
        if (value) {
          urlParams.append(key, value)
        }
      })
    }

    urlParams.append(
      'platformId',
      isMobile.value ? 'mobilesite' : 'desktopsite',
    )

    const domainOrigin = url.origin
    const googleRedirectUrl = `${domainOrigin}${endpoint}?${urlParams.toString()}`

    await navigateTo(googleRedirectUrl, {
      external: true,
    })
  }
  const { t } = useI18n()
  const { open } = useToast()

  onMounted(() => {
    if (!redirectErrorQuery.value) {
      return
    }

    let errorText = t('authorization.errorMessages.somethingWentWrong')
    if (redirectErrorQuery.value === 'USER_ALREADY_EXISTS') {
      errorText = t('authorization.errorMessages.userAlreadyExists')
    }
    open({
      label: errorText,
      type: 'negative',
    })
  })

  return {
    handleGoogleRedirect,
  }
}
