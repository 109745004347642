<template>
  <div>
    <i18n-t
      v-if="status === 'rejected'"
      keypath="notifications.body.verificationRejected"
      tag="p"
      class="text"
    >
      <template #level>
        <b>{{ level }}</b>
      </template>
    </i18n-t>
    <i18n-t
      v-else-if="status === 'userWait'"
      keypath="notifications.body.verificationActionNeed"
      tag="p"
      class="text"
    >
      <template #level>
        <b>{{ level }}</b>
      </template>
    </i18n-t>
    <i18n-t
      v-else
      keypath="notifications.body.verificationSuccess"
      tag="p"
      class="text"
    >
      <template #level>
        <b>{{ level }}</b>
      </template>
    </i18n-t>

    <StButton
      v-if="showVerificationButton"
      class="button"
      size="s"
      :label="t('verification.passVerification')"
      :to="{ query: { modal: 'sumSubVerification' } }"
    />
  </div>
</template>

<script setup lang="ts">
import type { VerificationNotification } from '../../../../types'

const props = defineProps<{
  notification: VerificationNotification
}>()

const { type, status } = toRefs(props.notification.data)
const { t } = useI18n()

const setLevel = (lvl: number) =>
  t('dashboard.verificationLevel', { level: lvl })

const level = computed(() => {
  switch (type.value) {
    case 'actionLiveness':
      return t('notifications.body.biometric')
    case 'actionSourceOfFunds':
      return setLevel(3)
    case 'level2':
      return setLevel(2)
    default:
      return setLevel(1)
  }
})

const showVerificationButton = computed(() => status.value === 'userWait')
</script>

<style scoped>
.text {
  margin: 0;

  b {
    color: var(--text-primary);
  }
}

.button {
  width: 100%;
  margin-top: var(--spacing-150);
}
</style>
