<template>
  <div class="two-factor-auth" :class="platform" data-t="two-factor-auth">
    <h2 v-if="platform === 'desktop'">{{ title }}</h2>
    <StDrawerHeader
      v-else
      with-back-button
      @back="emit('back')"
      @close="goBack"
    >
      <h2>{{ title }}</h2>
    </StDrawerHeader>
    <div class="subtitle">
      <i18n-t keypath="payments.twoFactorAuth.emailCodeSend">
        <template #email>
          <a
            v-if="hasTelegramProvider"
            href="https://t.me/BetoneComBot"
            class="telegram-bot"
            target="_blank"
          >
            <img
              src="./assets/telegram.svg"
              :width="platform === 'desktop' ? 20 : 16"
            />
            {{ t('payments.twoFactorAuth.telegramBot') }}
          </a>
          <span v-else>
            {{ user?.email }}
          </span>
        </template>
      </i18n-t>
    </div>
    <div class="content">
      <St2Fa
        :is-code-valid="isCodeValid"
        :error="error"
        :platform="platform"
        is-wide
        @input-filled="handleCodeEntered"
        @resend-code-clicked="handleRetry"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUserAuthProviders } from '@st/user/composables/useUserAuthProviders'
import { useUserStore } from '@st/user/stores/useUserStore'

const { t } = useI18n()

const isCodeValid = ref(false)

const { goBack } = useRouterBack()

withDefaults(
  defineProps<{
    title?: string
    error?: any
    platform: 'desktop' | 'mobile'
  }>(),
  {
    platform: 'desktop',
  },
)

const { user } = storeToRefs(useUserStore())
const { hasTelegramProvider } = useUserAuthProviders()

const emit = defineEmits<{
  (e: 'codeEntered', enteredCode: string): void
  (e: 'retry'): void
  (e: 'back'): void
}>()

function handleCodeEntered(value: string) {
  emit('codeEntered', value)
}

function handleRetry() {
  emit('retry')
}
</script>

<style scoped>
.two-factor-auth {
  display: flex;
  flex-direction: column;
}

h2 {
  margin: 0;
  padding: 0;
  font: var(--desktop-text-2xl-semibold);
}

.subtitle {
  display: inline;
  margin-top: var(--spacing-100);
  font: var(--desktop-text-md-medium);
  color: var(--text-tertiary);

  .telegram-bot {
    align-items: center;
    color: #32a1ff; /* stylelint-disable-line */
    text-decoration: none;
    white-space: nowrap;
  }

  img {
    margin-left: var(--spacing-050);
    line-height: 0;
    vertical-align: middle;
  }

  span,
  a {
    color: var(--text-primary);
  }
}

.content {
  margin-top: var(--spacing-300);
}

.retry-code {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    margin-right: var(--spacing-050);
    font: var(--desktop-text-xs-medium);
    color: var(--text-tertiary);
  }
}

.timeout {
  margin: 0;
  font: var(--desktop-text-xs-medium);
  color: var(--text-tertiary);
  text-align: center;
}

.recovery-code-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);
  align-items: center;

  margin-top: var(--spacing-300);
}

.two-factor-auth.mobile {
  width: 100%;

  h2 {
    font: var(--mobile-title-2-semibold);
  }

  .subtitle {
    padding: 0 var(--spacing-200);
    font: var(--mobile-text-content-regular);

    img {
      margin: 0;
    }
  }

  .content {
    padding: 0 var(--spacing-200);
  }

  .retry-code {
    .timeout {
      font: var(--mobile-text-content-regular);
    }

    span {
      font: var(--mobile-text-content-regular);
    }
  }
}
</style>
