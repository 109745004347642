import revive_payload_client_YTGlmQWWT5 from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PKIXU5KmsI from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Ib6KnAhqrq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/apps/site/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/app/apps/site/.nuxt/pwa-icons-plugin.ts";
import pwa_client_bU9rlafmKr from "/app/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import switch_locale_path_ssr_lCxu0ZmxIx from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_5C7QFp7tsq from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import verificationModalsPlugin_client_penz9KGRm2 from "/app/entities/st-verification/plugins/verificationModalsPlugin.client.ts";
import ipqs_client_8xWDgrlfma from "/app/packages/st-ipqs/plugins/ipqs.client.ts";
import intercom_client_YH0KQbdFwS from "/app/packages/st-intercom/plugins/intercom.client.ts";
import recaptcha_TtT6SAJH6r from "/app/packages/st-nuxt-recaptcha/plugins/recaptcha.ts";
import init_wv74f4jq58 from "/app/packages/st-base-localization/plugins/init.ts";
import init_lw4AB3Lbis from "/app/packages/st-i18n/plugins/init.ts";
import socketIo_2chAVMxtF0 from "/app/packages/st-nuxt-sockets/plugins/socketIo.ts";
import ipRestrictionPlugin_sXXEFl5WnA from "/app/packages/st-core/plugins/ipRestrictionPlugin.ts";
import autoAuthPlugin_client_MeYY1VGyEO from "/app/entities/st-user/plugins/autoAuthPlugin.client.ts";
import referralCodePlugin_client_nTHPg3NoU4 from "/app/entities/st-user/plugins/referralCodePlugin.client.ts";
import showTelegramAuth_client_jLPtSlc6Ga from "/app/entities/st-user/plugins/showTelegramAuth.client.ts";
import telegramBackButtonWatcher_client_sEwLn03ApV from "/app/entities/st-user/plugins/telegramBackButtonWatcher.client.ts";
import analytics_init_client_NlPomQn94f from "/app/packages/st-analytics/plugins/analytics-init.client.ts";
import telegram_init_client_9mOgZ7iRRu from "/app/packages/st-telegram/plugins/telegram-init.client.ts";
import apiEventBusPlugin_6fcmhSsfcj from "/app/packages/st-api/plugins/apiEventBusPlugin.ts";
import partner_client_7LhgciUFav from "/app/packages/st-partner/plugins/partner.client.ts";
import gtm_client_cFQaMrNz9U from "/app/packages/st-gtm/plugins/gtm.client.ts";
import sentry_client_jy28sex9Fj from "/app/packages/st-nuxt-sentry/plugins/sentry.client.ts";
import errorHandler_x1Y4n5NP4w from "/app/apps/site/plugins/errorHandler.ts";
import markets_store_QwbHfVcmtZ from "/app/packages/st-nuxt-markets-store/plugins/markets-store.ts";
import diContainer_ENLzo1I7pL from "/app/apps/site/plugins/diContainer.ts";
import init_X5h4CJ85GB from "/app/packages/st-core/plugins/init.ts";
import userLogoutPlugin_client_tmys9NJmps from "/app/entities/st-user/plugins/userLogoutPlugin.client.ts";
import initCasinoStore_Pzixwrrhi4 from "/app/entities/st-casino/plugins/initCasinoStore.ts";
import initHomeStore_LaEaXqulxe from "/app/entities/st-bookmaker/plugins/initHomeStore.ts";
import paymentStoresInit_client_RGCSvMhblU from "/app/entities/st-payments/plugins/paymentStoresInit.client.ts";
import initCouponStore_RGHJYitbbI from "/app/entities/st-coupon/plugins/initCouponStore.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  navigation_repaint_client_PKIXU5KmsI,
  check_outdated_build_client_LIYcCMJD18,
  chunk_reload_client_SeG0EjL5Ec,
  plugin_vue3_Ib6KnAhqrq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_bU9rlafmKr,
  switch_locale_path_ssr_lCxu0ZmxIx,
  i18n_5C7QFp7tsq,
  verificationModalsPlugin_client_penz9KGRm2,
  ipqs_client_8xWDgrlfma,
  intercom_client_YH0KQbdFwS,
  recaptcha_TtT6SAJH6r,
  init_wv74f4jq58,
  init_lw4AB3Lbis,
  socketIo_2chAVMxtF0,
  ipRestrictionPlugin_sXXEFl5WnA,
  autoAuthPlugin_client_MeYY1VGyEO,
  referralCodePlugin_client_nTHPg3NoU4,
  showTelegramAuth_client_jLPtSlc6Ga,
  telegramBackButtonWatcher_client_sEwLn03ApV,
  analytics_init_client_NlPomQn94f,
  telegram_init_client_9mOgZ7iRRu,
  apiEventBusPlugin_6fcmhSsfcj,
  partner_client_7LhgciUFav,
  gtm_client_cFQaMrNz9U,
  sentry_client_jy28sex9Fj,
  errorHandler_x1Y4n5NP4w,
  markets_store_QwbHfVcmtZ,
  diContainer_ENLzo1I7pL,
  init_X5h4CJ85GB,
  userLogoutPlugin_client_tmys9NJmps,
  initCasinoStore_Pzixwrrhi4,
  initHomeStore_LaEaXqulxe,
  paymentStoresInit_client_RGCSvMhblU,
  initCouponStore_RGHJYitbbI
]