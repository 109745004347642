<template>
  <StSelectField is-default-click-disabled>
    <div class="region-section">
      <StIcon :name="icon" />
      <div class="country">
        <div class="country-title">
          {{ t('payments.deposit.methodsForRegion') }}
        </div>
        <div class="country-name" data-t="country-name">
          {{ fullName }}
        </div>
      </div>
      <div>
        <StButton
          :label="t('payments.deposit.changeButton')"
          size="m"
          type="ghost"
          data-t="region-select-button"
          @click="toggle"
        />
      </div>
    </div>
  </StSelectField>
</template>

<script setup lang="ts">
import StSelectField from '@st/ui/components/StBaseSelect/parts/StSelectField.vue'
import type { BaseSelectApi } from '@st/ui/components/StBaseSelect/types'
import type { IconName } from '@st/ui/components/StIcon/types'

defineProps<{
  fullName?: string
  icon?: IconName
}>()

const emit = defineEmits<{
  toggle: []
}>()

const { t } = useI18n()

const selectApi: BaseSelectApi | undefined = inject('StBaseSelectApi')
function toggle() {
  selectApi?.toggleDropdown()
  emit('toggle')
}
</script>

<style scoped>
.region-section {
  display: flex;
  gap: var(--spacing-150, 12px);
  align-items: center;
  align-self: stretch;
  justify-content: space-between;

  width: 100%;
  padding: var(--spacing-100, 8px) var(--spacing-100, 8px)
    var(--spacing-100, 8px) var(--spacing-150, 12px);

  background: var(--background-base, #111118);
  border: var(--border-width-medium, 1px) solid
    var(--border-secondary, rgb(255 255 255 / 8%));
  border-radius: var(--border-radius-150, 12px);
}

.country {
  flex-grow: 1;
}

.country-title {
  margin-bottom: var(--spacing-025);
  font: var(--mobile-caption-1-regular);
  color: var(--text-tertiary);
}

.country-name {
  font: var(--mobile-text-semibold);
}
</style>
