import { useUserStore } from '@st/user/stores/useUserStore'

import type {
  UserFreespinProgramStatusPayload,
  UserBonusForDepositProgramPayload,
} from '../types'

/**
 * хук, добавляющий слушатели на события обновления бонусов
 * userFreespinProgramStatus - userFreespinProgramStatus не равен ('new', 'inProgress', 'rolling')
 * bonusClaimed
 * bonusExpired
 * bonusForDepositProgramUserStatus
 * freebetProcessed
 * bonusRollingCompleted
 * userFreespinProgramCreated
 * bonusForDepositProgramUserCreated
 * freebetCreated
 */
export function useRefreshBonusesSocket(refreshFn: () => void) {
  const { isAuthenticated } = storeToRefs(useUserStore())
  const io = useSocket()

  function handleRefresh() {
    if (!isAuthenticated.value) return

    refreshFn()
  }
  function handleFreespinProgramStatusChange(
    payload: UserFreespinProgramStatusPayload,
  ) {
    if (
      ['new', 'inProgress', 'rolling'].includes(
        payload.userFreespinProgramStatus,
      )
    )
      return

    handleRefresh()
  }
  function handleBonusForDepositProgramChange(
    payload: UserBonusForDepositProgramPayload,
  ) {
    if (payload.bonusForDepositProgramUserStatus === 'inProgress') return

    handleRefresh()
  }

  function addIoListeners() {
    io.on('bonusClaimed', handleRefresh)
    io.on('bonusExpired', handleRefresh)
    io.on('freebetProcessed', handleRefresh)
    io.on('bonusRollingCompleted', handleRefresh)
    io.on('welcomeProgram', handleRefresh)
    io.on('userFreespinProgramCreated', handleRefresh)
    io.on('bonusForDepositProgramUserCreated', handleRefresh)
    io.on('freebetCreated', handleRefresh)
    io.on('userFreespinProgramStatus', handleFreespinProgramStatusChange)
    io.on(
      'bonusForDepositProgramUserStatus',
      handleBonusForDepositProgramChange,
    )
  }

  addIoListeners()
}
