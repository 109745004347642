import { isTruthy } from '@st/utils'
import { useUserStore } from '@st/user/stores/useUserStore'
import type { Category } from '../types'

export const useCasinoStore = defineStore('casino', () => {
  const { locale } = useNuxtApp().$i18n

  const { data: categories, status } = useStFetch('/casino/category/find', {
    method: 'post',
    body: computed(() => ({
      params: {
        language: locale.value as any,
      },
      pagination: {
        page: 0,
        perPage: 500,
        orderBy: [
          {
            fieldName: 'weight' as const,
            sortOrder: 'DESC' as const,
          },
        ],
      },
    })),
    transform: (response) =>
      response.data.map((category) => ({
        ...category,
        name: category.name || category.code,
      })),
    default: () => [],
  })

  const pending = computed(() => status.value === 'pending')

  const mainCategories = computed<Category[]>(() =>
    categories.value
      .filter((category) => category.type === 'main')
      .sort((a, b) => b.weight - a.weight),
  )

  const firstMainCategory = computed(() => mainCategories.value[0])

  const mainPageCategories = computed<Category[]>(() =>
    categories.value.filter((category) => category.type === 'mainPage'),
  )

  const menuCategories = computed<Category[]>(() =>
    categories.value.filter((category) => category.visibleForLeftMenu),
  )

  const otherCategories = computed<Category[]>(() =>
    categories.value.filter((category) => category.type === 'other'),
  )

  const categoryMap = computed(() =>
    categories.value.reduce<Map<string | number, Category>>((acc, category) => {
      acc.set(category.code, category)
      acc.set(category.id, category)
      return acc
    }, new Map()),
  )

  function getCategoryByCode(code: string): Category | undefined {
    return categoryMap.value.get(code)
  }

  function getCategoryNameById(id: number): Category['name'] | undefined {
    return categoryMap.value.get(id)?.name
  }

  const isLoading = computed(() => pending.value && !categories.value.length)

  // это все тут для того, чтобы не было перекрестных зависимостей с @st/bonuses
  const freespins = ref()
  const stFetch = useRawStFetch()
  async function fetchFreespins() {
    const { data } = await stFetch('/user-freespin-program/find', {
      method: 'post',
      body: {
        params: {
          status: ['new', 'inProgress', 'rolling'],
        },
        pagination: {
          page: 0,
          perPage: 50,
          orderBy: [
            {
              fieldName: 'createdAt',
              sortOrder: 'DESC',
            },
          ],
        },
      },
    })
    freespins.value = data?.data
  }

  const { isAuthenticated } = storeToRefs(useUserStore())

  const freespinGamesIds = computed<Set<number>>(
    () =>
      new Set(
        freespins.value
          ?.map((item: any) => item.freespinGameId)
          .filter(isTruthy),
      ),
  )
  watch(
    () => isAuthenticated.value,
    (value, oldValue) => {
      if (value && value !== oldValue) fetchFreespins()
    },
    { immediate: true },
  )

  const io = useSocket()

  io.on('userFreespinProgramStatus', async () => {
    await fetchFreespins()
  })

  function checkGameHasFreespins(gameId: number) {
    return freespinGamesIds.value.has(gameId)
  }

  const isUserHasFreespins = computed(() => freespinGamesIds.value.size > 0)

  return {
    categories,
    mainCategories,
    mainPageCategories,
    menuCategories,
    isLoading,
    otherCategories,
    firstMainCategory,
    getCategoryByCode,
    getCategoryNameById,
    freespinGamesIds,
    checkGameHasFreespins,
    fetchFreespins,
    isUserHasFreespins,
  }
})
