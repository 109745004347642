<template>
  <div class="providers" :class="wrapperClasses">
    <div class="title">
      <h2>{{ t('payments.deposit.selectProvider') }}</h2>
      <div class="convert-rate">{{ convertRateText }}</div>
    </div>
    <StRadioGroup v-model="provider" name="providers">
      <div class="content">
        <div
          v-for="providerItem in providers"
          :key="providerItem.name"
          class="provider"
          :class="{
            disabled: providerItem.isDisabled,
            selected: providerItem.name === provider,
          }"
          @click="handleProviderClick(providerItem)"
        >
          <img :src="providerItem.icon" width="32" />
          <div class="provider-name">{{ providerItem.title }}</div>
          <div class="radio-button-wrapper">
            <StRadio
              :model-data="providerItem.name"
              :disabled="providerItem.isDisabled"
            />
          </div>
        </div>
      </div>
    </StRadioGroup>
  </div>
</template>

<script setup lang="ts">
import Decimal from '@st/decimal'
import { useExchangeDataStore } from '../../../stores/useExchangeDataStore'
import calypsoIcon from '../assets/calypso.svg'
import moonpayIcon from '../assets/moonpay.svg'
import type { ExchangePaymentProvider } from '../../../interfaces'

const { t } = useI18n()
const { platform } = usePlatform()

const exchangeDataStore = useExchangeDataStore()
const { setSelectedProvider } = exchangeDataStore
const { selectedMethod, convertRate, selectedFiatCurrency, selectedCurrency } =
  storeToRefs(exchangeDataStore)

const { format: formatCrypto } = useCryptoFormatter({
  currency: computed(() => selectedCurrency.value.code),
})

const provider = ref<string>(
  selectedMethod.value?.providers[0].provider as string,
)

onMounted(() => {
  setSelectedProvider(provider.value as ExchangePaymentProvider)
})

watch(provider, (newValue) => {
  setSelectedProvider(newValue as ExchangePaymentProvider)
})

interface Provider {
  title: string
  name: string
  icon: string
  isDisabled: boolean
}

const providers = ref<Provider[]>([
  {
    title: 'Calypso Pay',
    name: 'calypso',
    icon: calypsoIcon,
    isDisabled: !selectedMethod.value?.providers.find(
      (item) => item.provider === 'calypso',
    ),
  },
  {
    title: 'Moon Pay',
    name: 'moonpay',
    icon: moonpayIcon,
    isDisabled: !selectedMethod.value?.providers.find(
      (item) => item.provider === 'moonpay',
    ),
  },
])

const wrapperClasses = computed(() => [platform.value])

const convertRateText = computed(() => {
  if (!convertRate.value) return ''

  const rate = new Decimal(1)
    .mul(convertRate.value)
    .toDecimalPlaces(3)
    .toString()

  return `${formatCrypto(1)} ≈ ${rate} ${
    selectedFiatCurrency.value?.title ?? ''
  }`
})

function handleProviderClick(providerItem: Provider) {
  if (providerItem.isDisabled) return

  provider.value = providerItem.name
}
</script>

<style scoped>
.providers {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-125);
  height: 140px;
}

.title {
  position: relative;
}

.convert-rate {
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  align-items: center;

  height: 100%;

  font: var(--desktop-text-sm-medium);
  color: var(--text-secondary);
}

h2 {
  margin: 0;
  padding: 0;
  font: var(--desktop-text-2lg-semibold);
  color: var(--text-primary);
}

.content {
  display: flex;
  gap: var(--spacing-150);
}

.provider {
  cursor: pointer;

  position: relative;

  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);

  width: 292px;
  padding: var(--spacing-200) var(--spacing-200) var(--spacing-150);

  background-color: var(--background-primary);
  border-radius: var(--border-radius-150, 12px);

  &:hover {
    box-shadow: 0 0 0 1px var(--icon-brand) inset;
  }

  &.selected {
    box-shadow: 0 0 0 1px var(--icon-brand) inset;
  }

  &.disabled {
    box-shadow: none;
  }

  :deep(.checkmark) {
    right: 0;
    left: unset;
  }
}

.provider-name {
  font: var(--desktop-text-md-semibold);
}

.radio-button-wrapper {
  position: absolute;
  top: 10px;
  right: 10px;
}

.disabled {
  opacity: 0.3;
}

.providers.mobile {
  h2 {
    font: var(--mobile-headline-semibold);
  }

  .convert-rate {
    font: var(--mobile-caption-1-regular);
  }
}
</style>
