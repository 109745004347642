<template>
  {{ notification.data.operationType }}
  <i18n-t
    v-if="notification.data.operationType === 'freebetAccrual'"
    keypath="notifications.body.freebetAccrual"
    tag="p"
    class="text"
    data-t="body-message"
  >
    <template #amount>
      <b>{{ notificationAmount }}</b>
    </template>
  </i18n-t>

  <i18n-t
    v-else
    keypath="notifications.body.freebetWinAccrual"
    tag="p"
    class="text"
    data-t="body-message"
  >
    <template #amount>
      <b>{{ notificationAmount }}</b>
    </template>
  </i18n-t>
</template>

<script setup lang="ts">
import type { AccountTransactionNotification } from '../../../../types'

const props = defineProps<{
  notification: AccountTransactionNotification
}>()

const { notification } = toRefs(props)
const { notificationAmount } = useCurrencyNotification(notification)
</script>

<style scoped>
.text {
  margin: 0;

  b {
    color: var(--text-primary);
  }
}
</style>
