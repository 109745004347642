import type { TSportEvent } from 'markets-store'
import type { Game } from '@st/casino/types'
import type { Tab } from '@st/ui/components/StTabs/types'
import { useSearchStore } from '../../stores/useSearchStore'

export type SearchTab = 'casino' | 'sport'

interface UseSearchReturn {
  tabs: Tab[]
  setSearch: (value: string) => void
  resetSearch: () => void
  searchValue: Ref<string>
  casinoGames: Ref<Game[]>
  selectedTab: Ref<SearchTab>
  isLoading: ComputedRef<boolean>
  bookmakerEvents: Ref<TSportEvent[]>
}

export function useSearch(): UseSearchReturn {
  const { t } = useI18n()

  const searchValue = ref('')

  function resetSearch() {
    searchValue.value = ''
  }

  function setSearch(value: string) {
    searchValue.value = value
  }

  const { bookmakerEvents } = useBookmakerSearch(searchValue)
  const { casinoGames, casinoStatus } = useCasinoSearch(searchValue)
  const { searchValue: prevSearchValue } = storeToRefs(useSearchStore())
  const { setSearchValue } = useSearchStore()

  const selectedTab = ref<SearchTab>('casino')
  const tabs: Tab[] = [
    {
      id: 'casino',
      label: t('search.casino'),
      icon: 'casino',
    },
    {
      id: 'sport',
      label: t('search.sport'),
      icon: 'all-sports',
    },
  ]

  const { currentPage } = useMatchLinkToRoute()

  onMounted(() => {
    switch (currentPage.value) {
      case 'sport':
        selectedTab.value = 'sport'
        break
      case 'cybersport':
        selectedTab.value = 'sport'
        break
      case 'casino':
        selectedTab.value = 'casino'
        break

      default:
        selectedTab.value = 'casino'
        break
    }
    searchValue.value = prevSearchValue.value
  })

  watch(searchValue, (newValue) => {
    setSearchValue(newValue)
  })

  const isLoading = computed(
    () =>
      casinoStatus.value === 'pending' ||
      casinoStatus.value === ('debounce' as any),
  )

  return {
    tabs,
    setSearch,
    resetSearch,
    searchValue,
    bookmakerEvents,
    casinoGames,
    selectedTab,
    isLoading,
  }
}
