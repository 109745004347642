<template>
  <Html v-if="isOnline">
    <Head>
      <VitePwaManifest />
    </Head>
    <Body>
      <StModalManager :modals="modalConfig" />
      <StToastManager :position="toastsPosition" />
      <NuxtLayout>
        <NuxtPage @click="handleClick" />
        <NotificationsManager />
      </NuxtLayout>
      <Cookies />
    </Body>
  </Html>
  <OfflinePage v-else />
</template>

<script lang="ts" setup>
import { useUserStore } from '@st/user/stores/useUserStore'
import { useActiveBetsStore } from '@st/coupon/stores/useActiveBetsStore'
import { useDepositBonusesStore } from '@st/bonuses/stores/useDepositBonusesStore'
import { useGlobalOutcomeButtonClickHandler } from '@st/coupon/composables/useGlobalOutcomeButtonClickHandler'
import { useBonusesRollingSocket } from '@st/bonuses/composables/useBonusesRollingSocket'
import { useModalConfig } from './modal.config'
import OfflinePage from './components/OfflinePage/OfflinePage.vue'

const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
})

useHead({
  htmlAttrs: {
    lang: head.value?.htmlAttrs?.lang ?? 'en',
    dir: head.value?.htmlAttrs?.dir ?? 'ltr',
  },
  link: [...(head.value?.link || [])],
  meta: [...(head.value?.meta || [])],
})

const { isMobile } = usePlatform()
const toastsPosition = computed(() => (isMobile.value ? 'top' : 'bottom'))
const modalConfig = useModalConfig()

/*
  по какой-то причине после fix extends resolutions
  сторы инитятся до всех плагинов
  и внутри него нет io
*/
const io = useSocket()
const userStore = useUserStore()
const { handleFullLogout } = userStore
const { user } = storeToRefs(userStore)
const { activeBetListener } = useActiveBetsStore()

io.on('fullLogout', handleFullLogout)
io.on('betStatus', activeBetListener)
io.on('userKycLevelSet', ({ kycLevel }) => {
  if (user.value) user.value.kycLevel = kycLevel
})

const { isAuthenticated, isManualLogin } = storeToRefs(useUserStore())

const { handleDepositModalShow } = useDepositBonusesStore()

watch(
  () => isAuthenticated.value,
  (isAuth) => {
    if (isAuth && isManualLogin.value) {
      handleDepositModalShow()
    }
  },
)

const { isOnline } = useOnline()
const { handleClick } = useGlobalOutcomeButtonClickHandler()

useBonusesRollingSocket()
</script>

<style>
@import url('@st/fonts/SFProDisplay/index.css');

:root {
  scrollbar-gutter: stable;
  font-family: var(--sf-pro-display);
  background-color: var(--background-base);
}

/* hide scroll with modal */
.no-scroll {
  overflow: hidden;
  height: 100%;
}

/* hide recaptcha badge */
.grecaptcha-badge {
  visibility: hidden;
}
</style>
