<template>
  <p class="text">
    {{
      t('notifications.body.userAuthProviderCreated', {
        provider: notificationProvider,
      })
    }}
  </p>
</template>

<script setup lang="ts">
import type { UserAuthProviderCreated } from '../../../../types'

const { t } = useI18n()

const { notification } = defineProps<{
  notification: UserAuthProviderCreated
}>()

const notificationProvider = computed(
  () => ({ telegram: 'Telegram' })[notification.data.provider],
)
</script>

<style scoped>
.text {
  margin: 0;
  font: var(--desktop-text-xs-medium);
  color: var(--text-secondary);
}
</style>
