export default defineNuxtPlugin(() => {
  onNuxtReady(() => {
    const telegram = globalThis.Telegram?.WebApp

    if (telegram) {
      telegram.ready()
      telegram.expand()
      telegram.enableClosingConfirmation()
      telegram.disableVerticalSwipes()
      telegram.setHeaderColor('#111118')
    }
  })
})
