<template>
  <div>
    <i18n-t
      keypath="notifications.body.bonusForDepositProgramUsersCreated"
      tag="p"
      class="text"
    >
      <template #multiplier>
        <b>
          {{ multiplier }}
        </b>
      </template>
    </i18n-t>
  </div>
</template>

<script setup lang="ts">
import Decimal from '@st/decimal'
import type { BonusForDepositNotification } from '../../../../types'

const props = defineProps<{
  notification: BonusForDepositNotification
}>()

const multiplier = computed(
  () =>
    `${new Decimal(props.notification.data.depositMultiplier)
      .times(100)
      .toString()}%`,
)
</script>

<style scoped>
.text {
  margin: 0;

  b {
    color: var(--text-primary);
  }
}
</style>
