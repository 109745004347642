<template>
  <div
    class="user-verification-modal"
    :class="{ mobile: isMobile }"
    data-t="user-verification-modal"
  >
    <div v-if="isMobile" class="close-wrapper">
      <div class="close-icon">
        <StIcon name="cross-large" size="20" @click="handleClose" />
      </div>
    </div>
    <h2 class="user-verification-title">
      {{ t('userVerificationModal.title') }}
    </h2>
    <p class="user-verification-description">
      {{ t('userVerificationModal.description') }}
    </p>
    <Transition name="fade" mode="out-in">
      <UserVerificationForm
        v-if="isReady"
        :initial-values="initialData"
        @success="handleSuccess"
      />
      <div v-else class="loader">
        <StSpinner size="32" />
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import UserVerificationForm from '../UserVerificationForm/UserVerificationForm.vue'

const { isMobile } = usePlatform()
const { countryCode, isReady } = useGeoAvailability()

const { t } = useI18n()

const router = useRouter()
const route = useRoute()

function handleClose() {
  router.replace({ query: { ...route.query, modal: undefined } })
}

const { parseDate } = useDate()
const initialData = computed(() => ({
  country: countryCode.value,
  birthDate: parseDate()
    .subtract(18, 'years')
    .subtract(1, 'days')
    .format('YYYY-MM-DD'),
}))

function handleSuccess() {
  router.replace({
    query: { ...route.query, modal: 'payments', tab: 'deposit' },
  })
}
</script>

<style scoped>
.user-verification-modal {
  user-select: none;

  position: relative;

  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  width: 480px;
  max-height: calc(100dvh - var(--spacing-400));
  padding: var(--spacing-300);

  border-radius: var(--border-radius-200);
  box-shadow: 0 0 80px -8px #1d1d2a;
}

.user-verification-title {
  margin: 0;
  margin-bottom: var(--spacing-100);
  font: var(--desktop-text-xl-semibold);
}

.user-verification-description {
  margin: 0;
  margin-bottom: var(--spacing-300);
  font: var(--desktop-text-sm-medium);
  color: var(--text-tertiary);
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 456px;
}

.fade-enter-active,
.fade-leave-active {
  transition:
    opacity 0.3s ease-in-out,
    transform 0.3s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-from {
  transform: translateY(15px);
}

.close-wrapper {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;

  display: flex;
  justify-content: flex-end;

  width: 100%;
  padding: var(--spacing-050);

  .close-icon {
    padding: var(--spacing-125);
  }
}

.mobile {
  &.user-verification-modal {
    flex-grow: 1;
    width: 100%;
    padding: var(--spacing-150) var(--spacing-200) var(--spacing-200);
    box-shadow: none;
  }

  .loader {
    width: auto;
    height: 376px;
  }

  .user-verification-title {
    margin-bottom: var(--spacing-050);
    font: var(--mobile-title-2-semibold);
  }

  .user-verification-description {
    margin-bottom: 22px; /* stylelint-disable-line */
  }
}
</style>
