<template>
  <button
    class="close-button"
    data-t="close-button-c1ks"
    type="button"
    @click="close"
  >
    <StIcon name="cross-large" :size="20" />
  </button>
</template>

<script setup lang="ts">
const { disableRouterBack = false } = defineProps<{
  disableRouterBack?: boolean
}>()

const emit = defineEmits<{
  close: []
}>()

const router = useRouter()
function close() {
  emit('close')

  if (!disableRouterBack) {
    router.replace({ query: {} })
  }
}
</script>

<style scoped>
.close-button {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: var(--spacing-125);

  color: var(--text-primary);

  background-color: transparent;
  border: none;
  outline: none;
}
</style>
