import { useUserStore } from '@st/user/stores/useUserStore'
import { useTagsStore } from '@st/user/stores/useTagsStore'
import { provideVerificationDeps } from '@st/verification/useDeps'
import { useCurrenciesStore } from '@st/payments/stores/useCurrenciesStore'
import type { IconName } from '@st/ui/components/StIcon/types'
import { provideQuestsDeps } from '@st/quests/useDeps'

export default defineNuxtPlugin({
  order: 1,
  name: 'diContainer',
  dependsOn: ['i18n:plugin', 'i18n-base-init'],
  setup: () => {
    const tagsStore = useTagsStore()
    const userStore = useUserStore()
    const { hasTag } = tagsStore
    const { isReady: isReadyTags } = storeToRefs(tagsStore)
    const {
      isAuthenticated,
      isReady: isReadyUser,
      user,
    } = storeToRefs(userStore)

    const { getCurrencyByCode } = useCurrenciesStore()

    provideVerificationDeps({
      hasSkipKycLevelCheckOnDepositTag: computed(() =>
        hasTag('skipKycLevelCheckOnDeposit'),
      ),
      isAuthenticated,
      isReadyUser,
      isReadyTags,
      userKycLevel: computed(() => user.value?.kycLevel ?? null),
    })

    const router = useRouter()
    const route = useRoute()

    const { handleTelegramAuth } = useTelegramAuthWidget()

    const { locale } = useNuxtApp().$i18n

    provideQuestsDeps({
      connectTelegram: async () => {
        await handleTelegramAuth(
          { language: locale.value },
          '/user/auth/telegram',
        )
      },
      openLogin: () => {
        router.push({ query: { ...route.query, modal: 'login' } })
      },
      isAuthenticated,
      getCurrencyIcon: (code: string) =>
        getCurrencyByCode(code)?.icon as IconName,
    })
  },
})
