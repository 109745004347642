<template>
  <section ref="sportContainer" class="sport-container" data-t="sportContainer">
    <header
      v-if="!props.isSingleSport"
      class="sport-header-title"
      data-t="title-sport"
    >
      <StIcon class="sport-header-icon" :name="sportIcon" :size="24" />
      <h3 class="sport-header-text" data-t="sport-header-title">
        {{ sportTree.sport.name }}
      </h3>
    </header>

    <MSportPageHeader
      v-else
      :header-color="headerColor"
      :class="{ scrolled: isScrolled && props.isSingleSport }"
      class="sport-page-header"
    >
      <StIcon :name="sportIcon" :size="24" />
      <h1 class="sport-page-title">
        {{ sportTree.sport.name }}
      </h1>
      <MMarketsFilterList v-model="singleSportFilter" class="filter" />
    </MSportPageHeader>
    <div class="sport-container-group" data-t="sportContainerGroup">
      <div
        v-for="(tournament, tournamentIndex) in renderPartTree"
        :key="`${tournament.tournament.id}-${tournamentIndex}`"
        class="sport-container-tournament"
        data-t="tournamentContainer"
      >
        <MTournamentHeader
          v-if="tournament.sportEvents.length"
          :tournament="tournament.tournament"
          :category="tournament.category"
          :counter="tournament.sportEvents.length"
          @toogle-tournament="
            handleTournamentHeaderClick(
              tournament.tournament.id,
              tournamentIndex,
            )
          "
        />
        <div
          v-show="isVisibleEventItem(tournament.tournament.id, tournamentIndex)"
          class="sport-container-items"
        >
          <EventItemCompact
            v-for="event in tournament.sportEvents"
            :key="event.id"
            class="event-compact"
            :event="event"
            :filter-config="filterConfig"
            is-mobile
            :hide-markets="props.hideMarkets"
          />
        </div>
      </div>
    </div>
    <div ref="endOfTheList" />
  </section>
</template>

<script setup lang="ts">
import { useInfiniteScroll } from '@st/use/composables'
import { useSportList } from '../../composables'
import EventItemCompact from '../EventItemCompact/EventItemCompact.vue'
import type { RenderFullTree, MobileMarketsFilterType } from '../../types'
import MTournamentHeader from '../TournamentHeader/MTournamentHeader.vue'
import { useSportListItem } from './useSportListItem'
import MSportPageHeader from '../SportPageHeader/MSportPageHeader.vue'
import MMarketsFilterList from '../MarketsFilter/MMarketsFilterList.vue'

interface Props {
  sportTree: RenderFullTree
  perPage?: number
  isSingleSport?: boolean
  activeFilter?: MobileMarketsFilterType
  hideMarkets?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  perPage: 5,
  isSingleSport: false,
  activeFilter: undefined,
  hideMarkets: false,
})

const { sportTree } = toRefs(props)
const singleSportFilter = ref<MobileMarketsFilterType>(
  props.activeFilter ?? 'exodus',
)
const filterIndexMap = {
  exodus: 0,
  handicap: 1,
  total: 2,
}
const filterConfig = computed(() => {
  const filterValueIndex =
    filterIndexMap[props.activeFilter ?? singleSportFilter.value]

  return sportTree.value.marketsConfig?.[filterValueIndex] ?? []
})

const {
  routeCategory,
  routeTournament,
  handleTournamentHeaderClick,
  isVisibleEventItem,
  sportIcon,
} = useSportListItem(sportTree)

const endOfTheList = ref<HTMLDivElement>()
const sportContainer = ref<HTMLDivElement>()

const { canLoadMore, loadMore, renderPartTree } = useSportList({
  perPage: props.perPage,
  disabled: false,
  renderFullTree: sportTree,
  routeCategory,
  routeTournament,
})

useInfiniteScroll({
  el: endOfTheList,
  loadMore: () => (canLoadMore.value ? loadMore() : undefined),
  disabledObserver: !canLoadMore.value,
  options: { rootMargin: '200px', threshold: 0 },
})

const { headerColor } = useMobileLayout()

const scrollRef = ref<Document | null>(null)
const { y } = useScroll(scrollRef)
const isScrolled = computed(() => y.value > 0)
onMounted(() => {
  scrollRef.value = document
})
</script>

<style>
:root {
  --sport-header-title-top: 164px; /* stylelint-disable-line */
  --sport-wrapper-padding-horizontal: var(--spacing-200);
}
</style>
<style scoped>
.sport-container {
  position: relative;
  z-index: 5;

  margin-bottom: var(--spacing-200);

  background: var(--background-base);
  border-radius: var(--border-radius-150);
}

.sport-container-group {
  position: relative;
  padding: 0 var(--sport-wrapper-padding-horizontal); /* stylelint-disable-line */
  background: var(--sport-list-background, transparent);

  .event-compact {
    border-bottom: var(--border-width-light) solid var(--border-primary);
    border-radius: 0;

    &:last-child {
      border: none;
      border-radius: 0 0 var(--border-radius-150) var(--border-radius-150);
    }
  }
}

.sport-header-title {
  position: sticky;
  z-index: 5;
  top: var(--sport-header-title-top); /* stylelint-disable-line */

  display: flex;
  gap: var(--spacing-075);
  align-items: center;

  padding: var(--spacing-100) var(--sport-wrapper-padding-horizontal); /* stylelint-disable-line */

  background: var(--sport-list-background, var(--background-base));
}

.sport-header-text {
  margin: 0;
  font: var(--mobile-text-medium);
  color: var(--text-primary);
}

.sport-container-tournament {
  margin-bottom: var(--spacing-075);
}

.sport-page-header {
  --event-page-header-top: 56px;

  border-bottom: var(--border-width-light, 0.5px) solid transparent;

  &::after {
    pointer-events: none;
    content: '';

    position: absolute;
    z-index: 1;
    right: 0;
    bottom: -32.5px;
    left: 0;

    height: 32px;

    opacity: 0;
    background: linear-gradient(#111118 0%, rgb(17 17 24 / 0%) 100%);

    transition: opacity 0.2s;
  }

  &.scrolled {
    border-bottom: var(--border-width-light, 0.5px) solid
      var(--border-primary, rgb(255 255 255 / 12%));

    &::after {
      opacity: 1;
    }
  }
}

.sport-page-title {
  margin: 0;
  font: var(--mobile-title-2-semibold);
  color: var(--text-primary);
}

.filter {
  margin-left: auto;
}
</style>
