import { useCurrenciesStore } from '@st/payments/stores/useCurrenciesStore'
import { delay } from '@st/utils'
import Decimal from '@st/decimal'
import claimAnimation from '../../assets/claim-animation.json'
import { useRegularBonusesStore } from '../../stores/useRegularBonusesStore'
import type { RegularBonus } from '../../types'
import { useGetTitleByLevel } from '../../composables/useGetTitleByLevel'

export function useRegularBonus(bonus: Ref<RegularBonus | undefined>) {
  const { t } = useI18n()
  const router = useRouter()
  const { currencies } = storeToRefs(useCurrenciesStore())
  const { format } = useCryptoFormatter()
  const { refreshBonuses } = useRegularBonusesStore()
  const { format: formatMultiplier } = useNumberFormatter()

  const { isExpired } = useCountdownV2(
    computed(() => bonus.value?.expiredAt ?? ''),
  )

  const bonusAmount = computed(() => format(bonus.value?.amount || '0'))
  const isZeroAmount = computed(
    () => bonus.value?.amount === '0' || !bonus.value?.amount,
  )
  const isWaitingForAccrual = computed(
    () => bonus.value?.status === 'waitingForAccrual',
  )

  const bonusTimer = computed(() => {
    if (bonus.value?.status === 'readyToClaim') {
      return {
        expiresAt: bonus.value.expiredAt,
        title: t('bonuses.timerTitle'),
      }
    }

    if (isWaitingForAccrual.value && bonus.value?.type === 'reload') {
      return {
        expiresAt: bonus.value.accrualAt,
        title: t('bonuses.timerAccrualTitle'),
      }
    }

    if (
      isWaitingForAccrual.value &&
      !isZeroAmount.value &&
      bonus.value?.type === 'cashback'
    ) {
      return {
        expiresAt: bonus.value.accrualAt,
        title: t('bonuses.timerAccrualTitle'),
      }
    }

    return null
  })
  const currencyIcon = computed(() => {
    const defaultIcon = 'c-crypto-icon-usdt' as const
    if (!bonus.value?.currencyId) return defaultIcon
    return currencies.value[bonus.value.currencyId]?.icon ?? defaultIcon
  })
  const currencyCode = computed(() => {
    const defaultCode = 'USDT'
    if (!bonus.value?.currencyId) return defaultCode
    return currencies.value[bonus.value.currencyId]?.code ?? defaultCode
  })
  const percentAmount = computed(() => {
    if (bonus.value?.percent) {
      return `${formatMultiplier(
        new Decimal(bonus.value.percent ?? 0).mul(100).toString(),
      )}%`
    }

    return null
  })

  const formattedMinBetRate = computed(() =>
    bonus.value?.betSettings?.minRate
      ? formatMultiplier(bonus.value.betSettings.minRate ?? 0)
      : '',
  )
  const formattedMaxBetRate = computed(() =>
    bonus.value?.betSettings?.maxRate
      ? formatMultiplier(bonus.value.betSettings.maxRate ?? 0)
      : '',
  )

  const { format: formatWithCurrency } = useCryptoFormatter({
    currency: currencyCode,
  })

  const bonusAmountWithCurrency = computed(() =>
    formatWithCurrency(bonus.value?.amount ?? 0),
  )

  const progress = computed(() => {
    if (
      !isWaitingForAccrual.value ||
      !bonus.value?.progressTotal ||
      !isZeroAmount.value
    )
      return ''

    const result = new Decimal(bonus.value.progressCurrent ?? 0)
      .div(bonus.value.progressTotal || 1)
      .mul(100)
      .toFixed(2)

    return Decimal.min(result, 100).toString()
  })
  const minMaxProgressText = computed(
    () =>
      `${format(bonus.value?.progressCurrent ?? 0)}/${format(
        bonus.value?.progressTotal ?? 0,
      )}`,
  )
  const totalProgressWithCurrency = computed(() => {
    if (!bonus.value?.progressTotal) return ''

    return formatWithCurrency(bonus.value?.progressTotal ?? 0)
  })

  const { getTitle } = useGetTitleByLevel()
  const cashbackUrl = usePublicFileUrl('publicFileCashbackTerms')
  const rakeBackUrl = usePublicFileUrl('publicFileRakeBackTerms')
  const reloadBonusUrl = usePublicFileUrl('publicFileReloadBonusTerms')
  const ratRaceUrl = usePublicFileUrl('publicFileRatRaceTerms')
  const content = computed(() => {
    switch (bonus.value?.type) {
      case 'ratRace':
        return {
          title: t('bonuses.tournament'),
          subtitle: '',
          link: ratRaceUrl.value,
          terms: [],
        }
      case 'cashback':
        return {
          title: t('bonuses.weeklyCashback'),
          subtitle: t('bonuses.cashbackModalSubTitle'),
          link: cashbackUrl.value,
          terms: [],
        }
      case 'rakeBack':
        return {
          title: t('bonuses.rakeback'),
          subtitle: t('bonuses.rakebackModalSubTitle'),
          link: rakeBackUrl.value,
          terms: [
            {
              label: t('bonuses.rakeBackFrequency'),
              value: t('bonuses.rakeBackFrequencyValue'),
            },
          ],
        }
      case 'reload':
        return {
          title: getTitle(t('bonuses.reload'), bonus.value.level),
          subtitle: t('bonuses.reloadModalSubTitle'),
          link: reloadBonusUrl.value,
          terms: [],
        }
      default:
        return {
          title: '',
          subtitle: '',
          link: '',
          terms: [],
        }
    }
  })

  const {
    execute,
    status: claimStatus,
    error,
  } = useStFetch('/bonus/claim', {
    method: 'post',
    body: computed(() => ({
      bonusId: bonus.value?.bonusId ?? 0,
    })),
    immediate: false,
    watch: false,
  })

  async function handleBonusClaim() {
    try {
      await execute()
      // Wait for the animation to finish
      await delay(300)
      if (!error.value) {
        refreshBonuses()
      }
    } catch (err) {
      console.error(err)
    }
  }
  async function handleBonusButtonClick() {
    if (bonus.value?.status === 'readyToClaim') {
      await handleBonusClaim()
    } else {
      router.push('/casino')
    }
  }

  const isButtonDisabled = computed(() => {
    if (isWaitingForAccrual.value && bonus.value?.type === 'reload') return true
    if (
      isWaitingForAccrual.value &&
      !isZeroAmount.value &&
      bonus.value?.type === 'cashback'
    )
      return true

    return false
  })

  const toast = useToast()

  watch(
    () => error.value,
    (newError) => {
      if (!newError) return
      toast.open({
        label: t('bonuses.error'),
        type: 'negative',
      })
    },
  )

  return {
    handleBonusButtonClick,
    claimStatus,
    bonusAmount,
    bonusAmountWithCurrency,
    isZeroAmount,
    currencyIcon,
    currencyCode,
    claimAnimation,
    isExpired,
    bonusTimer,
    content,
    percentAmount,
    formattedMinBetRate,
    formattedMaxBetRate,
    progress,
    minMaxProgressText,
    isButtonDisabled,
    totalProgressWithCurrency,
  }
}
