<template>
  <section class="set-email" :class="platform" data-t="set-email-form">
    <EmailStep v-if="isCurrent('email')" @success="handleEmailStepSuccess" />
    <ConfirmEmailStep
      v-else-if="isCurrent('confirm') && emailStepData"
      :email-step-data="emailStepData"
      @success="handleFinish"
      @back="goTo('email')"
    />
  </section>
</template>

<script setup lang="ts">
import { useUserStore } from '@st/user/stores/useUserStore'
import EmailStep from './parts/EmailStep.vue'
import ConfirmEmailStep from './parts/ConfirmEmailStep.vue'
import type { EmailStepData } from './types'

const { t } = useI18n()
const { platform } = usePlatform()
const { isCurrent, goTo } = useStepper(['email', 'confirm'])

const emailStepData = ref<EmailStepData>()
function handleEmailStepSuccess(data: EmailStepData) {
  emailStepData.value = data
  goTo('confirm')
}

const router = useRouter()
function close() {
  router.replace({ query: {} })
}

const toast = useToast()
const { refreshUserData, getAuthProvidersList } = useUserStore()
async function handleFinish() {
  await refreshUserData()
  await getAuthProvidersList()
  close()
  toast.open({
    label: t('profile.emailSuccess'),
    type: 'positive',
  })
}
</script>

<style scoped>
.set-email {
  display: flex;
  flex-direction: column;
  width: 480px;
  min-height: 364px;

  &.mobile {
    width: 100%;
    min-height: 304px;
    padding-top: var(--spacing-250);
  }
}
</style>
