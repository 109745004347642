<template>
  <div
    v-if="fetchProvider?.data"
    class="provider-slider-wrapper"
    data-t="providers-list-86py"
  >
    <div class="title-wrapper">
      <NuxtI18nLink
        v-if="withHeaderLink"
        to="/casino/providers"
        class="title-group"
      >
        <StIcon name="providers" class="icon" />
        <h3 class="title">{{ t('categories.providers') }}</h3>
        <StIcon name="chevron-right" size="18" />
      </NuxtI18nLink>
      <h3 v-else class="title">{{ t('categories.providers') }}</h3>
      <StButton
        size="s"
        to="/casino/providers"
        type="gray"
        data-t="link-providers-rb4b"
      >
        {{ t('gameCard.watchAll') }}
        <span class="providers-count">{{ fetchProvider.paging.total }}</span>
      </StButton>
    </div>
    <StSlider
      :scroll-step="208"
      class="providers-list"
      scrollable
      :arrow-offset-vertical="0"
      :items-gap="12"
    >
      <StSliderItem v-for="provider in fetchProvider.data" :key="provider.id">
        <ProviderCard :provider="provider" class="providers-list-card" />
      </StSliderItem>
    </StSlider>
  </div>
</template>

<script lang="ts" setup>
withDefaults(
  defineProps<{
    withHeaderLink?: boolean
  }>(),
  {
    withHeaderLink: true,
  },
)

const { t, locale } = useI18n()
const { data: fetchProvider } = useStFetch('/casino/provider/find', {
  method: 'post',
  body: {
    params: {
      forDesktop: true,
      language: locale.value as any,
    },
    pagination: {
      page: 0,
      perPage: 25,
      orderBy: [
        {
          fieldName: 'weight',
          sortOrder: 'DESC',
        },
      ],
    },
  },
})
</script>

<style scoped>
.title-wrapper {
  display: flex;
  gap: var(--spacing-150);
  align-items: center;
  align-self: stretch;
  justify-content: space-between;

  margin-bottom: var(--spacing-200);
}

.title-group {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;

  color: var(--text-primary);
  text-decoration: none;

  &:hover {
    color: var(--text-link);
  }

  .icon {
    margin-right: var(--spacing-050);
    color: var(--icon-tertiary);
  }
}

.title {
  margin: 0;
  font: var(--desktop-text-lg-semibold);
}

.providers-list {
  gap: var(--spacing-150);
}

.provider-slider-wrapper {
  .providers-list-card {
    width: 208px;
  }
}

.providers-count {
  font: var(--desktop-text-xs-medium);
  color: var(--text-tertiary);
}
</style>
