<template>
  <i18n-t keypath="notifications.body.depositCreated" tag="p" class="text">
    <template #amount>
      <b>{{ notificationAmount }}</b>
    </template>
  </i18n-t>
</template>

<script setup lang="ts">
import type { DepositNotification } from '../../../../types'

const props = defineProps<{
  notification: DepositNotification
}>()
const { notification } = toRefs(props)
const { notificationAmount } = useCurrencyNotification(notification)
</script>

<style scoped>
.text {
  margin: 0;

  b {
    color: var(--text-primary);
  }
}
</style>
