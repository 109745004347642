<template>
  <header class="header" :class="{ back: withBackButton }">
    <button
      v-if="withBackButton"
      class="button"
      type="button"
      @click="emit('back')"
    >
      <StIcon name="chevron-left" :size="20" />
    </button>
    <slot></slot>
    <button
      v-if="withCloseButton"
      class="button close"
      type="button"
      @click="close"
    >
      <StIcon name="cross-large" :size="20" />
    </button>
  </header>
</template>

<script setup lang="ts">
import { omit } from '@st/utils'

const { withCloseButton = true } = defineProps<{
  withBackButton?: boolean
  withCloseButton?: boolean
}>()

const emit = defineEmits<{
  (e: 'back'): void
}>()

const router = useRouter()
const route = useRoute()
function close() {
  router.replace({ query: omit(route.query, ['modal']) })
}
</script>

<style scoped>
.header {
  display: flex;
  align-items: center;
  padding: var(--spacing-050) var(--spacing-050) var(--spacing-050)
    var(--spacing-200);

  &.back {
    padding-left: var(--spacing-050);
    border-bottom: var(--border-width-light) solid var(--border-primary);
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: var(--spacing-125);

  color: var(--text-primary);

  background-color: transparent;
  border: none;
  outline: none;

  &.close {
    margin-left: auto;
  }
}
</style>
