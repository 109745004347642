<template>
  <div class="region-select">
    <StSkeletonLoader v-if="isFiatCurrenciesLoading" />
    <StBaseSelect
      v-else
      v-model="selectedCountry"
      :has-drawer="platform === 'mobile'"
      :mobile-title="t('payments.deposit.selectCountry')"
      dropdown-max-height="200"
      top-offset="-8"
      full-screen
    >
      <template #field>
        <RegionSelect
          :full-name="selectedCountryItem?.fullName"
          :icon="selectedCountryItem?.icon"
          @toggle="toggleRegionSelect"
        />
      </template>
      <template #top>
        <div
          class="dropdown-search-wrapper"
          :class="platform"
          data-t="dropdown-search-wrapper"
        >
          <StInput
            v-model="searchValue"
            :placeholder="t('payments.deposit.enterTheCountryName')"
            icon-prefix="search"
            size="s"
            class="search-input"
            autofocus
          >
            <template v-if="searchValue" #postfix>
              <StIcon
                name="cross-large"
                size="16"
                class="close-icon"
                data-t="clear-icon"
                @click="clearSearch"
              />
            </template>
          </StInput>
        </div>
      </template>
      <div class="options">
        <div v-if="!filteredCountryList.length && searchValue" class="no-items">
          <StIcon name="search-none" size="48" class="search-icon" />
          <div class="disclaimer">
            <div class="title">
              {{ t('payments.deposit.noItemsSearchTitle') }}
            </div>
            <div class="subtitle">
              {{ t('payments.deposit.noItemsSearchSubtitle') }}
            </div>
          </div>
        </div>
        <template v-else>
          <PaymentsSelectOption
            v-for="item in filteredSlice"
            :key="item.id"
            :item="item"
            :platform="platform"
            :is-selected="selectedCountry === item.countryCode"
          />
          <div ref="endOfTheList"></div>
        </template>
      </div>
    </StBaseSelect>
  </div>
</template>

<script setup lang="ts">
import { currencyCodeByCountryCode } from '../../../helpers/currencyCodeByCountryCodeMap'
import { useExchangeDataStore } from '../../../stores/useExchangeDataStore'
import RegionSelect from './RegionSelectField.vue'

const selectedCountry = defineModel<string>()

const { t } = useI18n()
const { platform } = usePlatform()

const exchangeDataStore = useExchangeDataStore()

const { isFiatCurrenciesLoading } = storeToRefs(exchangeDataStore)

const searchValue = ref('')

function clearSearch() {
  searchValue.value = ''
}

const { getCountryByCode } = useCountries()

const countryList = Object.keys(currencyCodeByCountryCode).map((country) => {
  const countryItem = getCountryByCode(country)

  return {
    id: country.toLowerCase(),
    title: countryItem?.name ?? country,
    fullName: countryItem ? `${countryItem?.name} (${country})` : country,
    currency: currencyCodeByCountryCode[country],
    countryCode: country,
    icon: countryItem?.icon ?? 'planet',
  }
})

const selectedCountryItem = computed(() =>
  countryList.find(
    (country) => country.id === selectedCountry.value?.toLowerCase(),
  ),
)

const filteredCountryList = computed(() =>
  countryList.filter((currency) =>
    currency.title.toLowerCase().includes(searchValue.value.toLowerCase()),
  ),
)

let currentSlice = 0
const SLICE_LENGTH = 10
const filteredSlice = ref<any[]>([])
function loadMore() {
  const result = filteredCountryList.value.slice(
    currentSlice,
    currentSlice + SLICE_LENGTH,
  )
  currentSlice += SLICE_LENGTH

  filteredSlice.value.push(...result)
}

loadMore()

watch(searchValue, () => {
  filteredSlice.value = []
  currentSlice = 0
})

const canLoadMore = computed(
  () => currentSlice < filteredCountryList.value.length,
)

const endOfTheList = useTemplateRef<HTMLDivElement>('endOfTheList')
useInfiniteScroll({
  el: endOfTheList,
  loadMore: async () => {
    if (canLoadMore.value) {
      loadMore()
    }
  },
  disabledObserver: computed(() => !canLoadMore.value),
})

watch(selectedCountry, () => {
  filteredSlice.value = []
  currentSlice = 0
})

function toggleRegionSelect() {
  searchValue.value = ''
}
</script>

<style scoped>
.region-select {
  width: 100%;
  margin-bottom: var(--spacing-200);

  :deep(.base-dropdown) {
    background-color: var(--background-primary);
  }
}

.close-icon {
  cursor: pointer;
  color: var(--icon-tertiary);
}

.dropdown-search-wrapper {
  padding: var(--spacing-100) var(--spacing-150);
  background-color: var(--background-primary);
}

.no-items {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);
  align-items: center;

  height: 100%;

  /* stylelint-disable */
  padding: 49px 0;
  /* stylelint-enable */
  text-align: center;

  .search-icon {
    color: var(--text-tertiary);
  }

  .title {
    font: var(--desktop-text-sm-semibold);
  }

  .subtitle {
    font: var(--desktop-text-xs-medium);
    color: var(--text-tertiary);
  }
}

.dropdown-search-wrapper.mobile {
  background-color: transparent;
  border: none;
}
</style>
