export function createEntityDeps<D>(): [() => D, (depsToProvide: D) => void] {
  const key = Symbol('injectable dependencies')

  const useDeps = () => {
    const deps = inject<D>(key)
    if (!deps) {
      throw new Error(`Dependencies are used, but not provided!`)
    }
    return deps as D
  }

  const provideDeps = (depsToProvide: D) =>
    useNuxtApp().vueApp.provide(key, depsToProvide)

  return [useDeps, provideDeps]
}
