<template>
  <header
    class="header"
    :class="{
      base: !props.withBackButton,
      'full-width': fullWidth,
      'drag-line': withDragLinePadding,
      'border-bottom': withBorderBottom,
    }"
  >
    <button v-if="props.withBackButton" class="button" @click="emit('back')">
      <StIcon name="chevron-left" :size="20" />
    </button>
    <span v-if="title" class="title">{{ title }}</span>
    <slot v-else />
    <StDrawerClose
      class="close"
      :disable-router-back="disableRouterBack"
      @close="handleClose"
    />
  </header>
</template>

<script setup lang="ts">
import StDrawerClose from './StDrawerClose.vue'

const props = defineProps<{
  withBackButton?: boolean
  title?: string
  fullWidth?: boolean
  disableRouterBack?: boolean
  withDragLinePadding?: boolean
  withBorderBottom?: boolean
}>()

const emit = defineEmits<{
  back: []
  close: []
}>()

function handleClose() {
  emit('close')
}
</script>

<style scoped>
.header {
  display: flex;
  align-items: center;
  padding: var(--spacing-050);

  &.base {
    padding-left: var(--spacing-200);
  }

  &.full-width {
    padding-left: 0;
  }

  &.drag-line {
    padding-top: var(--spacing-250);
  }

  &.border-bottom {
    border-bottom: 0.5px solid var(--border-primary);
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: var(--spacing-125);

  color: var(--text-primary);

  background-color: transparent;
  border: none;
  outline: none;
}

.close {
  margin-left: auto;
}

.title {
  font: var(--mobile-title-2-semibold);
}
</style>
