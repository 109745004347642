import { default as indext1dZRm5SZjMeta } from "/app/apps/site/pages/about/index.vue?macro=true";
import { default as bonus_45infoKRZP9Rwg0YMeta } from "/app/apps/site/pages/bonus-info.vue?macro=true";
import { default as bonuses0IzCsU3UF1Meta } from "/app/apps/site/pages/bonuses.vue?macro=true";
import { default as _91subCategoryCode_93Um66j3PT1tMeta } from "/app/apps/site/pages/casino/categories/[categoryCode]/[subCategoryCode].vue?macro=true";
import { default as indexw3g2kgM3VyMeta } from "/app/apps/site/pages/casino/categories/[categoryCode]/index.vue?macro=true";
import { default as favorites9unxzaE337Meta } from "/app/apps/site/pages/casino/favorites.vue?macro=true";
import { default as freespinsXNpu88Iy7yMeta } from "/app/apps/site/pages/casino/freespins.vue?macro=true";
import { default as _91id_93xzcd6sIDxDMeta } from "/app/apps/site/pages/casino/games/[id].vue?macro=true";
import { default as indexKxBHLCu100Meta } from "/app/apps/site/pages/casino/index.vue?macro=true";
import { default as _91provider_93OkNdbO6p7NMeta } from "/app/apps/site/pages/casino/providers/[provider].vue?macro=true";
import { default as indexm0lft7aPIQMeta } from "/app/apps/site/pages/casino/providers/index.vue?macro=true";
import { default as recentsRQI3XxdmiMeta } from "/app/apps/site/pages/casino/recent.vue?macro=true";
import { default as restrictionVCCrKWrabjMeta } from "/app/apps/site/pages/casino/restriction.vue?macro=true";
import { default as index6cqa2vd68hMeta } from "/app/apps/site/pages/cybersport/[categoryId]/[tournamentId]/index.vue?macro=true";
import { default as _91eventId_93i59KLwB3hxMeta } from "/app/apps/site/pages/cybersport/[categoryId]/[tournamentId]/match/[eventId].vue?macro=true";
import { default as index9pgS9eWsu8Meta } from "/app/apps/site/pages/cybersport/[categoryId]/index.vue?macro=true";
import { default as allOPxgJZtHQiMeta } from "/app/apps/site/pages/cybersport/all.vue?macro=true";
import { default as indexctsZvWhKmpMeta } from "/app/apps/site/pages/cybersport/index.vue?macro=true";
import { default as liveb1kkG9gATxMeta } from "/app/apps/site/pages/cybersport/live.vue?macro=true";
import { default as upcomingeAIdzvuhm8Meta } from "/app/apps/site/pages/cybersport/upcoming.vue?macro=true";
import { default as indexAGWIYPDswAMeta } from "/app/apps/site/pages/index.vue?macro=true";
import { default as bonus_45infoEWBNiQdUJrMeta } from "/app/apps/site/pages/mobile/bonus-info.vue?macro=true";
import { default as bonusesD2RCjujVtjMeta } from "/app/apps/site/pages/mobile/bonuses.vue?macro=true";
import { default as _91subCategoryCode_93YLVkHNGCfwMeta } from "/app/apps/site/pages/mobile/casino/categories/[categoryCode]/[subCategoryCode].vue?macro=true";
import { default as indexUcNfpQ4RR5Meta } from "/app/apps/site/pages/mobile/casino/categories/[categoryCode]/index.vue?macro=true";
import { default as favoritesousD4GQHn7Meta } from "/app/apps/site/pages/mobile/casino/favorites.vue?macro=true";
import { default as freespinsSkQBiU72bIMeta } from "/app/apps/site/pages/mobile/casino/freespins.vue?macro=true";
import { default as _91id_93dCaJNoT8pCMeta } from "/app/apps/site/pages/mobile/casino/games/[id].vue?macro=true";
import { default as indexrTi5HvIA0YMeta } from "/app/apps/site/pages/mobile/casino/index.vue?macro=true";
import { default as _91provider_93mSnP4jjMB4Meta } from "/app/apps/site/pages/mobile/casino/providers/[provider].vue?macro=true";
import { default as indexoWW5sKcFaBMeta } from "/app/apps/site/pages/mobile/casino/providers/index.vue?macro=true";
import { default as recent70MF2fjFrMMeta } from "/app/apps/site/pages/mobile/casino/recent.vue?macro=true";
import { default as restrictionKDZxmvpctbMeta } from "/app/apps/site/pages/mobile/casino/restriction.vue?macro=true";
import { default as indexgWME7u8Q1IMeta } from "/app/apps/site/pages/mobile/cybersport/[categoryId]/[tournamentId]/index.vue?macro=true";
import { default as _91eventId_93DVt1Ud75wCMeta } from "/app/apps/site/pages/mobile/cybersport/[categoryId]/[tournamentId]/match/[eventId].vue?macro=true";
import { default as indexdH9LuxHdaqMeta } from "/app/apps/site/pages/mobile/cybersport/[categoryId]/index.vue?macro=true";
import { default as indexVUsbMRqukFMeta } from "/app/apps/site/pages/mobile/cybersport/index.vue?macro=true";
import { default as liverPDIk4ZKRrMeta } from "/app/apps/site/pages/mobile/cybersport/live.vue?macro=true";
import { default as upcomingl8twCvk0S4Meta } from "/app/apps/site/pages/mobile/cybersport/upcoming.vue?macro=true";
import { default as indexQetr5klI9HMeta } from "/app/apps/site/pages/mobile/index.vue?macro=true";
import { default as betsMTi5WP7IbTMeta } from "/app/apps/site/pages/mobile/personal/bets.vue?macro=true";
import { default as kychGo1dZz1ttMeta } from "/app/apps/site/pages/mobile/personal/kyc.vue?macro=true";
import { default as profile3vJcr8KiKGMeta } from "/app/apps/site/pages/mobile/personal/profile.vue?macro=true";
import { default as settingsYRoe6PG0BfMeta } from "/app/apps/site/pages/mobile/personal/settings.vue?macro=true";
import { default as transactionsAMF9N0beyOMeta } from "/app/apps/site/pages/mobile/personal/transactions.vue?macro=true";
import { default as walletXz5gW2EWqtMeta } from "/app/apps/site/pages/mobile/personal/wallet.vue?macro=true";
import { default as registrationrbzrfXjjhQMeta } from "/app/apps/site/pages/mobile/promo/registration.vue?macro=true";
import { default as pwahw5TdcnbyIMeta } from "/app/apps/site/pages/mobile/pwa.vue?macro=true";
import { default as questsuonvIO0qVgMeta } from "/app/apps/site/pages/mobile/quests.vue?macro=true";
import { default as refer_45and_45earnVwMEkgnOtnMeta } from "/app/apps/site/pages/mobile/refer-and-earn.vue?macro=true";
import { default as refer_45info19hTPRpea6Meta } from "/app/apps/site/pages/mobile/refer-info.vue?macro=true";
import { default as indexbhxh01Kd9wMeta } from "/app/apps/site/pages/mobile/sport/[sportId]/[categoryId]/[tournamentId]/index.vue?macro=true";
import { default as _91eventId_93hQrAdnwKrmMeta } from "/app/apps/site/pages/mobile/sport/[sportId]/[categoryId]/[tournamentId]/match/[eventId].vue?macro=true";
import { default as indexTYNAyURMIHMeta } from "/app/apps/site/pages/mobile/sport/[sportId]/[categoryId]/index.vue?macro=true";
import { default as indexpDhmRLHTMMMeta } from "/app/apps/site/pages/mobile/sport/[sportId]/index.vue?macro=true";
import { default as all5lh487dru4Meta } from "/app/apps/site/pages/mobile/sport/all.vue?macro=true";
import { default as indexqEDppdwYXwMeta } from "/app/apps/site/pages/mobile/sport/index.vue?macro=true";
import { default as liveGDPetqsNd2Meta } from "/app/apps/site/pages/mobile/sport/live.vue?macro=true";
import { default as upcomingjgGita4WfNMeta } from "/app/apps/site/pages/mobile/sport/upcoming.vue?macro=true";
import { default as supportamedNFbChdMeta } from "/app/apps/site/pages/mobile/support.vue?macro=true";
import { default as _91id_93uSojnDCrRoMeta } from "/app/apps/site/pages/mobile/tournaments/[id].vue?macro=true";
import { default as index2Rf6gY2o1DMeta } from "/app/apps/site/pages/mobile/tournaments/index.vue?macro=true";
import { default as unsubscribesnfj6WpwZ6Meta } from "/app/apps/site/pages/mobile/unsubscribe.vue?macro=true";
import { default as bets55PXI7Bq75Meta } from "/app/apps/site/pages/personal/bets.vue?macro=true";
import { default as kyc2QSbsoWkUkMeta } from "/app/apps/site/pages/personal/kyc.vue?macro=true";
import { default as profileGMGu9bdYmBMeta } from "/app/apps/site/pages/personal/profile.vue?macro=true";
import { default as transactionsxL2rxTO656Meta } from "/app/apps/site/pages/personal/transactions.vue?macro=true";
import { default as walletZ0QEOx9alnMeta } from "/app/apps/site/pages/personal/wallet.vue?macro=true";
import { default as registrationiZrL1sJ8iWMeta } from "/app/apps/site/pages/promo/registration.vue?macro=true";
import { default as pwaPFm19EQAPwMeta } from "/app/apps/site/pages/pwa.vue?macro=true";
import { default as questsPNPxYU0HnTMeta } from "/app/apps/site/pages/quests.vue?macro=true";
import { default as refer_45and_45earnSrBsLbldv3Meta } from "/app/apps/site/pages/refer-and-earn.vue?macro=true";
import { default as refer_45infoSQhOpZOJvvMeta } from "/app/apps/site/pages/refer-info.vue?macro=true";
import { default as indexKKlwbRssCTMeta } from "/app/apps/site/pages/sport/[sportId]/[categoryId]/[tournamentId]/index.vue?macro=true";
import { default as _91eventId_93UYUQdP4EI8Meta } from "/app/apps/site/pages/sport/[sportId]/[categoryId]/[tournamentId]/match/[eventId].vue?macro=true";
import { default as indexZA8AreH4nrMeta } from "/app/apps/site/pages/sport/[sportId]/[categoryId]/index.vue?macro=true";
import { default as indexXqtKpNJIITMeta } from "/app/apps/site/pages/sport/[sportId]/index.vue?macro=true";
import { default as allvuzAUqv2gaMeta } from "/app/apps/site/pages/sport/all.vue?macro=true";
import { default as indexYaADUjozNSMeta } from "/app/apps/site/pages/sport/index.vue?macro=true";
import { default as liverNxADqpTpFMeta } from "/app/apps/site/pages/sport/live.vue?macro=true";
import { default as upcoming55fiukpALUMeta } from "/app/apps/site/pages/sport/upcoming.vue?macro=true";
import { default as supportRafuRYvRSeMeta } from "/app/apps/site/pages/support.vue?macro=true";
import { default as _91id_93tWYqDm8JarMeta } from "/app/apps/site/pages/tournaments/[id].vue?macro=true";
import { default as indexVwhhIVl36QMeta } from "/app/apps/site/pages/tournaments/index.vue?macro=true";
import { default as unsubscribe67u1rkBt9DMeta } from "/app/apps/site/pages/unsubscribe.vue?macro=true";
export default [
  {
    name: "about___en",
    path: "/about",
    component: () => import("/app/apps/site/pages/about/index.vue")
  },
  {
    name: "about___ru",
    path: "/ru/about",
    component: () => import("/app/apps/site/pages/about/index.vue")
  },
  {
    name: "about___es",
    path: "/es/about",
    component: () => import("/app/apps/site/pages/about/index.vue")
  },
  {
    name: "about___pt",
    path: "/pt/about",
    component: () => import("/app/apps/site/pages/about/index.vue")
  },
  {
    name: "bonus-info___en",
    path: "/bonus-info",
    component: () => import("/app/apps/site/pages/bonus-info.vue")
  },
  {
    name: "bonus-info___ru",
    path: "/ru/bonus-info",
    component: () => import("/app/apps/site/pages/bonus-info.vue")
  },
  {
    name: "bonus-info___es",
    path: "/es/bonus-info",
    component: () => import("/app/apps/site/pages/bonus-info.vue")
  },
  {
    name: "bonus-info___pt",
    path: "/pt/bonus-info",
    component: () => import("/app/apps/site/pages/bonus-info.vue")
  },
  {
    name: "bonuses___en",
    path: "/bonuses",
    component: () => import("/app/apps/site/pages/bonuses.vue")
  },
  {
    name: "bonuses___ru",
    path: "/ru/bonuses",
    component: () => import("/app/apps/site/pages/bonuses.vue")
  },
  {
    name: "bonuses___es",
    path: "/es/bonuses",
    component: () => import("/app/apps/site/pages/bonuses.vue")
  },
  {
    name: "bonuses___pt",
    path: "/pt/bonuses",
    component: () => import("/app/apps/site/pages/bonuses.vue")
  },
  {
    name: "casino-categories-categoryCode-subCategoryCode___en",
    path: "/casino/categories/:categoryCode()/:subCategoryCode()",
    meta: _91subCategoryCode_93Um66j3PT1tMeta || {},
    component: () => import("/app/apps/site/pages/casino/categories/[categoryCode]/[subCategoryCode].vue")
  },
  {
    name: "casino-categories-categoryCode-subCategoryCode___ru",
    path: "/ru/casino/categories/:categoryCode()/:subCategoryCode()",
    meta: _91subCategoryCode_93Um66j3PT1tMeta || {},
    component: () => import("/app/apps/site/pages/casino/categories/[categoryCode]/[subCategoryCode].vue")
  },
  {
    name: "casino-categories-categoryCode-subCategoryCode___es",
    path: "/es/casino/categories/:categoryCode()/:subCategoryCode()",
    meta: _91subCategoryCode_93Um66j3PT1tMeta || {},
    component: () => import("/app/apps/site/pages/casino/categories/[categoryCode]/[subCategoryCode].vue")
  },
  {
    name: "casino-categories-categoryCode-subCategoryCode___pt",
    path: "/pt/casino/categories/:categoryCode()/:subCategoryCode()",
    meta: _91subCategoryCode_93Um66j3PT1tMeta || {},
    component: () => import("/app/apps/site/pages/casino/categories/[categoryCode]/[subCategoryCode].vue")
  },
  {
    name: "casino-categories-categoryCode___en",
    path: "/casino/categories/:categoryCode()",
    meta: indexw3g2kgM3VyMeta || {},
    component: () => import("/app/apps/site/pages/casino/categories/[categoryCode]/index.vue")
  },
  {
    name: "casino-categories-categoryCode___ru",
    path: "/ru/casino/categories/:categoryCode()",
    meta: indexw3g2kgM3VyMeta || {},
    component: () => import("/app/apps/site/pages/casino/categories/[categoryCode]/index.vue")
  },
  {
    name: "casino-categories-categoryCode___es",
    path: "/es/casino/categories/:categoryCode()",
    meta: indexw3g2kgM3VyMeta || {},
    component: () => import("/app/apps/site/pages/casino/categories/[categoryCode]/index.vue")
  },
  {
    name: "casino-categories-categoryCode___pt",
    path: "/pt/casino/categories/:categoryCode()",
    meta: indexw3g2kgM3VyMeta || {},
    component: () => import("/app/apps/site/pages/casino/categories/[categoryCode]/index.vue")
  },
  {
    name: "casino-favorites___en",
    path: "/casino/favorites",
    meta: favorites9unxzaE337Meta || {},
    component: () => import("/app/apps/site/pages/casino/favorites.vue")
  },
  {
    name: "casino-favorites___ru",
    path: "/ru/casino/favorites",
    meta: favorites9unxzaE337Meta || {},
    component: () => import("/app/apps/site/pages/casino/favorites.vue")
  },
  {
    name: "casino-favorites___es",
    path: "/es/casino/favorites",
    meta: favorites9unxzaE337Meta || {},
    component: () => import("/app/apps/site/pages/casino/favorites.vue")
  },
  {
    name: "casino-favorites___pt",
    path: "/pt/casino/favorites",
    meta: favorites9unxzaE337Meta || {},
    component: () => import("/app/apps/site/pages/casino/favorites.vue")
  },
  {
    name: "casino-freespins___en",
    path: "/casino/freespins",
    meta: freespinsXNpu88Iy7yMeta || {},
    component: () => import("/app/apps/site/pages/casino/freespins.vue")
  },
  {
    name: "casino-freespins___ru",
    path: "/ru/casino/freespins",
    meta: freespinsXNpu88Iy7yMeta || {},
    component: () => import("/app/apps/site/pages/casino/freespins.vue")
  },
  {
    name: "casino-freespins___es",
    path: "/es/casino/freespins",
    meta: freespinsXNpu88Iy7yMeta || {},
    component: () => import("/app/apps/site/pages/casino/freespins.vue")
  },
  {
    name: "casino-freespins___pt",
    path: "/pt/casino/freespins",
    meta: freespinsXNpu88Iy7yMeta || {},
    component: () => import("/app/apps/site/pages/casino/freespins.vue")
  },
  {
    name: "casino-games-id___en",
    path: "/casino/games/:id()",
    meta: _91id_93xzcd6sIDxDMeta || {},
    component: () => import("/app/apps/site/pages/casino/games/[id].vue")
  },
  {
    name: "casino-games-id___ru",
    path: "/ru/casino/games/:id()",
    meta: _91id_93xzcd6sIDxDMeta || {},
    component: () => import("/app/apps/site/pages/casino/games/[id].vue")
  },
  {
    name: "casino-games-id___es",
    path: "/es/casino/games/:id()",
    meta: _91id_93xzcd6sIDxDMeta || {},
    component: () => import("/app/apps/site/pages/casino/games/[id].vue")
  },
  {
    name: "casino-games-id___pt",
    path: "/pt/casino/games/:id()",
    meta: _91id_93xzcd6sIDxDMeta || {},
    component: () => import("/app/apps/site/pages/casino/games/[id].vue")
  },
  {
    name: "casino___en",
    path: "/casino",
    meta: indexKxBHLCu100Meta || {},
    component: () => import("/app/apps/site/pages/casino/index.vue")
  },
  {
    name: "casino___ru",
    path: "/ru/casino",
    meta: indexKxBHLCu100Meta || {},
    component: () => import("/app/apps/site/pages/casino/index.vue")
  },
  {
    name: "casino___es",
    path: "/es/casino",
    meta: indexKxBHLCu100Meta || {},
    component: () => import("/app/apps/site/pages/casino/index.vue")
  },
  {
    name: "casino___pt",
    path: "/pt/casino",
    meta: indexKxBHLCu100Meta || {},
    component: () => import("/app/apps/site/pages/casino/index.vue")
  },
  {
    name: "casino-providers-provider___en",
    path: "/casino/providers/:provider()",
    component: () => import("/app/apps/site/pages/casino/providers/[provider].vue")
  },
  {
    name: "casino-providers-provider___ru",
    path: "/ru/casino/providers/:provider()",
    component: () => import("/app/apps/site/pages/casino/providers/[provider].vue")
  },
  {
    name: "casino-providers-provider___es",
    path: "/es/casino/providers/:provider()",
    component: () => import("/app/apps/site/pages/casino/providers/[provider].vue")
  },
  {
    name: "casino-providers-provider___pt",
    path: "/pt/casino/providers/:provider()",
    component: () => import("/app/apps/site/pages/casino/providers/[provider].vue")
  },
  {
    name: "casino-providers___en",
    path: "/casino/providers",
    component: () => import("/app/apps/site/pages/casino/providers/index.vue")
  },
  {
    name: "casino-providers___ru",
    path: "/ru/casino/providers",
    component: () => import("/app/apps/site/pages/casino/providers/index.vue")
  },
  {
    name: "casino-providers___es",
    path: "/es/casino/providers",
    component: () => import("/app/apps/site/pages/casino/providers/index.vue")
  },
  {
    name: "casino-providers___pt",
    path: "/pt/casino/providers",
    component: () => import("/app/apps/site/pages/casino/providers/index.vue")
  },
  {
    name: "casino-recent___en",
    path: "/casino/recent",
    meta: recentsRQI3XxdmiMeta || {},
    component: () => import("/app/apps/site/pages/casino/recent.vue")
  },
  {
    name: "casino-recent___ru",
    path: "/ru/casino/recent",
    meta: recentsRQI3XxdmiMeta || {},
    component: () => import("/app/apps/site/pages/casino/recent.vue")
  },
  {
    name: "casino-recent___es",
    path: "/es/casino/recent",
    meta: recentsRQI3XxdmiMeta || {},
    component: () => import("/app/apps/site/pages/casino/recent.vue")
  },
  {
    name: "casino-recent___pt",
    path: "/pt/casino/recent",
    meta: recentsRQI3XxdmiMeta || {},
    component: () => import("/app/apps/site/pages/casino/recent.vue")
  },
  {
    name: "casino-restriction___en",
    path: "/casino/restriction",
    meta: restrictionVCCrKWrabjMeta || {},
    component: () => import("/app/apps/site/pages/casino/restriction.vue")
  },
  {
    name: "casino-restriction___ru",
    path: "/ru/casino/restriction",
    meta: restrictionVCCrKWrabjMeta || {},
    component: () => import("/app/apps/site/pages/casino/restriction.vue")
  },
  {
    name: "casino-restriction___es",
    path: "/es/casino/restriction",
    meta: restrictionVCCrKWrabjMeta || {},
    component: () => import("/app/apps/site/pages/casino/restriction.vue")
  },
  {
    name: "casino-restriction___pt",
    path: "/pt/casino/restriction",
    meta: restrictionVCCrKWrabjMeta || {},
    component: () => import("/app/apps/site/pages/casino/restriction.vue")
  },
  {
    name: "cybersport-categoryId-tournamentId___en",
    path: "/cybersport/:categoryId()/:tournamentId()",
    component: () => import("/app/apps/site/pages/cybersport/[categoryId]/[tournamentId]/index.vue")
  },
  {
    name: "cybersport-categoryId-tournamentId___ru",
    path: "/ru/cybersport/:categoryId()/:tournamentId()",
    component: () => import("/app/apps/site/pages/cybersport/[categoryId]/[tournamentId]/index.vue")
  },
  {
    name: "cybersport-categoryId-tournamentId___es",
    path: "/es/cybersport/:categoryId()/:tournamentId()",
    component: () => import("/app/apps/site/pages/cybersport/[categoryId]/[tournamentId]/index.vue")
  },
  {
    name: "cybersport-categoryId-tournamentId___pt",
    path: "/pt/cybersport/:categoryId()/:tournamentId()",
    component: () => import("/app/apps/site/pages/cybersport/[categoryId]/[tournamentId]/index.vue")
  },
  {
    name: "cybersport-categoryId-tournamentId-match-eventId___en",
    path: "/cybersport/:categoryId()/:tournamentId()/match/:eventId()",
    meta: _91eventId_93i59KLwB3hxMeta || {},
    component: () => import("/app/apps/site/pages/cybersport/[categoryId]/[tournamentId]/match/[eventId].vue")
  },
  {
    name: "cybersport-categoryId-tournamentId-match-eventId___ru",
    path: "/ru/cybersport/:categoryId()/:tournamentId()/match/:eventId()",
    meta: _91eventId_93i59KLwB3hxMeta || {},
    component: () => import("/app/apps/site/pages/cybersport/[categoryId]/[tournamentId]/match/[eventId].vue")
  },
  {
    name: "cybersport-categoryId-tournamentId-match-eventId___es",
    path: "/es/cybersport/:categoryId()/:tournamentId()/match/:eventId()",
    meta: _91eventId_93i59KLwB3hxMeta || {},
    component: () => import("/app/apps/site/pages/cybersport/[categoryId]/[tournamentId]/match/[eventId].vue")
  },
  {
    name: "cybersport-categoryId-tournamentId-match-eventId___pt",
    path: "/pt/cybersport/:categoryId()/:tournamentId()/match/:eventId()",
    meta: _91eventId_93i59KLwB3hxMeta || {},
    component: () => import("/app/apps/site/pages/cybersport/[categoryId]/[tournamentId]/match/[eventId].vue")
  },
  {
    name: "cybersport-categoryId___en",
    path: "/cybersport/:categoryId()",
    component: () => import("/app/apps/site/pages/cybersport/[categoryId]/index.vue")
  },
  {
    name: "cybersport-categoryId___ru",
    path: "/ru/cybersport/:categoryId()",
    component: () => import("/app/apps/site/pages/cybersport/[categoryId]/index.vue")
  },
  {
    name: "cybersport-categoryId___es",
    path: "/es/cybersport/:categoryId()",
    component: () => import("/app/apps/site/pages/cybersport/[categoryId]/index.vue")
  },
  {
    name: "cybersport-categoryId___pt",
    path: "/pt/cybersport/:categoryId()",
    component: () => import("/app/apps/site/pages/cybersport/[categoryId]/index.vue")
  },
  {
    name: "cybersport-all___en",
    path: "/cybersport/all",
    component: () => import("/app/apps/site/pages/cybersport/all.vue")
  },
  {
    name: "cybersport-all___ru",
    path: "/ru/cybersport/all",
    component: () => import("/app/apps/site/pages/cybersport/all.vue")
  },
  {
    name: "cybersport-all___es",
    path: "/es/cybersport/all",
    component: () => import("/app/apps/site/pages/cybersport/all.vue")
  },
  {
    name: "cybersport-all___pt",
    path: "/pt/cybersport/all",
    component: () => import("/app/apps/site/pages/cybersport/all.vue")
  },
  {
    name: "cybersport___en",
    path: "/cybersport",
    component: () => import("/app/apps/site/pages/cybersport/index.vue")
  },
  {
    name: "cybersport___ru",
    path: "/ru/cybersport",
    component: () => import("/app/apps/site/pages/cybersport/index.vue")
  },
  {
    name: "cybersport___es",
    path: "/es/cybersport",
    component: () => import("/app/apps/site/pages/cybersport/index.vue")
  },
  {
    name: "cybersport___pt",
    path: "/pt/cybersport",
    component: () => import("/app/apps/site/pages/cybersport/index.vue")
  },
  {
    name: "cybersport-live___en",
    path: "/cybersport/live",
    component: () => import("/app/apps/site/pages/cybersport/live.vue")
  },
  {
    name: "cybersport-live___ru",
    path: "/ru/cybersport/live",
    component: () => import("/app/apps/site/pages/cybersport/live.vue")
  },
  {
    name: "cybersport-live___es",
    path: "/es/cybersport/live",
    component: () => import("/app/apps/site/pages/cybersport/live.vue")
  },
  {
    name: "cybersport-live___pt",
    path: "/pt/cybersport/live",
    component: () => import("/app/apps/site/pages/cybersport/live.vue")
  },
  {
    name: "cybersport-upcoming___en",
    path: "/cybersport/upcoming",
    component: () => import("/app/apps/site/pages/cybersport/upcoming.vue")
  },
  {
    name: "cybersport-upcoming___ru",
    path: "/ru/cybersport/upcoming",
    component: () => import("/app/apps/site/pages/cybersport/upcoming.vue")
  },
  {
    name: "cybersport-upcoming___es",
    path: "/es/cybersport/upcoming",
    component: () => import("/app/apps/site/pages/cybersport/upcoming.vue")
  },
  {
    name: "cybersport-upcoming___pt",
    path: "/pt/cybersport/upcoming",
    component: () => import("/app/apps/site/pages/cybersport/upcoming.vue")
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/app/apps/site/pages/index.vue")
  },
  {
    name: "index___ru",
    path: "/ru",
    component: () => import("/app/apps/site/pages/index.vue")
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/app/apps/site/pages/index.vue")
  },
  {
    name: "index___pt",
    path: "/pt",
    component: () => import("/app/apps/site/pages/index.vue")
  },
  {
    name: "mobile-bonus-info___en",
    path: "/mobile/bonus-info",
    meta: bonus_45infoEWBNiQdUJrMeta || {},
    component: () => import("/app/apps/site/pages/mobile/bonus-info.vue")
  },
  {
    name: "mobile-bonus-info___ru",
    path: "/ru/mobile/bonus-info",
    meta: bonus_45infoEWBNiQdUJrMeta || {},
    component: () => import("/app/apps/site/pages/mobile/bonus-info.vue")
  },
  {
    name: "mobile-bonus-info___es",
    path: "/es/mobile/bonus-info",
    meta: bonus_45infoEWBNiQdUJrMeta || {},
    component: () => import("/app/apps/site/pages/mobile/bonus-info.vue")
  },
  {
    name: "mobile-bonus-info___pt",
    path: "/pt/mobile/bonus-info",
    meta: bonus_45infoEWBNiQdUJrMeta || {},
    component: () => import("/app/apps/site/pages/mobile/bonus-info.vue")
  },
  {
    name: "mobile-bonuses___en",
    path: "/mobile/bonuses",
    meta: bonusesD2RCjujVtjMeta || {},
    component: () => import("/app/apps/site/pages/mobile/bonuses.vue")
  },
  {
    name: "mobile-bonuses___ru",
    path: "/ru/mobile/bonuses",
    meta: bonusesD2RCjujVtjMeta || {},
    component: () => import("/app/apps/site/pages/mobile/bonuses.vue")
  },
  {
    name: "mobile-bonuses___es",
    path: "/es/mobile/bonuses",
    meta: bonusesD2RCjujVtjMeta || {},
    component: () => import("/app/apps/site/pages/mobile/bonuses.vue")
  },
  {
    name: "mobile-bonuses___pt",
    path: "/pt/mobile/bonuses",
    meta: bonusesD2RCjujVtjMeta || {},
    component: () => import("/app/apps/site/pages/mobile/bonuses.vue")
  },
  {
    name: "mobile-casino-categories-categoryCode-subCategoryCode___en",
    path: "/mobile/casino/categories/:categoryCode()/:subCategoryCode()",
    meta: _91subCategoryCode_93YLVkHNGCfwMeta || {},
    component: () => import("/app/apps/site/pages/mobile/casino/categories/[categoryCode]/[subCategoryCode].vue")
  },
  {
    name: "mobile-casino-categories-categoryCode-subCategoryCode___ru",
    path: "/ru/mobile/casino/categories/:categoryCode()/:subCategoryCode()",
    meta: _91subCategoryCode_93YLVkHNGCfwMeta || {},
    component: () => import("/app/apps/site/pages/mobile/casino/categories/[categoryCode]/[subCategoryCode].vue")
  },
  {
    name: "mobile-casino-categories-categoryCode-subCategoryCode___es",
    path: "/es/mobile/casino/categories/:categoryCode()/:subCategoryCode()",
    meta: _91subCategoryCode_93YLVkHNGCfwMeta || {},
    component: () => import("/app/apps/site/pages/mobile/casino/categories/[categoryCode]/[subCategoryCode].vue")
  },
  {
    name: "mobile-casino-categories-categoryCode-subCategoryCode___pt",
    path: "/pt/mobile/casino/categories/:categoryCode()/:subCategoryCode()",
    meta: _91subCategoryCode_93YLVkHNGCfwMeta || {},
    component: () => import("/app/apps/site/pages/mobile/casino/categories/[categoryCode]/[subCategoryCode].vue")
  },
  {
    name: "mobile-casino-categories-categoryCode___en",
    path: "/mobile/casino/categories/:categoryCode()",
    meta: indexUcNfpQ4RR5Meta || {},
    component: () => import("/app/apps/site/pages/mobile/casino/categories/[categoryCode]/index.vue")
  },
  {
    name: "mobile-casino-categories-categoryCode___ru",
    path: "/ru/mobile/casino/categories/:categoryCode()",
    meta: indexUcNfpQ4RR5Meta || {},
    component: () => import("/app/apps/site/pages/mobile/casino/categories/[categoryCode]/index.vue")
  },
  {
    name: "mobile-casino-categories-categoryCode___es",
    path: "/es/mobile/casino/categories/:categoryCode()",
    meta: indexUcNfpQ4RR5Meta || {},
    component: () => import("/app/apps/site/pages/mobile/casino/categories/[categoryCode]/index.vue")
  },
  {
    name: "mobile-casino-categories-categoryCode___pt",
    path: "/pt/mobile/casino/categories/:categoryCode()",
    meta: indexUcNfpQ4RR5Meta || {},
    component: () => import("/app/apps/site/pages/mobile/casino/categories/[categoryCode]/index.vue")
  },
  {
    name: "mobile-casino-favorites___en",
    path: "/mobile/casino/favorites",
    meta: favoritesousD4GQHn7Meta || {},
    component: () => import("/app/apps/site/pages/mobile/casino/favorites.vue")
  },
  {
    name: "mobile-casino-favorites___ru",
    path: "/ru/mobile/casino/favorites",
    meta: favoritesousD4GQHn7Meta || {},
    component: () => import("/app/apps/site/pages/mobile/casino/favorites.vue")
  },
  {
    name: "mobile-casino-favorites___es",
    path: "/es/mobile/casino/favorites",
    meta: favoritesousD4GQHn7Meta || {},
    component: () => import("/app/apps/site/pages/mobile/casino/favorites.vue")
  },
  {
    name: "mobile-casino-favorites___pt",
    path: "/pt/mobile/casino/favorites",
    meta: favoritesousD4GQHn7Meta || {},
    component: () => import("/app/apps/site/pages/mobile/casino/favorites.vue")
  },
  {
    name: "mobile-casino-freespins___en",
    path: "/mobile/casino/freespins",
    meta: freespinsSkQBiU72bIMeta || {},
    component: () => import("/app/apps/site/pages/mobile/casino/freespins.vue")
  },
  {
    name: "mobile-casino-freespins___ru",
    path: "/ru/mobile/casino/freespins",
    meta: freespinsSkQBiU72bIMeta || {},
    component: () => import("/app/apps/site/pages/mobile/casino/freespins.vue")
  },
  {
    name: "mobile-casino-freespins___es",
    path: "/es/mobile/casino/freespins",
    meta: freespinsSkQBiU72bIMeta || {},
    component: () => import("/app/apps/site/pages/mobile/casino/freespins.vue")
  },
  {
    name: "mobile-casino-freespins___pt",
    path: "/pt/mobile/casino/freespins",
    meta: freespinsSkQBiU72bIMeta || {},
    component: () => import("/app/apps/site/pages/mobile/casino/freespins.vue")
  },
  {
    name: "mobile-casino-games-id___en",
    path: "/mobile/casino/games/:id()",
    meta: _91id_93dCaJNoT8pCMeta || {},
    component: () => import("/app/apps/site/pages/mobile/casino/games/[id].vue")
  },
  {
    name: "mobile-casino-games-id___ru",
    path: "/ru/mobile/casino/games/:id()",
    meta: _91id_93dCaJNoT8pCMeta || {},
    component: () => import("/app/apps/site/pages/mobile/casino/games/[id].vue")
  },
  {
    name: "mobile-casino-games-id___es",
    path: "/es/mobile/casino/games/:id()",
    meta: _91id_93dCaJNoT8pCMeta || {},
    component: () => import("/app/apps/site/pages/mobile/casino/games/[id].vue")
  },
  {
    name: "mobile-casino-games-id___pt",
    path: "/pt/mobile/casino/games/:id()",
    meta: _91id_93dCaJNoT8pCMeta || {},
    component: () => import("/app/apps/site/pages/mobile/casino/games/[id].vue")
  },
  {
    name: "mobile-casino___en",
    path: "/mobile/casino",
    meta: indexrTi5HvIA0YMeta || {},
    component: () => import("/app/apps/site/pages/mobile/casino/index.vue")
  },
  {
    name: "mobile-casino___ru",
    path: "/ru/mobile/casino",
    meta: indexrTi5HvIA0YMeta || {},
    component: () => import("/app/apps/site/pages/mobile/casino/index.vue")
  },
  {
    name: "mobile-casino___es",
    path: "/es/mobile/casino",
    meta: indexrTi5HvIA0YMeta || {},
    component: () => import("/app/apps/site/pages/mobile/casino/index.vue")
  },
  {
    name: "mobile-casino___pt",
    path: "/pt/mobile/casino",
    meta: indexrTi5HvIA0YMeta || {},
    component: () => import("/app/apps/site/pages/mobile/casino/index.vue")
  },
  {
    name: "mobile-casino-providers-provider___en",
    path: "/mobile/casino/providers/:provider()",
    meta: _91provider_93mSnP4jjMB4Meta || {},
    component: () => import("/app/apps/site/pages/mobile/casino/providers/[provider].vue")
  },
  {
    name: "mobile-casino-providers-provider___ru",
    path: "/ru/mobile/casino/providers/:provider()",
    meta: _91provider_93mSnP4jjMB4Meta || {},
    component: () => import("/app/apps/site/pages/mobile/casino/providers/[provider].vue")
  },
  {
    name: "mobile-casino-providers-provider___es",
    path: "/es/mobile/casino/providers/:provider()",
    meta: _91provider_93mSnP4jjMB4Meta || {},
    component: () => import("/app/apps/site/pages/mobile/casino/providers/[provider].vue")
  },
  {
    name: "mobile-casino-providers-provider___pt",
    path: "/pt/mobile/casino/providers/:provider()",
    meta: _91provider_93mSnP4jjMB4Meta || {},
    component: () => import("/app/apps/site/pages/mobile/casino/providers/[provider].vue")
  },
  {
    name: "mobile-casino-providers___en",
    path: "/mobile/casino/providers",
    meta: indexoWW5sKcFaBMeta || {},
    component: () => import("/app/apps/site/pages/mobile/casino/providers/index.vue")
  },
  {
    name: "mobile-casino-providers___ru",
    path: "/ru/mobile/casino/providers",
    meta: indexoWW5sKcFaBMeta || {},
    component: () => import("/app/apps/site/pages/mobile/casino/providers/index.vue")
  },
  {
    name: "mobile-casino-providers___es",
    path: "/es/mobile/casino/providers",
    meta: indexoWW5sKcFaBMeta || {},
    component: () => import("/app/apps/site/pages/mobile/casino/providers/index.vue")
  },
  {
    name: "mobile-casino-providers___pt",
    path: "/pt/mobile/casino/providers",
    meta: indexoWW5sKcFaBMeta || {},
    component: () => import("/app/apps/site/pages/mobile/casino/providers/index.vue")
  },
  {
    name: "mobile-casino-recent___en",
    path: "/mobile/casino/recent",
    meta: recent70MF2fjFrMMeta || {},
    component: () => import("/app/apps/site/pages/mobile/casino/recent.vue")
  },
  {
    name: "mobile-casino-recent___ru",
    path: "/ru/mobile/casino/recent",
    meta: recent70MF2fjFrMMeta || {},
    component: () => import("/app/apps/site/pages/mobile/casino/recent.vue")
  },
  {
    name: "mobile-casino-recent___es",
    path: "/es/mobile/casino/recent",
    meta: recent70MF2fjFrMMeta || {},
    component: () => import("/app/apps/site/pages/mobile/casino/recent.vue")
  },
  {
    name: "mobile-casino-recent___pt",
    path: "/pt/mobile/casino/recent",
    meta: recent70MF2fjFrMMeta || {},
    component: () => import("/app/apps/site/pages/mobile/casino/recent.vue")
  },
  {
    name: "mobile-casino-restriction___en",
    path: "/mobile/casino/restriction",
    meta: restrictionKDZxmvpctbMeta || {},
    component: () => import("/app/apps/site/pages/mobile/casino/restriction.vue")
  },
  {
    name: "mobile-casino-restriction___ru",
    path: "/ru/mobile/casino/restriction",
    meta: restrictionKDZxmvpctbMeta || {},
    component: () => import("/app/apps/site/pages/mobile/casino/restriction.vue")
  },
  {
    name: "mobile-casino-restriction___es",
    path: "/es/mobile/casino/restriction",
    meta: restrictionKDZxmvpctbMeta || {},
    component: () => import("/app/apps/site/pages/mobile/casino/restriction.vue")
  },
  {
    name: "mobile-casino-restriction___pt",
    path: "/pt/mobile/casino/restriction",
    meta: restrictionKDZxmvpctbMeta || {},
    component: () => import("/app/apps/site/pages/mobile/casino/restriction.vue")
  },
  {
    name: "mobile-cybersport-categoryId-tournamentId___en",
    path: "/mobile/cybersport/:categoryId()/:tournamentId()",
    meta: indexgWME7u8Q1IMeta || {},
    component: () => import("/app/apps/site/pages/mobile/cybersport/[categoryId]/[tournamentId]/index.vue")
  },
  {
    name: "mobile-cybersport-categoryId-tournamentId___ru",
    path: "/ru/mobile/cybersport/:categoryId()/:tournamentId()",
    meta: indexgWME7u8Q1IMeta || {},
    component: () => import("/app/apps/site/pages/mobile/cybersport/[categoryId]/[tournamentId]/index.vue")
  },
  {
    name: "mobile-cybersport-categoryId-tournamentId___es",
    path: "/es/mobile/cybersport/:categoryId()/:tournamentId()",
    meta: indexgWME7u8Q1IMeta || {},
    component: () => import("/app/apps/site/pages/mobile/cybersport/[categoryId]/[tournamentId]/index.vue")
  },
  {
    name: "mobile-cybersport-categoryId-tournamentId___pt",
    path: "/pt/mobile/cybersport/:categoryId()/:tournamentId()",
    meta: indexgWME7u8Q1IMeta || {},
    component: () => import("/app/apps/site/pages/mobile/cybersport/[categoryId]/[tournamentId]/index.vue")
  },
  {
    name: "mobile-cybersport-categoryId-tournamentId-match-eventId___en",
    path: "/mobile/cybersport/:categoryId()/:tournamentId()/match/:eventId()",
    meta: _91eventId_93DVt1Ud75wCMeta || {},
    component: () => import("/app/apps/site/pages/mobile/cybersport/[categoryId]/[tournamentId]/match/[eventId].vue")
  },
  {
    name: "mobile-cybersport-categoryId-tournamentId-match-eventId___ru",
    path: "/ru/mobile/cybersport/:categoryId()/:tournamentId()/match/:eventId()",
    meta: _91eventId_93DVt1Ud75wCMeta || {},
    component: () => import("/app/apps/site/pages/mobile/cybersport/[categoryId]/[tournamentId]/match/[eventId].vue")
  },
  {
    name: "mobile-cybersport-categoryId-tournamentId-match-eventId___es",
    path: "/es/mobile/cybersport/:categoryId()/:tournamentId()/match/:eventId()",
    meta: _91eventId_93DVt1Ud75wCMeta || {},
    component: () => import("/app/apps/site/pages/mobile/cybersport/[categoryId]/[tournamentId]/match/[eventId].vue")
  },
  {
    name: "mobile-cybersport-categoryId-tournamentId-match-eventId___pt",
    path: "/pt/mobile/cybersport/:categoryId()/:tournamentId()/match/:eventId()",
    meta: _91eventId_93DVt1Ud75wCMeta || {},
    component: () => import("/app/apps/site/pages/mobile/cybersport/[categoryId]/[tournamentId]/match/[eventId].vue")
  },
  {
    name: "mobile-cybersport-categoryId___en",
    path: "/mobile/cybersport/:categoryId()",
    meta: indexdH9LuxHdaqMeta || {},
    component: () => import("/app/apps/site/pages/mobile/cybersport/[categoryId]/index.vue")
  },
  {
    name: "mobile-cybersport-categoryId___ru",
    path: "/ru/mobile/cybersport/:categoryId()",
    meta: indexdH9LuxHdaqMeta || {},
    component: () => import("/app/apps/site/pages/mobile/cybersport/[categoryId]/index.vue")
  },
  {
    name: "mobile-cybersport-categoryId___es",
    path: "/es/mobile/cybersport/:categoryId()",
    meta: indexdH9LuxHdaqMeta || {},
    component: () => import("/app/apps/site/pages/mobile/cybersport/[categoryId]/index.vue")
  },
  {
    name: "mobile-cybersport-categoryId___pt",
    path: "/pt/mobile/cybersport/:categoryId()",
    meta: indexdH9LuxHdaqMeta || {},
    component: () => import("/app/apps/site/pages/mobile/cybersport/[categoryId]/index.vue")
  },
  {
    name: "mobile-cybersport___en",
    path: "/mobile/cybersport",
    meta: indexVUsbMRqukFMeta || {},
    component: () => import("/app/apps/site/pages/mobile/cybersport/index.vue")
  },
  {
    name: "mobile-cybersport___ru",
    path: "/ru/mobile/cybersport",
    meta: indexVUsbMRqukFMeta || {},
    component: () => import("/app/apps/site/pages/mobile/cybersport/index.vue")
  },
  {
    name: "mobile-cybersport___es",
    path: "/es/mobile/cybersport",
    meta: indexVUsbMRqukFMeta || {},
    component: () => import("/app/apps/site/pages/mobile/cybersport/index.vue")
  },
  {
    name: "mobile-cybersport___pt",
    path: "/pt/mobile/cybersport",
    meta: indexVUsbMRqukFMeta || {},
    component: () => import("/app/apps/site/pages/mobile/cybersport/index.vue")
  },
  {
    name: "mobile-cybersport-live___en",
    path: "/mobile/cybersport/live",
    meta: liverPDIk4ZKRrMeta || {},
    component: () => import("/app/apps/site/pages/mobile/cybersport/live.vue")
  },
  {
    name: "mobile-cybersport-live___ru",
    path: "/ru/mobile/cybersport/live",
    meta: liverPDIk4ZKRrMeta || {},
    component: () => import("/app/apps/site/pages/mobile/cybersport/live.vue")
  },
  {
    name: "mobile-cybersport-live___es",
    path: "/es/mobile/cybersport/live",
    meta: liverPDIk4ZKRrMeta || {},
    component: () => import("/app/apps/site/pages/mobile/cybersport/live.vue")
  },
  {
    name: "mobile-cybersport-live___pt",
    path: "/pt/mobile/cybersport/live",
    meta: liverPDIk4ZKRrMeta || {},
    component: () => import("/app/apps/site/pages/mobile/cybersport/live.vue")
  },
  {
    name: "mobile-cybersport-upcoming___en",
    path: "/mobile/cybersport/upcoming",
    meta: upcomingl8twCvk0S4Meta || {},
    component: () => import("/app/apps/site/pages/mobile/cybersport/upcoming.vue")
  },
  {
    name: "mobile-cybersport-upcoming___ru",
    path: "/ru/mobile/cybersport/upcoming",
    meta: upcomingl8twCvk0S4Meta || {},
    component: () => import("/app/apps/site/pages/mobile/cybersport/upcoming.vue")
  },
  {
    name: "mobile-cybersport-upcoming___es",
    path: "/es/mobile/cybersport/upcoming",
    meta: upcomingl8twCvk0S4Meta || {},
    component: () => import("/app/apps/site/pages/mobile/cybersport/upcoming.vue")
  },
  {
    name: "mobile-cybersport-upcoming___pt",
    path: "/pt/mobile/cybersport/upcoming",
    meta: upcomingl8twCvk0S4Meta || {},
    component: () => import("/app/apps/site/pages/mobile/cybersport/upcoming.vue")
  },
  {
    name: "mobile___en",
    path: "/mobile",
    meta: indexQetr5klI9HMeta || {},
    component: () => import("/app/apps/site/pages/mobile/index.vue")
  },
  {
    name: "mobile___ru",
    path: "/ru/mobile",
    meta: indexQetr5klI9HMeta || {},
    component: () => import("/app/apps/site/pages/mobile/index.vue")
  },
  {
    name: "mobile___es",
    path: "/es/mobile",
    meta: indexQetr5klI9HMeta || {},
    component: () => import("/app/apps/site/pages/mobile/index.vue")
  },
  {
    name: "mobile___pt",
    path: "/pt/mobile",
    meta: indexQetr5klI9HMeta || {},
    component: () => import("/app/apps/site/pages/mobile/index.vue")
  },
  {
    name: "mobile-personal-bets___en",
    path: "/mobile/personal/bets",
    meta: betsMTi5WP7IbTMeta || {},
    component: () => import("/app/apps/site/pages/mobile/personal/bets.vue")
  },
  {
    name: "mobile-personal-bets___ru",
    path: "/ru/mobile/personal/bets",
    meta: betsMTi5WP7IbTMeta || {},
    component: () => import("/app/apps/site/pages/mobile/personal/bets.vue")
  },
  {
    name: "mobile-personal-bets___es",
    path: "/es/mobile/personal/bets",
    meta: betsMTi5WP7IbTMeta || {},
    component: () => import("/app/apps/site/pages/mobile/personal/bets.vue")
  },
  {
    name: "mobile-personal-bets___pt",
    path: "/pt/mobile/personal/bets",
    meta: betsMTi5WP7IbTMeta || {},
    component: () => import("/app/apps/site/pages/mobile/personal/bets.vue")
  },
  {
    name: "mobile-personal-kyc___en",
    path: "/mobile/personal/kyc",
    meta: kychGo1dZz1ttMeta || {},
    component: () => import("/app/apps/site/pages/mobile/personal/kyc.vue")
  },
  {
    name: "mobile-personal-kyc___ru",
    path: "/ru/mobile/personal/kyc",
    meta: kychGo1dZz1ttMeta || {},
    component: () => import("/app/apps/site/pages/mobile/personal/kyc.vue")
  },
  {
    name: "mobile-personal-kyc___es",
    path: "/es/mobile/personal/kyc",
    meta: kychGo1dZz1ttMeta || {},
    component: () => import("/app/apps/site/pages/mobile/personal/kyc.vue")
  },
  {
    name: "mobile-personal-kyc___pt",
    path: "/pt/mobile/personal/kyc",
    meta: kychGo1dZz1ttMeta || {},
    component: () => import("/app/apps/site/pages/mobile/personal/kyc.vue")
  },
  {
    name: "mobile-personal-profile___en",
    path: "/mobile/personal/profile",
    meta: profile3vJcr8KiKGMeta || {},
    component: () => import("/app/apps/site/pages/mobile/personal/profile.vue")
  },
  {
    name: "mobile-personal-profile___ru",
    path: "/ru/mobile/personal/profile",
    meta: profile3vJcr8KiKGMeta || {},
    component: () => import("/app/apps/site/pages/mobile/personal/profile.vue")
  },
  {
    name: "mobile-personal-profile___es",
    path: "/es/mobile/personal/profile",
    meta: profile3vJcr8KiKGMeta || {},
    component: () => import("/app/apps/site/pages/mobile/personal/profile.vue")
  },
  {
    name: "mobile-personal-profile___pt",
    path: "/pt/mobile/personal/profile",
    meta: profile3vJcr8KiKGMeta || {},
    component: () => import("/app/apps/site/pages/mobile/personal/profile.vue")
  },
  {
    name: "mobile-personal-settings___en",
    path: "/mobile/personal/settings",
    meta: settingsYRoe6PG0BfMeta || {},
    component: () => import("/app/apps/site/pages/mobile/personal/settings.vue")
  },
  {
    name: "mobile-personal-settings___ru",
    path: "/ru/mobile/personal/settings",
    meta: settingsYRoe6PG0BfMeta || {},
    component: () => import("/app/apps/site/pages/mobile/personal/settings.vue")
  },
  {
    name: "mobile-personal-settings___es",
    path: "/es/mobile/personal/settings",
    meta: settingsYRoe6PG0BfMeta || {},
    component: () => import("/app/apps/site/pages/mobile/personal/settings.vue")
  },
  {
    name: "mobile-personal-settings___pt",
    path: "/pt/mobile/personal/settings",
    meta: settingsYRoe6PG0BfMeta || {},
    component: () => import("/app/apps/site/pages/mobile/personal/settings.vue")
  },
  {
    name: "mobile-personal-transactions___en",
    path: "/mobile/personal/transactions",
    meta: transactionsAMF9N0beyOMeta || {},
    component: () => import("/app/apps/site/pages/mobile/personal/transactions.vue")
  },
  {
    name: "mobile-personal-transactions___ru",
    path: "/ru/mobile/personal/transactions",
    meta: transactionsAMF9N0beyOMeta || {},
    component: () => import("/app/apps/site/pages/mobile/personal/transactions.vue")
  },
  {
    name: "mobile-personal-transactions___es",
    path: "/es/mobile/personal/transactions",
    meta: transactionsAMF9N0beyOMeta || {},
    component: () => import("/app/apps/site/pages/mobile/personal/transactions.vue")
  },
  {
    name: "mobile-personal-transactions___pt",
    path: "/pt/mobile/personal/transactions",
    meta: transactionsAMF9N0beyOMeta || {},
    component: () => import("/app/apps/site/pages/mobile/personal/transactions.vue")
  },
  {
    name: "mobile-personal-wallet___en",
    path: "/mobile/personal/wallet",
    meta: walletXz5gW2EWqtMeta || {},
    component: () => import("/app/apps/site/pages/mobile/personal/wallet.vue")
  },
  {
    name: "mobile-personal-wallet___ru",
    path: "/ru/mobile/personal/wallet",
    meta: walletXz5gW2EWqtMeta || {},
    component: () => import("/app/apps/site/pages/mobile/personal/wallet.vue")
  },
  {
    name: "mobile-personal-wallet___es",
    path: "/es/mobile/personal/wallet",
    meta: walletXz5gW2EWqtMeta || {},
    component: () => import("/app/apps/site/pages/mobile/personal/wallet.vue")
  },
  {
    name: "mobile-personal-wallet___pt",
    path: "/pt/mobile/personal/wallet",
    meta: walletXz5gW2EWqtMeta || {},
    component: () => import("/app/apps/site/pages/mobile/personal/wallet.vue")
  },
  {
    name: "mobile-promo-registration___en",
    path: "/mobile/promo/registration",
    meta: registrationrbzrfXjjhQMeta || {},
    component: () => import("/app/apps/site/pages/mobile/promo/registration.vue")
  },
  {
    name: "mobile-promo-registration___ru",
    path: "/ru/mobile/promo/registration",
    meta: registrationrbzrfXjjhQMeta || {},
    component: () => import("/app/apps/site/pages/mobile/promo/registration.vue")
  },
  {
    name: "mobile-promo-registration___es",
    path: "/es/mobile/promo/registration",
    meta: registrationrbzrfXjjhQMeta || {},
    component: () => import("/app/apps/site/pages/mobile/promo/registration.vue")
  },
  {
    name: "mobile-promo-registration___pt",
    path: "/pt/mobile/promo/registration",
    meta: registrationrbzrfXjjhQMeta || {},
    component: () => import("/app/apps/site/pages/mobile/promo/registration.vue")
  },
  {
    name: "mobile-pwa___en",
    path: "/mobile/pwa",
    meta: pwahw5TdcnbyIMeta || {},
    component: () => import("/app/apps/site/pages/mobile/pwa.vue")
  },
  {
    name: "mobile-pwa___ru",
    path: "/ru/mobile/pwa",
    meta: pwahw5TdcnbyIMeta || {},
    component: () => import("/app/apps/site/pages/mobile/pwa.vue")
  },
  {
    name: "mobile-pwa___es",
    path: "/es/mobile/pwa",
    meta: pwahw5TdcnbyIMeta || {},
    component: () => import("/app/apps/site/pages/mobile/pwa.vue")
  },
  {
    name: "mobile-pwa___pt",
    path: "/pt/mobile/pwa",
    meta: pwahw5TdcnbyIMeta || {},
    component: () => import("/app/apps/site/pages/mobile/pwa.vue")
  },
  {
    name: "mobile-quests___en",
    path: "/mobile/quests",
    meta: questsuonvIO0qVgMeta || {},
    component: () => import("/app/apps/site/pages/mobile/quests.vue")
  },
  {
    name: "mobile-quests___ru",
    path: "/ru/mobile/quests",
    meta: questsuonvIO0qVgMeta || {},
    component: () => import("/app/apps/site/pages/mobile/quests.vue")
  },
  {
    name: "mobile-quests___es",
    path: "/es/mobile/quests",
    meta: questsuonvIO0qVgMeta || {},
    component: () => import("/app/apps/site/pages/mobile/quests.vue")
  },
  {
    name: "mobile-quests___pt",
    path: "/pt/mobile/quests",
    meta: questsuonvIO0qVgMeta || {},
    component: () => import("/app/apps/site/pages/mobile/quests.vue")
  },
  {
    name: "mobile-refer-and-earn___en",
    path: "/mobile/refer-and-earn",
    meta: refer_45and_45earnVwMEkgnOtnMeta || {},
    component: () => import("/app/apps/site/pages/mobile/refer-and-earn.vue")
  },
  {
    name: "mobile-refer-and-earn___ru",
    path: "/ru/mobile/refer-and-earn",
    meta: refer_45and_45earnVwMEkgnOtnMeta || {},
    component: () => import("/app/apps/site/pages/mobile/refer-and-earn.vue")
  },
  {
    name: "mobile-refer-and-earn___es",
    path: "/es/mobile/refer-and-earn",
    meta: refer_45and_45earnVwMEkgnOtnMeta || {},
    component: () => import("/app/apps/site/pages/mobile/refer-and-earn.vue")
  },
  {
    name: "mobile-refer-and-earn___pt",
    path: "/pt/mobile/refer-and-earn",
    meta: refer_45and_45earnVwMEkgnOtnMeta || {},
    component: () => import("/app/apps/site/pages/mobile/refer-and-earn.vue")
  },
  {
    name: "mobile-refer-info___en",
    path: "/mobile/refer-info",
    meta: refer_45info19hTPRpea6Meta || {},
    component: () => import("/app/apps/site/pages/mobile/refer-info.vue")
  },
  {
    name: "mobile-refer-info___ru",
    path: "/ru/mobile/refer-info",
    meta: refer_45info19hTPRpea6Meta || {},
    component: () => import("/app/apps/site/pages/mobile/refer-info.vue")
  },
  {
    name: "mobile-refer-info___es",
    path: "/es/mobile/refer-info",
    meta: refer_45info19hTPRpea6Meta || {},
    component: () => import("/app/apps/site/pages/mobile/refer-info.vue")
  },
  {
    name: "mobile-refer-info___pt",
    path: "/pt/mobile/refer-info",
    meta: refer_45info19hTPRpea6Meta || {},
    component: () => import("/app/apps/site/pages/mobile/refer-info.vue")
  },
  {
    name: "mobile-sport-sportId-categoryId-tournamentId___en",
    path: "/mobile/sport/:sportId()/:categoryId()/:tournamentId()",
    meta: indexbhxh01Kd9wMeta || {},
    component: () => import("/app/apps/site/pages/mobile/sport/[sportId]/[categoryId]/[tournamentId]/index.vue")
  },
  {
    name: "mobile-sport-sportId-categoryId-tournamentId___ru",
    path: "/ru/mobile/sport/:sportId()/:categoryId()/:tournamentId()",
    meta: indexbhxh01Kd9wMeta || {},
    component: () => import("/app/apps/site/pages/mobile/sport/[sportId]/[categoryId]/[tournamentId]/index.vue")
  },
  {
    name: "mobile-sport-sportId-categoryId-tournamentId___es",
    path: "/es/mobile/sport/:sportId()/:categoryId()/:tournamentId()",
    meta: indexbhxh01Kd9wMeta || {},
    component: () => import("/app/apps/site/pages/mobile/sport/[sportId]/[categoryId]/[tournamentId]/index.vue")
  },
  {
    name: "mobile-sport-sportId-categoryId-tournamentId___pt",
    path: "/pt/mobile/sport/:sportId()/:categoryId()/:tournamentId()",
    meta: indexbhxh01Kd9wMeta || {},
    component: () => import("/app/apps/site/pages/mobile/sport/[sportId]/[categoryId]/[tournamentId]/index.vue")
  },
  {
    name: "mobile-sport-sportId-categoryId-tournamentId-match-eventId___en",
    path: "/mobile/sport/:sportId()/:categoryId()/:tournamentId()/match/:eventId()",
    meta: _91eventId_93hQrAdnwKrmMeta || {},
    component: () => import("/app/apps/site/pages/mobile/sport/[sportId]/[categoryId]/[tournamentId]/match/[eventId].vue")
  },
  {
    name: "mobile-sport-sportId-categoryId-tournamentId-match-eventId___ru",
    path: "/ru/mobile/sport/:sportId()/:categoryId()/:tournamentId()/match/:eventId()",
    meta: _91eventId_93hQrAdnwKrmMeta || {},
    component: () => import("/app/apps/site/pages/mobile/sport/[sportId]/[categoryId]/[tournamentId]/match/[eventId].vue")
  },
  {
    name: "mobile-sport-sportId-categoryId-tournamentId-match-eventId___es",
    path: "/es/mobile/sport/:sportId()/:categoryId()/:tournamentId()/match/:eventId()",
    meta: _91eventId_93hQrAdnwKrmMeta || {},
    component: () => import("/app/apps/site/pages/mobile/sport/[sportId]/[categoryId]/[tournamentId]/match/[eventId].vue")
  },
  {
    name: "mobile-sport-sportId-categoryId-tournamentId-match-eventId___pt",
    path: "/pt/mobile/sport/:sportId()/:categoryId()/:tournamentId()/match/:eventId()",
    meta: _91eventId_93hQrAdnwKrmMeta || {},
    component: () => import("/app/apps/site/pages/mobile/sport/[sportId]/[categoryId]/[tournamentId]/match/[eventId].vue")
  },
  {
    name: "mobile-sport-sportId-categoryId___en",
    path: "/mobile/sport/:sportId()/:categoryId()",
    meta: indexTYNAyURMIHMeta || {},
    component: () => import("/app/apps/site/pages/mobile/sport/[sportId]/[categoryId]/index.vue")
  },
  {
    name: "mobile-sport-sportId-categoryId___ru",
    path: "/ru/mobile/sport/:sportId()/:categoryId()",
    meta: indexTYNAyURMIHMeta || {},
    component: () => import("/app/apps/site/pages/mobile/sport/[sportId]/[categoryId]/index.vue")
  },
  {
    name: "mobile-sport-sportId-categoryId___es",
    path: "/es/mobile/sport/:sportId()/:categoryId()",
    meta: indexTYNAyURMIHMeta || {},
    component: () => import("/app/apps/site/pages/mobile/sport/[sportId]/[categoryId]/index.vue")
  },
  {
    name: "mobile-sport-sportId-categoryId___pt",
    path: "/pt/mobile/sport/:sportId()/:categoryId()",
    meta: indexTYNAyURMIHMeta || {},
    component: () => import("/app/apps/site/pages/mobile/sport/[sportId]/[categoryId]/index.vue")
  },
  {
    name: "mobile-sport-sportId___en",
    path: "/mobile/sport/:sportId()",
    meta: indexpDhmRLHTMMMeta || {},
    component: () => import("/app/apps/site/pages/mobile/sport/[sportId]/index.vue")
  },
  {
    name: "mobile-sport-sportId___ru",
    path: "/ru/mobile/sport/:sportId()",
    meta: indexpDhmRLHTMMMeta || {},
    component: () => import("/app/apps/site/pages/mobile/sport/[sportId]/index.vue")
  },
  {
    name: "mobile-sport-sportId___es",
    path: "/es/mobile/sport/:sportId()",
    meta: indexpDhmRLHTMMMeta || {},
    component: () => import("/app/apps/site/pages/mobile/sport/[sportId]/index.vue")
  },
  {
    name: "mobile-sport-sportId___pt",
    path: "/pt/mobile/sport/:sportId()",
    meta: indexpDhmRLHTMMMeta || {},
    component: () => import("/app/apps/site/pages/mobile/sport/[sportId]/index.vue")
  },
  {
    name: "mobile-sport-all___en",
    path: "/mobile/sport/all",
    meta: all5lh487dru4Meta || {},
    component: () => import("/app/apps/site/pages/mobile/sport/all.vue")
  },
  {
    name: "mobile-sport-all___ru",
    path: "/ru/mobile/sport/all",
    meta: all5lh487dru4Meta || {},
    component: () => import("/app/apps/site/pages/mobile/sport/all.vue")
  },
  {
    name: "mobile-sport-all___es",
    path: "/es/mobile/sport/all",
    meta: all5lh487dru4Meta || {},
    component: () => import("/app/apps/site/pages/mobile/sport/all.vue")
  },
  {
    name: "mobile-sport-all___pt",
    path: "/pt/mobile/sport/all",
    meta: all5lh487dru4Meta || {},
    component: () => import("/app/apps/site/pages/mobile/sport/all.vue")
  },
  {
    name: "mobile-sport___en",
    path: "/mobile/sport",
    meta: indexqEDppdwYXwMeta || {},
    component: () => import("/app/apps/site/pages/mobile/sport/index.vue")
  },
  {
    name: "mobile-sport___ru",
    path: "/ru/mobile/sport",
    meta: indexqEDppdwYXwMeta || {},
    component: () => import("/app/apps/site/pages/mobile/sport/index.vue")
  },
  {
    name: "mobile-sport___es",
    path: "/es/mobile/sport",
    meta: indexqEDppdwYXwMeta || {},
    component: () => import("/app/apps/site/pages/mobile/sport/index.vue")
  },
  {
    name: "mobile-sport___pt",
    path: "/pt/mobile/sport",
    meta: indexqEDppdwYXwMeta || {},
    component: () => import("/app/apps/site/pages/mobile/sport/index.vue")
  },
  {
    name: "mobile-sport-live___en",
    path: "/mobile/sport/live",
    meta: liveGDPetqsNd2Meta || {},
    component: () => import("/app/apps/site/pages/mobile/sport/live.vue")
  },
  {
    name: "mobile-sport-live___ru",
    path: "/ru/mobile/sport/live",
    meta: liveGDPetqsNd2Meta || {},
    component: () => import("/app/apps/site/pages/mobile/sport/live.vue")
  },
  {
    name: "mobile-sport-live___es",
    path: "/es/mobile/sport/live",
    meta: liveGDPetqsNd2Meta || {},
    component: () => import("/app/apps/site/pages/mobile/sport/live.vue")
  },
  {
    name: "mobile-sport-live___pt",
    path: "/pt/mobile/sport/live",
    meta: liveGDPetqsNd2Meta || {},
    component: () => import("/app/apps/site/pages/mobile/sport/live.vue")
  },
  {
    name: "mobile-sport-upcoming___en",
    path: "/mobile/sport/upcoming",
    meta: upcomingjgGita4WfNMeta || {},
    component: () => import("/app/apps/site/pages/mobile/sport/upcoming.vue")
  },
  {
    name: "mobile-sport-upcoming___ru",
    path: "/ru/mobile/sport/upcoming",
    meta: upcomingjgGita4WfNMeta || {},
    component: () => import("/app/apps/site/pages/mobile/sport/upcoming.vue")
  },
  {
    name: "mobile-sport-upcoming___es",
    path: "/es/mobile/sport/upcoming",
    meta: upcomingjgGita4WfNMeta || {},
    component: () => import("/app/apps/site/pages/mobile/sport/upcoming.vue")
  },
  {
    name: "mobile-sport-upcoming___pt",
    path: "/pt/mobile/sport/upcoming",
    meta: upcomingjgGita4WfNMeta || {},
    component: () => import("/app/apps/site/pages/mobile/sport/upcoming.vue")
  },
  {
    name: "mobile-support___en",
    path: "/mobile/support",
    meta: supportamedNFbChdMeta || {},
    component: () => import("/app/apps/site/pages/mobile/support.vue")
  },
  {
    name: "mobile-support___ru",
    path: "/ru/mobile/support",
    meta: supportamedNFbChdMeta || {},
    component: () => import("/app/apps/site/pages/mobile/support.vue")
  },
  {
    name: "mobile-support___es",
    path: "/es/mobile/support",
    meta: supportamedNFbChdMeta || {},
    component: () => import("/app/apps/site/pages/mobile/support.vue")
  },
  {
    name: "mobile-support___pt",
    path: "/pt/mobile/support",
    meta: supportamedNFbChdMeta || {},
    component: () => import("/app/apps/site/pages/mobile/support.vue")
  },
  {
    name: "mobile-tournaments-id___en",
    path: "/mobile/tournaments/:id()",
    meta: _91id_93uSojnDCrRoMeta || {},
    component: () => import("/app/apps/site/pages/mobile/tournaments/[id].vue")
  },
  {
    name: "mobile-tournaments-id___ru",
    path: "/ru/mobile/tournaments/:id()",
    meta: _91id_93uSojnDCrRoMeta || {},
    component: () => import("/app/apps/site/pages/mobile/tournaments/[id].vue")
  },
  {
    name: "mobile-tournaments-id___es",
    path: "/es/mobile/tournaments/:id()",
    meta: _91id_93uSojnDCrRoMeta || {},
    component: () => import("/app/apps/site/pages/mobile/tournaments/[id].vue")
  },
  {
    name: "mobile-tournaments-id___pt",
    path: "/pt/mobile/tournaments/:id()",
    meta: _91id_93uSojnDCrRoMeta || {},
    component: () => import("/app/apps/site/pages/mobile/tournaments/[id].vue")
  },
  {
    name: "mobile-tournaments___en",
    path: "/mobile/tournaments",
    meta: index2Rf6gY2o1DMeta || {},
    component: () => import("/app/apps/site/pages/mobile/tournaments/index.vue")
  },
  {
    name: "mobile-tournaments___ru",
    path: "/ru/mobile/tournaments",
    meta: index2Rf6gY2o1DMeta || {},
    component: () => import("/app/apps/site/pages/mobile/tournaments/index.vue")
  },
  {
    name: "mobile-tournaments___es",
    path: "/es/mobile/tournaments",
    meta: index2Rf6gY2o1DMeta || {},
    component: () => import("/app/apps/site/pages/mobile/tournaments/index.vue")
  },
  {
    name: "mobile-tournaments___pt",
    path: "/pt/mobile/tournaments",
    meta: index2Rf6gY2o1DMeta || {},
    component: () => import("/app/apps/site/pages/mobile/tournaments/index.vue")
  },
  {
    name: "mobile-unsubscribe___en",
    path: "/mobile/unsubscribe",
    meta: unsubscribesnfj6WpwZ6Meta || {},
    component: () => import("/app/apps/site/pages/mobile/unsubscribe.vue")
  },
  {
    name: "mobile-unsubscribe___ru",
    path: "/ru/mobile/unsubscribe",
    meta: unsubscribesnfj6WpwZ6Meta || {},
    component: () => import("/app/apps/site/pages/mobile/unsubscribe.vue")
  },
  {
    name: "mobile-unsubscribe___es",
    path: "/es/mobile/unsubscribe",
    meta: unsubscribesnfj6WpwZ6Meta || {},
    component: () => import("/app/apps/site/pages/mobile/unsubscribe.vue")
  },
  {
    name: "mobile-unsubscribe___pt",
    path: "/pt/mobile/unsubscribe",
    meta: unsubscribesnfj6WpwZ6Meta || {},
    component: () => import("/app/apps/site/pages/mobile/unsubscribe.vue")
  },
  {
    name: "personal-bets___en",
    path: "/personal/bets",
    meta: bets55PXI7Bq75Meta || {},
    component: () => import("/app/apps/site/pages/personal/bets.vue")
  },
  {
    name: "personal-bets___ru",
    path: "/ru/personal/bets",
    meta: bets55PXI7Bq75Meta || {},
    component: () => import("/app/apps/site/pages/personal/bets.vue")
  },
  {
    name: "personal-bets___es",
    path: "/es/personal/bets",
    meta: bets55PXI7Bq75Meta || {},
    component: () => import("/app/apps/site/pages/personal/bets.vue")
  },
  {
    name: "personal-bets___pt",
    path: "/pt/personal/bets",
    meta: bets55PXI7Bq75Meta || {},
    component: () => import("/app/apps/site/pages/personal/bets.vue")
  },
  {
    name: "personal-kyc___en",
    path: "/personal/kyc",
    meta: kyc2QSbsoWkUkMeta || {},
    component: () => import("/app/apps/site/pages/personal/kyc.vue")
  },
  {
    name: "personal-kyc___ru",
    path: "/ru/personal/kyc",
    meta: kyc2QSbsoWkUkMeta || {},
    component: () => import("/app/apps/site/pages/personal/kyc.vue")
  },
  {
    name: "personal-kyc___es",
    path: "/es/personal/kyc",
    meta: kyc2QSbsoWkUkMeta || {},
    component: () => import("/app/apps/site/pages/personal/kyc.vue")
  },
  {
    name: "personal-kyc___pt",
    path: "/pt/personal/kyc",
    meta: kyc2QSbsoWkUkMeta || {},
    component: () => import("/app/apps/site/pages/personal/kyc.vue")
  },
  {
    name: "personal-profile___en",
    path: "/personal/profile",
    meta: profileGMGu9bdYmBMeta || {},
    component: () => import("/app/apps/site/pages/personal/profile.vue")
  },
  {
    name: "personal-profile___ru",
    path: "/ru/personal/profile",
    meta: profileGMGu9bdYmBMeta || {},
    component: () => import("/app/apps/site/pages/personal/profile.vue")
  },
  {
    name: "personal-profile___es",
    path: "/es/personal/profile",
    meta: profileGMGu9bdYmBMeta || {},
    component: () => import("/app/apps/site/pages/personal/profile.vue")
  },
  {
    name: "personal-profile___pt",
    path: "/pt/personal/profile",
    meta: profileGMGu9bdYmBMeta || {},
    component: () => import("/app/apps/site/pages/personal/profile.vue")
  },
  {
    name: "personal-transactions___en",
    path: "/personal/transactions",
    meta: transactionsxL2rxTO656Meta || {},
    component: () => import("/app/apps/site/pages/personal/transactions.vue")
  },
  {
    name: "personal-transactions___ru",
    path: "/ru/personal/transactions",
    meta: transactionsxL2rxTO656Meta || {},
    component: () => import("/app/apps/site/pages/personal/transactions.vue")
  },
  {
    name: "personal-transactions___es",
    path: "/es/personal/transactions",
    meta: transactionsxL2rxTO656Meta || {},
    component: () => import("/app/apps/site/pages/personal/transactions.vue")
  },
  {
    name: "personal-transactions___pt",
    path: "/pt/personal/transactions",
    meta: transactionsxL2rxTO656Meta || {},
    component: () => import("/app/apps/site/pages/personal/transactions.vue")
  },
  {
    name: "personal-wallet___en",
    path: "/personal/wallet",
    meta: walletZ0QEOx9alnMeta || {},
    component: () => import("/app/apps/site/pages/personal/wallet.vue")
  },
  {
    name: "personal-wallet___ru",
    path: "/ru/personal/wallet",
    meta: walletZ0QEOx9alnMeta || {},
    component: () => import("/app/apps/site/pages/personal/wallet.vue")
  },
  {
    name: "personal-wallet___es",
    path: "/es/personal/wallet",
    meta: walletZ0QEOx9alnMeta || {},
    component: () => import("/app/apps/site/pages/personal/wallet.vue")
  },
  {
    name: "personal-wallet___pt",
    path: "/pt/personal/wallet",
    meta: walletZ0QEOx9alnMeta || {},
    component: () => import("/app/apps/site/pages/personal/wallet.vue")
  },
  {
    name: "promo-registration___en",
    path: "/promo/registration",
    meta: registrationiZrL1sJ8iWMeta || {},
    component: () => import("/app/apps/site/pages/promo/registration.vue")
  },
  {
    name: "promo-registration___ru",
    path: "/ru/promo/registration",
    meta: registrationiZrL1sJ8iWMeta || {},
    component: () => import("/app/apps/site/pages/promo/registration.vue")
  },
  {
    name: "promo-registration___es",
    path: "/es/promo/registration",
    meta: registrationiZrL1sJ8iWMeta || {},
    component: () => import("/app/apps/site/pages/promo/registration.vue")
  },
  {
    name: "promo-registration___pt",
    path: "/pt/promo/registration",
    meta: registrationiZrL1sJ8iWMeta || {},
    component: () => import("/app/apps/site/pages/promo/registration.vue")
  },
  {
    name: "pwa___en",
    path: "/pwa",
    meta: pwaPFm19EQAPwMeta || {},
    component: () => import("/app/apps/site/pages/pwa.vue")
  },
  {
    name: "pwa___ru",
    path: "/ru/pwa",
    meta: pwaPFm19EQAPwMeta || {},
    component: () => import("/app/apps/site/pages/pwa.vue")
  },
  {
    name: "pwa___es",
    path: "/es/pwa",
    meta: pwaPFm19EQAPwMeta || {},
    component: () => import("/app/apps/site/pages/pwa.vue")
  },
  {
    name: "pwa___pt",
    path: "/pt/pwa",
    meta: pwaPFm19EQAPwMeta || {},
    component: () => import("/app/apps/site/pages/pwa.vue")
  },
  {
    name: "quests___en",
    path: "/quests",
    component: () => import("/app/apps/site/pages/quests.vue")
  },
  {
    name: "quests___ru",
    path: "/ru/quests",
    component: () => import("/app/apps/site/pages/quests.vue")
  },
  {
    name: "quests___es",
    path: "/es/quests",
    component: () => import("/app/apps/site/pages/quests.vue")
  },
  {
    name: "quests___pt",
    path: "/pt/quests",
    component: () => import("/app/apps/site/pages/quests.vue")
  },
  {
    name: "refer-and-earn___en",
    path: "/refer-and-earn",
    component: () => import("/app/apps/site/pages/refer-and-earn.vue")
  },
  {
    name: "refer-and-earn___ru",
    path: "/ru/refer-and-earn",
    component: () => import("/app/apps/site/pages/refer-and-earn.vue")
  },
  {
    name: "refer-and-earn___es",
    path: "/es/refer-and-earn",
    component: () => import("/app/apps/site/pages/refer-and-earn.vue")
  },
  {
    name: "refer-and-earn___pt",
    path: "/pt/refer-and-earn",
    component: () => import("/app/apps/site/pages/refer-and-earn.vue")
  },
  {
    name: "refer-info___en",
    path: "/refer-info",
    component: () => import("/app/apps/site/pages/refer-info.vue")
  },
  {
    name: "refer-info___ru",
    path: "/ru/refer-info",
    component: () => import("/app/apps/site/pages/refer-info.vue")
  },
  {
    name: "refer-info___es",
    path: "/es/refer-info",
    component: () => import("/app/apps/site/pages/refer-info.vue")
  },
  {
    name: "refer-info___pt",
    path: "/pt/refer-info",
    component: () => import("/app/apps/site/pages/refer-info.vue")
  },
  {
    name: "sport-sportId-categoryId-tournamentId___en",
    path: "/sport/:sportId()/:categoryId()/:tournamentId()",
    component: () => import("/app/apps/site/pages/sport/[sportId]/[categoryId]/[tournamentId]/index.vue")
  },
  {
    name: "sport-sportId-categoryId-tournamentId___ru",
    path: "/ru/sport/:sportId()/:categoryId()/:tournamentId()",
    component: () => import("/app/apps/site/pages/sport/[sportId]/[categoryId]/[tournamentId]/index.vue")
  },
  {
    name: "sport-sportId-categoryId-tournamentId___es",
    path: "/es/sport/:sportId()/:categoryId()/:tournamentId()",
    component: () => import("/app/apps/site/pages/sport/[sportId]/[categoryId]/[tournamentId]/index.vue")
  },
  {
    name: "sport-sportId-categoryId-tournamentId___pt",
    path: "/pt/sport/:sportId()/:categoryId()/:tournamentId()",
    component: () => import("/app/apps/site/pages/sport/[sportId]/[categoryId]/[tournamentId]/index.vue")
  },
  {
    name: "sport-sportId-categoryId-tournamentId-match-eventId___en",
    path: "/sport/:sportId()/:categoryId()/:tournamentId()/match/:eventId()",
    meta: _91eventId_93UYUQdP4EI8Meta || {},
    component: () => import("/app/apps/site/pages/sport/[sportId]/[categoryId]/[tournamentId]/match/[eventId].vue")
  },
  {
    name: "sport-sportId-categoryId-tournamentId-match-eventId___ru",
    path: "/ru/sport/:sportId()/:categoryId()/:tournamentId()/match/:eventId()",
    meta: _91eventId_93UYUQdP4EI8Meta || {},
    component: () => import("/app/apps/site/pages/sport/[sportId]/[categoryId]/[tournamentId]/match/[eventId].vue")
  },
  {
    name: "sport-sportId-categoryId-tournamentId-match-eventId___es",
    path: "/es/sport/:sportId()/:categoryId()/:tournamentId()/match/:eventId()",
    meta: _91eventId_93UYUQdP4EI8Meta || {},
    component: () => import("/app/apps/site/pages/sport/[sportId]/[categoryId]/[tournamentId]/match/[eventId].vue")
  },
  {
    name: "sport-sportId-categoryId-tournamentId-match-eventId___pt",
    path: "/pt/sport/:sportId()/:categoryId()/:tournamentId()/match/:eventId()",
    meta: _91eventId_93UYUQdP4EI8Meta || {},
    component: () => import("/app/apps/site/pages/sport/[sportId]/[categoryId]/[tournamentId]/match/[eventId].vue")
  },
  {
    name: "sport-sportId-categoryId___en",
    path: "/sport/:sportId()/:categoryId()",
    component: () => import("/app/apps/site/pages/sport/[sportId]/[categoryId]/index.vue")
  },
  {
    name: "sport-sportId-categoryId___ru",
    path: "/ru/sport/:sportId()/:categoryId()",
    component: () => import("/app/apps/site/pages/sport/[sportId]/[categoryId]/index.vue")
  },
  {
    name: "sport-sportId-categoryId___es",
    path: "/es/sport/:sportId()/:categoryId()",
    component: () => import("/app/apps/site/pages/sport/[sportId]/[categoryId]/index.vue")
  },
  {
    name: "sport-sportId-categoryId___pt",
    path: "/pt/sport/:sportId()/:categoryId()",
    component: () => import("/app/apps/site/pages/sport/[sportId]/[categoryId]/index.vue")
  },
  {
    name: "sport-sportId___en",
    path: "/sport/:sportId()",
    component: () => import("/app/apps/site/pages/sport/[sportId]/index.vue")
  },
  {
    name: "sport-sportId___ru",
    path: "/ru/sport/:sportId()",
    component: () => import("/app/apps/site/pages/sport/[sportId]/index.vue")
  },
  {
    name: "sport-sportId___es",
    path: "/es/sport/:sportId()",
    component: () => import("/app/apps/site/pages/sport/[sportId]/index.vue")
  },
  {
    name: "sport-sportId___pt",
    path: "/pt/sport/:sportId()",
    component: () => import("/app/apps/site/pages/sport/[sportId]/index.vue")
  },
  {
    name: "sport-all___en",
    path: "/sport/all",
    component: () => import("/app/apps/site/pages/sport/all.vue")
  },
  {
    name: "sport-all___ru",
    path: "/ru/sport/all",
    component: () => import("/app/apps/site/pages/sport/all.vue")
  },
  {
    name: "sport-all___es",
    path: "/es/sport/all",
    component: () => import("/app/apps/site/pages/sport/all.vue")
  },
  {
    name: "sport-all___pt",
    path: "/pt/sport/all",
    component: () => import("/app/apps/site/pages/sport/all.vue")
  },
  {
    name: "sport___en",
    path: "/sport",
    component: () => import("/app/apps/site/pages/sport/index.vue")
  },
  {
    name: "sport___ru",
    path: "/ru/sport",
    component: () => import("/app/apps/site/pages/sport/index.vue")
  },
  {
    name: "sport___es",
    path: "/es/sport",
    component: () => import("/app/apps/site/pages/sport/index.vue")
  },
  {
    name: "sport___pt",
    path: "/pt/sport",
    component: () => import("/app/apps/site/pages/sport/index.vue")
  },
  {
    name: "sport-live___en",
    path: "/sport/live",
    component: () => import("/app/apps/site/pages/sport/live.vue")
  },
  {
    name: "sport-live___ru",
    path: "/ru/sport/live",
    component: () => import("/app/apps/site/pages/sport/live.vue")
  },
  {
    name: "sport-live___es",
    path: "/es/sport/live",
    component: () => import("/app/apps/site/pages/sport/live.vue")
  },
  {
    name: "sport-live___pt",
    path: "/pt/sport/live",
    component: () => import("/app/apps/site/pages/sport/live.vue")
  },
  {
    name: "sport-upcoming___en",
    path: "/sport/upcoming",
    component: () => import("/app/apps/site/pages/sport/upcoming.vue")
  },
  {
    name: "sport-upcoming___ru",
    path: "/ru/sport/upcoming",
    component: () => import("/app/apps/site/pages/sport/upcoming.vue")
  },
  {
    name: "sport-upcoming___es",
    path: "/es/sport/upcoming",
    component: () => import("/app/apps/site/pages/sport/upcoming.vue")
  },
  {
    name: "sport-upcoming___pt",
    path: "/pt/sport/upcoming",
    component: () => import("/app/apps/site/pages/sport/upcoming.vue")
  },
  {
    name: "support___en",
    path: "/support",
    component: () => import("/app/apps/site/pages/support.vue")
  },
  {
    name: "support___ru",
    path: "/ru/support",
    component: () => import("/app/apps/site/pages/support.vue")
  },
  {
    name: "support___es",
    path: "/es/support",
    component: () => import("/app/apps/site/pages/support.vue")
  },
  {
    name: "support___pt",
    path: "/pt/support",
    component: () => import("/app/apps/site/pages/support.vue")
  },
  {
    name: "tournaments-id___en",
    path: "/tournaments/:id()",
    component: () => import("/app/apps/site/pages/tournaments/[id].vue")
  },
  {
    name: "tournaments-id___ru",
    path: "/ru/tournaments/:id()",
    component: () => import("/app/apps/site/pages/tournaments/[id].vue")
  },
  {
    name: "tournaments-id___es",
    path: "/es/tournaments/:id()",
    component: () => import("/app/apps/site/pages/tournaments/[id].vue")
  },
  {
    name: "tournaments-id___pt",
    path: "/pt/tournaments/:id()",
    component: () => import("/app/apps/site/pages/tournaments/[id].vue")
  },
  {
    name: "tournaments___en",
    path: "/tournaments",
    component: () => import("/app/apps/site/pages/tournaments/index.vue")
  },
  {
    name: "tournaments___ru",
    path: "/ru/tournaments",
    component: () => import("/app/apps/site/pages/tournaments/index.vue")
  },
  {
    name: "tournaments___es",
    path: "/es/tournaments",
    component: () => import("/app/apps/site/pages/tournaments/index.vue")
  },
  {
    name: "tournaments___pt",
    path: "/pt/tournaments",
    component: () => import("/app/apps/site/pages/tournaments/index.vue")
  },
  {
    name: "unsubscribe___en",
    path: "/unsubscribe",
    meta: unsubscribe67u1rkBt9DMeta || {},
    component: () => import("/app/apps/site/pages/unsubscribe.vue")
  },
  {
    name: "unsubscribe___ru",
    path: "/ru/unsubscribe",
    meta: unsubscribe67u1rkBt9DMeta || {},
    component: () => import("/app/apps/site/pages/unsubscribe.vue")
  },
  {
    name: "unsubscribe___es",
    path: "/es/unsubscribe",
    meta: unsubscribe67u1rkBt9DMeta || {},
    component: () => import("/app/apps/site/pages/unsubscribe.vue")
  },
  {
    name: "unsubscribe___pt",
    path: "/pt/unsubscribe",
    meta: unsubscribe67u1rkBt9DMeta || {},
    component: () => import("/app/apps/site/pages/unsubscribe.vue")
  }
]