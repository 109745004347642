<template>
  <div
    v-if="isBonusTimerVisible"
    class="bonus-timer"
    :class="direction"
    data-t="bonus-timer"
  >
    <span v-if="isExpired" class="timer-title">
      {{ t('bonuses.timeExpired') }}
    </span>
    <template v-else>
      <span v-if="label" class="timer-title" data-t="timer-tilte">
        {{ label }}
      </span>
      <div v-if="withDays" class="timer-countdown full">
        <template v-if="isShownDaysLabel">
          <span class="time-part day" data-t="days-timer-part">
            {{ t('bonuses.daysShort', countdownInDays) }}
          </span>
          <span class="colon"> : </span>
        </template>

        <span class="time-part">{{ countdownInUnits.hours }}</span>
        <span class="colon"> : </span>
        <span class="time-part">{{ countdownInUnits.minutes }}</span>
        <span class="colon"> : </span>
        <span class="time-part">{{ countdownInUnits.seconds }}</span>
      </div>
      <span v-else class="timer-countdown" data-t="timer-countdown">
        {{ countdown }}
      </span>
    </template>
  </div>
</template>

<script setup lang="ts">
interface Props {
  expiresAt: string
  label?: string
  direction?: 'row' | 'column'
  withDays?: boolean
  maxDays?: number
}
const {
  expiresAt,
  label,
  direction = 'column',
  withDays = false,
  maxDays = 0,
} = defineProps<Props>()

const { t } = useI18n()

const { countdown, countdownInUnits, countdownInDays, isExpired } =
  useCountdownV2(computed(() => expiresAt ?? ''))
const isShownDaysLabel = computed(() => countdownInDays.value > 0)
const isBonusTimerVisible = computed(
  () => !maxDays || maxDays >= countdownInDays.value,
)
</script>

<style scoped>
.timer-title {
  font: var(--mobile-caption-1-regular);
  color: var(--text-secondary);
}

.timer-countdown {
  font: var(--mobile-caption-1-regular);
  color: var(--text-primary);

  &.full {
    display: flex;
    align-items: center;

    .colon {
      width: 4px;
      text-align: center;
      opacity: 0.48;
    }

    .time-part {
      width: 15px;
      text-align: center;

      &.day {
        width: auto;
      }
    }
  }
}

.bonus-timer {
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  gap: var(--spacing-025);
  align-items: center;
  justify-content: center;

  min-width: 0;
  min-height: 40px;
  /* stylelint-disable-next-line */
  padding: 5px var(--spacing-125);

  &.row {
    flex-direction: row;
    gap: var(--spacing-050);
    justify-content: flex-end;

    min-height: auto;
    padding: 0;

    .timer-countdown {
      text-align: right;
    }
  }
}
</style>
