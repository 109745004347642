<template>
  <div class="exchange-status" :class="platform" data-t="exchange-status">
    <div class="content">
      <div class="main-section">
        <div class="status-icon-wrapper">
          <StIcon
            :name="status === 'success' ? 'check' : 'cross-large'"
            :size="platform === 'desktop' ? 48 : 40"
            class="status-icon"
          />
          <div class="provider">
            <img v-if="provider === 'moonpay'" src="./assets/moonpay.svg" />
            <img v-if="provider === 'calypso'" src="./assets/calypso.svg" />
          </div>
        </div>
        <div class="information">
          <div class="title">
            {{
              status === 'success'
                ? t('payments.deposit.exchangeSuccessStatusTitle')
                : t('payments.deposit.exchangeErrorStatusTitle')
            }}
          </div>
          <div class="subtitle">
            {{
              status === 'success'
                ? t('payments.deposit.exchangeErrorStatusSubtitle')
                : t('payments.deposit.exchangeErrorStatusSubtitle')
            }}
          </div>
        </div>
        <div v-if="status === 'success'" class="amount-section">
          <div class="amount">+{{ amount }}</div>
          <StIcon
            :name="currencyIcon"
            :size="platform === 'desktop' ? 40 : 28"
          />
        </div>
      </div>
      <StButton
        :label="
          status === 'success'
            ? t('payments.deposit.exchangeSuccessButton')
            : t('payments.deposit.exchangeErrorButton')
        "
        :size="platform === 'desktop' ? 'xl' : 'l'"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IconName } from '@st/ui/components/StIcon/types'
import type { ExchangePaymentProvider } from '../../interfaces'

const props = defineProps<{
  amount: string
  status: 'success' | 'error'
  currency: string
  provider: ExchangePaymentProvider
}>()

const { platform } = usePlatform()
const { t } = useI18n()

const currencyIcon = computed(
  () => `c-crypto-icon-${props.currency?.toLowerCase()}` as IconName,
)

const statusIconWrapperColor = computed(() =>
  props.status === 'success'
    ? 'rgba(83, 198, 107, 0.16)'
    : 'rgba(255, 39, 43, 0.16)',
)

const statusIconColor = computed(() =>
  props.status === 'success' ? 'var(--icon-success)' : 'var(--icon-error)',
)
</script>

<style scoped>
.exchange-status {
  display: flex;
  width: 596px;
  max-height: 476px;
  padding: var(--spacing-300);
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-300);
  justify-content: space-between;

  width: 100%;
  height: 100%;
}

.main-section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);
  align-items: center;
  justify-content: space-between;

  padding: var(--spacing-800) 0 var(--spacing-600);
}

.status-icon-wrapper {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 92px;
  height: 92px;

  background: v-bind(statusIconWrapperColor);
  border-radius: var(--border-radius-full, 999px);
}

.status-icon {
  color: v-bind(statusIconColor);
}

.provider {
  position: absolute;
  right: -6px;
  bottom: -8px;
}

.information {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.title {
  font: var(--desktop-text-2lg-semibold);
}

.subtitle {
  font: var(--desktop-text-md-medium);
  color: var(--text-secondary);
}

.amount-section {
  display: flex;
  gap: var(--spacing-100);
  align-items: center;
}

.amount {
  font: var(--desktop-text-4xl-semibold);
}

.exchange-status.mobile {
  width: auto;
  height: auto;
  padding: var(--spacing-600) var(--spacing-200) var(--spacing-200);

  .main-section {
    gap: var(--spacing-300);
    padding: 0;
  }

  .status-icon-wrapper {
    width: 80px;
    height: 80px;
  }

  .information {
    gap: var(--spacing-025);
  }

  .title {
    font: var(--mobile-title-2-semibold);
  }

  .subtitle {
    font: var(--mobile-text-regular);
  }

  .amount {
    font: var(--desktop-text-3xl-semibold);
  }
}
</style>
