<template>
  <section class="mobile-layout">
    <slot />
  </section>
</template>

<script setup lang="ts">
import type { MobileLayoutAPI } from './types'

const isOpenedMenu = ref(false)
function closeMenu() {
  isOpenedMenu.value = false
}
function openMenu() {
  isOpenedMenu.value = true
}

const BASE_COLOR = '#111118'
const headerColor = ref(BASE_COLOR)
function setHeaderColor(color = BASE_COLOR) {
  headerColor.value = color
}

const isDefaultHeaderColor = computed(() => headerColor.value === BASE_COLOR)

/*
  переменная, которая хранит размер в pх
  на который уменьшился контент, для использования в компонентах
  которые следует смещать на это расстояние вверх через translateY
*/
const layoutTranslateY = ref('0px')
function setLayoutTranslateY(px: string) {
  layoutTranslateY.value = px
}

function scrollToTop(top = 0) {
  nextTick(() => {
    window.scrollTo({
      top,
      left: 0,
      behavior: 'smooth',
    })
  })
}

provide<MobileLayoutAPI>('MobileLayoutAPI', {
  closeMenu,
  openMenu,
  isOpenedMenu,
  headerColor,
  setHeaderColor,
  isDefaultHeaderColor,
  layoutTranslateY,
  setLayoutTranslateY,
  scrollToTop,
})

const { t } = useI18n()
useHead({
  title: t('pages.main'),
  meta: [
    { name: 'theme-color', content: headerColor },
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1, user-scalable=no',
    },
  ],
})
</script>
