<template>
  <div
    class="second-step"
    data-t="second-step-form"
    :class="{ mobile: isMobile }"
  >
    <div class="content">
      <h2 class="title">
        {{ t('changeEmail.approveEmailChange') }}
      </h2>
      <p class="instructions">
        <I18nT keypath="changeEmail.newEmailText">
          <template #email>
            <b class="email">
              {{ props.firstStepData.email }}
            </b>
          </template>
        </I18nT>
      </p>
      <St2FA
        ref="st2fa"
        :is-code-valid="isCodeValid"
        :error="error"
        :platform="isMobile ? 'mobile' : 'desktop'"
        button-type="text-secondary"
        no-access-button-type="text-primary"
        @input-filled="inputFilled"
        @resend-code-clicked="resendCode"
      />
    </div>

    <div class="go-back-button">
      <StButton
        :label="t('changeEmail.back')"
        size="l"
        type="text-only"
        icon="arrow-left"
        is-left-icon
        @click="emit('back')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import St2FA from '@st/core/components/St2Fa/St2Fa.vue'
import { delay } from '@st/utils'

const { t, locale } = useI18n()
const { isMobile } = usePlatform()
const { getToken, recaptchaQueryParam } = useRecaptcha()
const code = ref('')
const st2fa = useTemplateRef<InstanceType<typeof St2FA>>('st2fa')
const isCodeValid = ref(false)

const props = defineProps<{
  firstStepData: {
    email: string
    emailOld: string
    password: string
    isCodeSent?: boolean
  }
}>()

onActivated(() => {
  if (props.firstStepData.isCodeSent) {
    st2fa.value?.restartTimer()
  }
})

const emit = defineEmits<{
  back: []
  success: []
}>()

const { execute: executeResendCode } = useStFetch('/user/email/set/code/send', {
  method: 'post',
  body: computed(() => {
    const { email, password, emailOld } = props.firstStepData
    const language = locale.value as any

    return {
      email,
      password,
      language,
      emailOld,
    }
  }),
  immediate: false,
  watch: false,
  query: recaptchaQueryParam,
})

async function resendCode() {
  await getToken('/user/email/set/code/send')
  await executeResendCode()
}

const {
  execute,
  error,
  data: userData,
} = useStFetch('/user/email/set', {
  method: 'post',
  body: computed(() => {
    const { email, password, emailOld } = props.firstStepData
    const language = locale.value as any

    return {
      email,
      emailOld,
      password,
      language,
      confirmationCode: code.value,
    }
  }),
  immediate: false,
  watch: false,
})

async function sendRequest() {
  await execute()
  if (userData.value) {
    isCodeValid.value = true
    await delay(1500)
    emit('success')
  }
}

function inputFilled(value: string) {
  code.value = value
  sendRequest()
}
</script>

<style scoped>
.title {
  margin: 0;
  padding: 0;
  font: var(--desktop-text-xl-semibold);
}

.content {
  flex-grow: 1;
}

.instructions {
  margin: var(--spacing-100) 0 var(--spacing-300);
  font: var(--desktop-text-sm-medium);
  color: var(--palette-light-600);

  b {
    font: var(--desktop-text-sm-semibold);
    color: var(--text-primary);
  }
}

.go-back-button {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.second-step {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--spacing-300);

  &.mobile {
    gap: var(--spacing-100);

    .instructions {
      margin: 0 0 var(--spacing-100);
      font: var(--mobile-text-content-regular);

      b {
        font: var(--mobile-text-content-regular);
        color: var(--text-primary);
      }
    }

    .title {
      margin: var(--spacing-200) 0 var(--spacing-050);
      font: var(--mobile-title-2-semibold);
    }
  }
}
</style>
