<template>
  <section :class="{ mobile }" class="last-bets" data-t="last-bets">
    <div class="title-wrapper">
      <h2 class="title" :class="`size-${headerSize}`">
        <StIcon
          v-if="withHeaderIcon"
          class="bet-icon"
          name="bet"
          :size="iconSize"
        />
        {{ t('lastBets.lastBets') }}
      </h2>
      <StTabs
        v-if="tabs.length > 1"
        v-model="selectedTab"
        class="last-tabs"
        type="separate"
        :tabs="tabs"
      />
    </div>
    <LastBetsCasinoTab v-if="selectedTab === 'casino'" :mobile="mobile" />
    <LastBetsSportTab v-else-if="selectedTab === 'sport'" :mobile="mobile" />
    <LastBigBetsCasinoTab
      v-else-if="selectedTab === 'big-bets-casino'"
      :mobile="mobile"
    />
    <LastBigBetsSportTab
      v-else-if="selectedTab === 'big-bets-sport'"
      :mobile="mobile"
    />
  </section>
</template>

<script setup lang="ts">
import type { Tab } from '@st/ui/components/StTabs/types'
import LastBetsCasinoTab from './parts/tabs/LastBetsCasinoTab.vue'
import LastBetsSportTab from './parts/tabs/LastBetsSportTab.vue'
import LastBigBetsCasinoTab from './parts/tabs/LastBigBetsCasinoTab.vue'
import LastBigBetsSportTab from './parts/tabs/LastBigBetsSportTab.vue'

const { t } = useI18n()

const props = withDefaults(
  defineProps<{
    tabs: Tab[]
    headerSize?: 'lg' | '2xl' | 'mobile' | 'mobile-large'
    mobile?: boolean
    withHeaderIcon?: boolean
  }>(),
  {
    headerSize: 'lg',
    mobile: false,
    withHeaderIcon: true,
  },
)
const selectedTab = ref(props.tabs[0].id)

const iconSize = computed(() => (props.mobile ? 20 : 24))
</script>

<style scoped>
.title {
  display: flex;
  gap: var(--spacing-150);
  align-items: center;
  margin: 0;

  &.size-lg {
    gap: var(--spacing-100);
    font: var(--desktop-text-lg-semibold);
  }

  &.size-2xl {
    font: var(--desktop-text-2xl-semibold);
  }

  &.size-mobile {
    gap: var(--spacing-075);
    font: var(--mobile-title-2-semibold);
  }

  &.size-mobile-large {
    font: var(--mobile-title-large);
  }
}

.title-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-150);
}

.mobile .title-wrapper {
  flex-direction: column;
  gap: var(--spacing-125);
}

.mobile.last-bets {
  padding: var(--spacing-200);
  padding-top: var(--spacing-250);
}

.bet-icon {
  background-color: var(--icon-secondary);
}

.last-tabs {
  overflow: auto;
}
</style>
