<template>
  <div class="sidebar-item-wrapper" :class="modifierClasses">
    <NuxtI18nLink
      class="sidebar-item"
      :to="link ?? '/'"
      :class="modifierClasses"
      :data-t="dataT"
      :target="isOpenNewPage"
      @click="isActive = !isActive"
    >
      <div v-if="icon" class="left">
        <StIcon
          :name="icon"
          :size="iconSize"
          class="left-icon"
          data-t="st-sidebar-item__icon"
          :class="modifierClasses"
        />
      </div>
      <div v-else-if="iconRaw" class="left">
        <StIcon
          :svg="iconRaw"
          :size="iconSize"
          class="left-icon"
          data-t="st-sidebar-item__icon"
          :class="modifierClasses"
        />
      </div>
      <img v-if="image" class="image" :src="image" alt="icon" />
      <div
        class="label"
        :class="modifierClasses"
        data-t="st-sidebar-item__label"
      >
        {{ formattedLabel }}
      </div>
      <StCounter
        v-if="counter"
        size="xxs"
        :bg-color="counterColor"
        :color="counterCustomColor"
        class="right"
        data-t="st-sidebar-item__counter"
      >
        {{ counter }}
      </StCounter>
      <ChevronButton
        v-else-if="withChevron"
        :is-active="isActive"
        class="right button-chevron-down"
        data-t="st-sidebar-item__chevron-button"
      />
      <StLabel
        v-else-if="badge"
        :label="badge"
        size="xs"
        bg-color="var(--border-primary)"
        class="right"
      />
      <slot name="right" />
    </NuxtI18nLink>
    <StTransitionExpand v-if="canExpand">
      <div v-if="isActive">
        <slot />
      </div>
    </StTransitionExpand>
  </div>
</template>

<script setup lang="ts">
import type { Ref } from 'vue'
import type { RouteLocationRaw } from 'vue-router'
import type { IconName } from '../StIcon/types'
import ChevronButton from './parts/ChevronButton.vue'

const isCompact = inject<Ref<boolean>>('isCompact')

const props = withDefaults(
  defineProps<{
    level: number
    label: string
    icon?: IconName
    iconRaw?: string | null
    image?: string
    counter?: number
    withChevron?: boolean
    modelValue?: boolean
    badge?: string
    link?: RouteLocationRaw
    dataT?: string
    ignoreActiveState?: boolean
    targetBlank?: boolean
    counterCustomBgColor?:
      | 'white'
      | 'orange'
      | 'transparent'
      | 'black'
      | 'ghost'
    counterCustomColor?: 'orange' | 'white' | 'black'
  }>(),
  {
    dataT: 'sidebar-item',
    ignoreActiveState: false,
    targetBlank: false,
  },
)
const isOpenNewPage = computed(() => (props.targetBlank ? '_blank' : ''))

const formattedLabel = computed(() =>
  isCompact?.value && props.level === 4 ? props.label.slice(0, 1) : props.label,
)

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
}>()

const route = useRoute()

const isActive = computed<boolean>({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
})

const canExpand = computed(() => props.level < 4)

const slots = useSlots()
const localizeRoute = useLocaleRoute()

const hasActiveClass = computed(() => {
  const isActiveRoute = Boolean(
    !props.ignoreActiveState &&
      props.link &&
      localizeRoute(props.link)?.path === route.path,
  )

  return slots.default ? props.modelValue : isActiveRoute
})

const modifierClasses = computed(() => [
  `level-${props.level}`,
  {
    active: hasActiveClass.value,
    compact: isCompact?.value,
  },
])

const counterColor = computed(() => {
  if (props.counterCustomBgColor) return props.counterCustomBgColor
  const colorsByLevel = {
    0: 'orange',
    1: 'orange',
    2: 'orange',
    3: 'black',
    4: 'transparent',
  } as const
  return colorsByLevel[props.level as keyof typeof colorsByLevel]
})

const iconSize = computed(() => {
  const iconSizeByLevel = {
    0: 20,
    1: 20,
    2: 20,
    3: 16,
    4: 0,
  } as const
  return iconSizeByLevel[props.level as keyof typeof iconSizeByLevel]
})
</script>

<style scoped>
.sidebar-item-wrapper {
  border-radius: var(--border-radius-075);

  &.level-0 {
    border-radius: var(--border-radius-100);
  }

  &.level-0.active {
    background-color: var(--background-secondary);
  }

  &.level-1 {
    border-radius: var(--border-radius-100);
  }

  &.level-1.active {
    background-color: var(--background-base);
  }

  &.level-2.active {
    background-color: var(--background-primary);
  }

  &.level-3.active {
    background-color: var(--background-tertiary);
  }

  &.level-4.active {
    background-color: transparent;
  }
}

.sidebar-item {
  cursor: pointer;

  display: flex;
  align-items: center;

  max-width: 100%;
  height: var(--spacing-500);
  padding-inline: var(--spacing-150);

  font: var(--desktop-text-sm-medium);
  color: var(--text-primary);
  text-decoration: none;

  border-radius: var(--border-radius-100);

  &.level-0 {
    background-color: transparent;

    &:hover {
      background-color: var(--background-secondary);
    }

    &.active {
      &:hover {
        background-color: var(--background-tertiary);
      }
    }

    &.compact {
      margin-inline: 0;
      padding-inline: var(--spacing-150);
    }
  }

  &.level-1 {
    background-color: transparent;
    border-radius: var(--border-radius-100);

    &:hover {
      background-color: var(--background-secondary);
    }

    &.active {
      height: var(--spacing-450);
      margin: var(--spacing-025);
      padding-inline: var(--spacing-125);
      border-radius: var(--border-radius-075);

      &:hover {
        background-color: var(--background-primary);
      }
    }

    &.compact {
      margin-inline: 0;
      padding-inline: var(--spacing-150);
    }
  }

  &.level-2 {
    padding-inline: var(--spacing-125);
    background-color: transparent;
    border-radius: var(--border-radius-075);

    &:hover {
      background-color: var(--background-primary);
    }

    &.active {
      height: var(--spacing-450);
      margin: var(--spacing-025);
      padding-inline: var(--spacing-100);

      background-color: var(--background-primary);
      border-radius: var(--border-radius-050);

      &:hover {
        background-color: var(--background-secondary);
      }
    }
  }

  &.level-3 {
    margin-inline: var(--spacing-025);
    padding-inline: var(--spacing-100);
    background-color: transparent;
    border-radius: var(--border-radius-050);

    &:hover {
      background-color: var(--background-tertiary);
    }

    &.active {
      height: var(--spacing-450);
      margin: var(--spacing-025);
      padding-inline: var(--spacing-100);
      background-color: var(--background-tertiary);

      &:hover {
        /* stylelint-disable-next-line */
        background-color: #525265;
      }
    }
  }

  &.level-4 {
    height: var(--spacing-350);
    padding-inline: var(--spacing-100);

    font: var(--desktop-text-xs-medium);

    background-color: transparent;
    border-radius: var(--border-radius-050);

    &:hover {
      color: var(--text-link);
    }

    &.active {
      padding-inline: var(--spacing-100);
      color: var(--text-link);

      /* stylelint-disable-next-line */
      background-color: #4b4b59;

      &:hover {
        /* stylelint-disable-next-line */
        background-color: #525265;
      }
    }
  }

  & .image {
    width: 20px;
    height: 20px;
  }

  &.compact {
    .image {
      /* stylelint-disable-next-line */
      margin-left: -2px;
    }
  }
}

.left {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  width: 20px;
}

.left-icon {
  color: var(--text-secondary);

  &.compact {
    /* stylelint-disable-next-line */
    margin-left: -2px;
  }
}

.label {
  overflow: hidden;
  flex-grow: 1;

  margin-inline: var(--spacing-125);

  white-space: nowrap;

  transition: opacity 0.2s;

  mask-image: linear-gradient(to right, black 85%, transparent 100%);

  &.compact:not(.level-4) {
    font-size: 0;
  }

  &.level-4 {
    transition: transform 0.2s;

    /* stylelint-disable-next-line */
    margin: 0 var(--spacing-125) 0 30px;
  }

  &.level-4.compact {
    transform: translateX(-24px);

    flex-shrink: 0;

    opacity: 1;

    transition: transform 0.2s;

    mask-image: none;
  }
}

.right {
  box-shadow: none;
}

.button-chevron-down {
  box-shadow: var(--shadows-shadow-l);
}
</style>
