<template>
  <div class="second-step" :class="{ mobile: props.isMobile }">
    <h2 v-if="!props.isMobile" class="title">
      {{ t('authorization.secondStepTitle') }}
    </h2>
    <MFormHeader
      v-else
      class="second-step-header"
      with-back-button
      @back="emit('back')"
    >
      <p class="header-text">{{ t('authorization.secondStepTitle') }}</p>
    </MFormHeader>
    <p class="instructions">
      {{ t('authorization.confirmEmailInstructions') }}
      <b class="email">
        {{ props.data.email }}
      </b>
    </p>
    <St2FA
      :is-code-valid="isCodeValid"
      :error="loginError"
      :platform="props.isMobile ? 'mobile' : 'desktop'"
      @input-filled="inputFilled"
      @resend-code-clicked="resendCode"
    />
    <div v-if="!props.isMobile" class="go-back-button">
      <StButton
        :label="t('authorization.secondStepGoBack')"
        size="l"
        type="text-only"
        icon="arrow-left"
        is-left-icon
        @click="$emit('back')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { delay } from '@st/utils'
import St2FA from '@st/core/components/St2Fa/St2Fa.vue'
import MFormHeader from '../../parts/MFormHeader.vue'
import { useEmailLogin } from '../../../composables/useEmailLogin'

const { t } = useI18n()

const props = defineProps<{
  data: {
    email: string
    password: string
  }
  isMobile?: boolean
}>()

const emit = defineEmits<{
  (e: 'success'): void
  (e: 'back'): void
}>()

const { getToken, recaptchaQueryParam } = useRecaptcha()
const { execute: executeResendCode } = useStFetch(
  '/auth/login/email/code/send',
  {
    method: 'post',
    body: props.data,
    immediate: false,
    watch: false,
    query: recaptchaQueryParam,
  },
)

async function resendCode() {
  await getToken('/auth/login/email/code/send')
  await executeResendCode()
}

const code = ref('')
const requestBody = computed(() => {
  const { email, password } = props.data

  return {
    email,
    password,
    code: code.value,
  }
})
const { loginError, userData, handleUserLogin } = useEmailLogin(requestBody)

const isCodeValid = ref(false)
async function goToNextStep() {
  await handleUserLogin()
  if (userData.value) {
    isCodeValid.value = true
    /**
     * Чисто дизайнерская затея чтобы юзер успел понять что все успешно
     * Технического смысла в ожидании нет
     */
    await delay(1500)
    emit('success')
  }
}

function inputFilled(value: string) {
  code.value = value

  goToNextStep()
}
</script>

<style scoped>
button {
  border: 0;
}

.title {
  margin: 0;
  font: var(--desktop-text-xl-semibold);
}

.instructions {
  margin: 0;
  margin-bottom: var(--spacing-100);
  font: var(--desktop-text-sm-medium);
  color: var(--text-secondary);
}

.email {
  color: var(--palette-light-1000);
}

.go-back-button {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
}

.second-step {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--spacing-100);

  &.mobile {
    .instructions {
      margin-top: var(--spacing-100);
      margin-bottom: 0;
      font: var(--mobile-text-content-regular);
    }
  }
}

.second-step-header {
  margin-right: calc(var(--spacing-200) * -1);
  margin-left: calc(var(--spacing-200) * -1);
}

.header-text {
  margin: 0;
  padding: var(--spacing-100) 0;
  font: var(--mobile-title-2-semibold);
}
</style>
