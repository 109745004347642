<template>
  <section class="top-banners">
    <template v-if="isLoading || !isMounted">
      <StSkeletonLoader
        class="hero-banner"
        bg-color="var(--background-primary)"
        height="auto"
        :shimmer-opacity="0.25"
        radius="var(--border-radius-150)"
      />
      <div class="banners">
        <StCarousel :items-to-show="3" :items-to-scroll="1">
          <StCarouselItem v-for="i in 5" :key="i">
            <StSkeletonLoader
              class="banner"
              bg-color="var(--background-primary)"
              height="auto"
              :shimmer-opacity="0.25"
              radius="var(--border-radius-150)"
            />
          </StCarouselItem>
        </StCarousel>
      </div>
    </template>
    <template v-else>
      <article
        v-if="!isAuthenticated && primaryNotAuthenticatedBanner"
        class="hero-banner"
        :style="`background-image: url(${primaryNotAuthenticatedBanner.publicUploadUrl})`"
      >
        <AuthProviders />
      </article>
      <ClientOnly v-if="isAuthenticated && primaryAuthenticatedBanner">
        <NuxtI18nLink
          :to="primaryAuthenticatedBanner.url"
          class="hero-banner"
          :style="`background-image: url(${primaryAuthenticatedBanner.publicUploadUrl})`"
        >
          <StButton
            class="hero-banner-btn"
            type="ghost"
            :label="t('bonuses.goToBonuses')"
            icon="chevron-right"
            size="xl"
          />
        </NuxtI18nLink>
      </ClientOnly>
      <div class="banners">
        <StCarousel :items-to-show="3" :items-to-scroll="1">
          <StCarouselItem
            v-for="(banner, index) in cycledSecondaryBanners"
            :key="index"
          >
            <NuxtI18nLink
              class="banner"
              :to="banner.url"
              :style="`background-image: url(${banner.publicUploadUrl})`"
            />
          </StCarouselItem>
        </StCarousel>
      </div>
    </template>
  </section>
</template>

<script setup lang="ts">
import AuthProviders from '@st/user/components/AuthProviders/AuthProviders.vue'
import { useUserStore } from '@st/user/stores/useUserStore'
import { useBanners } from '@st/core/composables/useBanners'
import { useIsOnMounted } from '@st/use/composables'

const { isAuthenticated } = storeToRefs(useUserStore())
const { t } = useI18n()

const {
  banners: primaryAuthenticatedBanners,
  isLoading: isLoadingPrimaryAuthBanner,
} = useBanners('desktopHomePrimaryAuthenticated')
const {
  banners: primaryNotAuthenticatedBanners,
  isLoading: isLoadingPrimaryNotAuthBanner,
} = useBanners('desktopHomePrimaryNotAuthenticated')

const primaryAuthenticatedBanner = computed(
  () => primaryAuthenticatedBanners.value[0],
)
const primaryNotAuthenticatedBanner = computed(
  () => primaryNotAuthenticatedBanners.value[0],
)

const { banners: secondaryBanners, isLoading: isLoadingSecondaryBanners } =
  useBanners('desktopHomeSecondary')

const isLoading = computed(
  () =>
    isLoadingSecondaryBanners.value ||
    isLoadingPrimaryAuthBanner.value ||
    isLoadingPrimaryNotAuthBanner.value,
)

const cycledSecondaryBanners = computed(() => {
  const n = secondaryBanners.value.length
  return [...secondaryBanners.value, ...secondaryBanners.value.slice(0, n - 1)]
})
const { isMounted } = useIsOnMounted()
</script>

<style scoped>
.top-banners {
  overflow: hidden;
  display: flex;
  gap: var(--spacing-200);
}

.hero-banner {
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  justify-content: flex-end;

  aspect-ratio: 632/300;
  min-width: 0;
  padding: var(--spacing-300);

  text-decoration: none;

  background-size: cover;
  border-radius: var(--border-radius-150);
}

.hero-banner-btn {
  align-self: flex-start;
}

.banners {
  flex: 1 1 0;
  min-width: 0;
  margin: 0 calc(var(--spacing-100) * -1);
}

.banner {
  cursor: pointer;

  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  justify-content: flex-end;

  aspect-ratio: 10/13;
  padding: var(--spacing-200) var(--spacing-100);

  text-decoration: none;

  background-color: var(--background-primary);
  background-size: cover;
  border-radius: var(--border-radius-150);
}
</style>
