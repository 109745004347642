import type { IconName } from '@st/ui/components/StIcon/types'
import type { Notification } from '../types'
import { useNotificationsStore } from '../stores/useNotificationsStore'
import { useBonusNotification } from './useBonusNotification'
import { useAccountTransactionNotification } from './useAccountTransactionNotification'

export function useNotification(notification: Ref<Notification>) {
  const { t } = useI18n()
  const { parseDate } = useDate()

  const { bonusTitle, bonusIcon } = useBonusNotification(notification)
  const { useAccountIcon } = useAccountTransactionNotification(notification)

  const time = computed(() => {
    const createdAt = parseDate(notification.value.createdAt)
    const now = parseDate().subtract(5, 'minute')
    const lessThenHour = parseDate().subtract(1, 'hour')
    const yesterday = parseDate().subtract(1, 'days')

    if (createdAt.isAfter(now, 'minute')) {
      return t('notifications.now')
    }
    if (createdAt.isAfter(lessThenHour, 'hour')) {
      return t('notifications.minutesAgo', {
        count: parseDate().subtract(createdAt.minute(), 'minute').minute(),
      })
    }
    if (createdAt.isSame(new Date(), 'day')) {
      return t('notifications.hoursAgo', {
        count: parseDate().subtract(createdAt.hour(), 'hour').hour(),
      })
    }
    if (createdAt.isSame(yesterday, 'day')) {
      return t('notifications.yesterday', [createdAt.format('hh:mm')])
    }
    return createdAt.format('DD.MM.YY, hh:mm')
  })
  const notificationGroupType = computed(() => {
    if (notification.value.type.startsWith('deposit')) return 'deposit'
    if (notification.value.type.startsWith('withdrawal')) return 'withdrawal'
    if (notification.value.type.includes('Ordinary')) return 'ordinaryBet'
    if (notification.value.type.includes('Express')) return 'expressBet'
    if (notification.value.type.includes('Verification')) return 'verification'
    if (notification.value.type.includes('bonus')) return 'bonus'
    if (notification.value.type.includes('Freespin')) return 'freespin'
    if (notification.value.type.includes('freebet')) return 'freespin'
    if (notification.value.type.includes('admin')) return 'support'
    return ''
  })

  const classes = computed(() => [
    { active: !notification.value.read },
    notificationGroupType.value,
  ])
  const notificationIcon = computed<IconName>(() => {
    if (bonusIcon.value) return bonusIcon.value
    if (useAccountIcon.value) return useAccountIcon.value

    if (notification.value.type === 'userAuthProviderCreated') {
      return {
        telegram: 'telegram-solid' as const,
      }[notification.value.data.provider]
    }

    switch (notificationGroupType.value) {
      case 'deposit':
        return 'arrow-down-left'
      case 'withdrawal':
        return 'arrow-up-right'
      case 'ordinaryBet':
        return 'cup-solid'
      case 'expressBet':
        return 'cup-solid'
      case 'verification':
        return 'id-card-clip-solid'
      case 'freespin':
        return 'freebet'
      default:
        return 'support-solid'
    }
  })

  const subjectMessage = computed(() => {
    if (notification.value.type === 'userAuthProviderCreated') {
      const provider = {
        telegram: 'Telegram',
      }[notification.value.data.provider]
      return t('notifications.subject.userAuthProviderCreated', {
        provider,
      })
    }
    /* 
      у accountTransactionCreated заголовок сопоставляется
      по полю operationType внутри data
    */
    if (notification.value.type === 'accountTransactionCreated') {
      return {
        referralAccrual: t('dashboard.referalAccrual'),
        freebetAccrual: t('notifications.subject.freebetAccrual'),
        freebetProcessAccrual: t('notifications.subject.freebetWinAccrual'),
        freebetWinAccrual: t('notifications.subject.freebetWinAccrual', {
          amount: notification.value.data.amount,
        }),
        freespinRewardAccrual: t('notifications.subject.freespinsWinAccrual'),
        carrotAccrual: t('notifications.subject.questRewardAccrual'),
      }[notification.value.data.operationType]
    }

    /* 
      заголовок подбирается по полю type нотификации
    */
    return (
      {
        freebetWinAccrual: t('notifications.subject.freebetWinAccrual'),
        depositCreated: t('notifications.subject.depositCreated'),
        depositProcessed: t('notifications.subject.depositProcessed'),
        withdrawalProcessed: t('notifications.subject.withdrawalProcessed'),
        betProcessedWinOrdinary: t(
          'notifications.subject.betProcessedWinOrdinary',
        ),
        betProcessedLossOrdinary: t(
          'notifications.subject.betProcessedLossOrdinary',
        ),
        betUnacceptedOrdinary: t('notifications.subject.betUnacceptedOrdinary'),
        betProcessedWinExpress: t(
          'notifications.subject.betProcessedWinExpress',
        ),
        betProcessedLossExpress: t(
          'notifications.subject.betProcessedLossExpress',
        ),
        betUnacceptedExpress: t('notifications.subject.betUnacceptedExpress'),
        admin: t('notifications.defaultSubject'),
        userVerificationStatus: t('verification.verificationTitle'),
        userVerificationCreated: t('verification.verificationTitle'),

        bonusCreated: bonusTitle.value,
        bonusExpired: bonusTitle.value,
        userFreespinProgramCreated: t('notifications.subject.freespinsAccrual'),
        bonusForDepositProgramUsersCreated: t(
          'notifications.subject.bonusForDeposit',
        ),
      }[notification.value.type] ?? t('notifications.defaultSubject')
    )
  })

  const { readNotification: read } = useNotificationsStore()
  async function readNotification() {
    if (notification.value.read) return

    try {
      await read(notification.value.id)
    } catch (e) {
      console.error('Failed to read notification', e)
    }
  }

  const notificationLink = computed(() => {
    switch (notification.value.type) {
      case 'depositCreated':
      case 'depositProcessed':
        return '/personal/transactions?tab=deposit'
      case 'withdrawalProcessed':
        return '/personal/transactions?tab=withdrawal'
      case 'betProcessedWinOrdinary':
      case 'betProcessedWinExpress':
      case 'betProcessedLossOrdinary':
      case 'betProcessedLossExpress':
        return '/personal/bets?sportTab=processed'
      case 'bonusCreated':
        return '/personal/transactions?tab=deposit'
      case 'bonusExpired':
      case 'userFreespinProgramCreated':
        return '/bonuses'
      case 'accountTransactionCreated':
        return '/personal/transactions?tab=deposit'
      default:
        return ''
    }
  })

  return {
    time,
    classes,
    notificationIcon,
    subjectMessage,
    notificationLink,
    readNotification,
  }
}
