export default defineNuxtPlugin({
  name: 'markets-store-plugin',
  order: 1,
  async setup(nuxt) {
    const io = useSocket() as any
    const { $i18n } = useNuxtApp() as any
    const { exactlyEquals } = useRouteMatching()
    const requestURL = useRequestURL()

    const isHomePage = exactlyEquals(requestURL.pathname, '/')
    const isSportHomePage = exactlyEquals(requestURL.pathname, '/sport')

    function normalizeBaseURL(baseURL: string) {
      return baseURL.startsWith('/')
        ? `${requestURL.origin}${baseURL}`
        : baseURL
    }

    const {
      public: { sportsbook, cormache },
    } = useRuntimeConfig()

    const { MarketStoreFactory } = await import('markets-store/factory/index')

    const factory = new MarketStoreFactory({
      sportsbookConfig: {
        api: normalizeBaseURL(sportsbook.api),
        key: sportsbook.key,
        user: sportsbook.user,
      },
      cormacheConfig: {
        api: normalizeBaseURL(cormache.api),
      },
      locale: $i18n.locale.value ?? 'en',
      allowedLocales: ['en', 'ru'],
      timeoutAfterSleep: 60,
      io,
      actualMarketsIds: [],
      platform: 'desktop',
      isHomePage: isHomePage || isSportHomePage,
    })

    const route = useRoute()
    const isPromoRoute = computed(() => route.path.includes('promo'))

    onNuxtReady(async () => {
      await until(isPromoRoute).toBe(false)
      factory.getActual()
    })

    nuxt.vueApp.provide('marketsStore', factory.marketStore)

    return {
      provide: {
        marketsStore: factory.marketStore,
      },
    }
  },
})
