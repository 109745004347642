<template>
  <div class="second-step" :class="platform">
    <StDrawerHeader v-if="platform === 'mobile'">
      <div class="title">{{ t('payments.buyCrypto.completePayment') }}</div>
    </StDrawerHeader>
    <div class="top-section">
      <div class="image-wrapper">
        <img src="../assets/second-step.svg" />
        <div class="spinner-wrapper">
          <StSpinner size="48" />
        </div>
        <div class="provider-image">
          <img
            v-if="selectedProvider === 'moonpay'"
            src="../../ExchangeStatus/assets/moonpay.svg"
            height="28"
          />
          <img
            v-if="selectedProvider === 'calypso'"
            src="../../ExchangeStatus/assets/calypso.svg"
            height="28"
          />
        </div>
      </div>
      <div class="text">
        {{ t('payments.deposit.completePayment', { provider: providerName }) }}
      </div>
    </div>

    <div class="bottom-section">
      <span>
        {{ t('payments.buyCrypto.redirectButtonText') }}
      </span>
      <StButton
        :label="t('payments.buyCrypto.goToExchange')"
        :size="platform === 'mobile' ? 'l' : 'xl'"
        icon="arrow-up-right"
        class="exchange-button"
        @click="emit('handleRedirect')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useExchangeDataStore } from '@st/payments/stores/useExchangeDataStore'
import StDrawerHeader from '@st/ui/components/StDrawer/StDrawerHeader.vue'

const emit = defineEmits<{
  handleRedirect: []
}>()

const dataStore = useExchangeDataStore()
const { selectedProvider } = storeToRefs(dataStore)

const { platform } = usePlatform()
const { t } = useI18n()

const providerName = computed(() =>
  selectedProvider.value === 'moonpay' ? 'MoonPay' : 'Calypso',
)
</script>

<style scoped>
.second-step {
  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 600px;
  padding: var(--spacing-300);
}

.top-section {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--spacing-200);
  align-items: center;
  justify-content: center;
}

.image-wrapper {
  position: relative;
}

.spinner-wrapper {
  position: absolute;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
}

.provider-image {
  position: absolute;
  top: 122px;
  right: 112px;
}

.text {
  width: 340px;
  font: var(--desktop-text-2lg-semibold);
  text-align: center;
}

.bottom-section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);
  align-items: center;

  width: 100%;
  height: 100%;

  span {
    font: var(--desktop-text-sm-medium);
    color: var(--text-secondary);
  }

  .exchange-button {
    width: 100%;
  }
}

.second-step.mobile {
  justify-content: space-between;

  height: 100%;
  min-height: auto;
  margin-top: 0;
  padding: 0 var(--spacing-200) var(--spacing-200);

  .top-section {
    justify-content: unset;
    padding: var(--spacing-500) 0 0;
  }

  .text {
    font: var(--mobile-headline-semibold);
  }

  .bottom-section {
    gap: var(--spacing-150);
    height: auto;

    span {
      font: var(--mobile-caption-1-regular);
    }
  }

  .title {
    font: var(--mobile-title-2-semibold);
  }
}
</style>
