<template>
  <div class="home">
    <div class="home-top-section">
      <HomeBanners />
      <MainPageCryptoBlock />
    </div>
    <MainCasino />
    <MainSport />
    <LastBets
      v-if="isVisible"
      :tabs="tabs"
      header-size="2xl"
      :with-header-icon="false"
    />
    <About />
  </div>
</template>

<script setup lang="ts">
import { useLastBets } from '@st/casino/composables/useLastBets'

const { t } = useI18n()

/**
 * @see useGameRecursiveMessage.ts
 */
onMounted(() => {
  window?.parent?.postMessage({ type: 'openedMainPage' }, '*')
})

useHead({
  title: t('pages.main'),
})

const { tabs, isVisible } = useLastBets('main')
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-600);
  padding-bottom: var(--spacing-600);
}

.home-top-section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-350);
}

.main-games {
  min-height: 256px;
}
</style>
